import React, { useState } from 'react';

import { SaleMethod } from './saleMethod/saleMethod';
import { Faq } from './faq/faq';
import { QuickScenario } from './quickScenario/quickScenario';
import { SecondaryScenario } from './secondaryScenario/secondaryScenario';
import { SALE_METHOD } from '../../model/types';

import { IPortfolioDeals } from '7-entities/user';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  item: IPortfolioDeals | null;
}

enum STEPS {
  CHOSE_METHOD = 'chose_method',
  FAQ = 'faq',
  QUICK = 'quick',
  SECONDARY = 'secondary',
}

export const DealSellScenario = ({ isOpen, item, onClose }: IProps) => {
  const [step, setStep] = useState<STEPS>(STEPS.CHOSE_METHOD);

  const onCloseHandler = () => {
    onClose();
    setStep(STEPS.CHOSE_METHOD);
  };
  const onChoseMethod = (method: SALE_METHOD) => {
    if (method === SALE_METHOD.QUICK) setStep(STEPS.QUICK);
    else setStep(STEPS.SECONDARY);
  };
  if (!isOpen || !item) return null;

  return (
    <>
      <SaleMethod
        onClose={onCloseHandler}
        opened={step === STEPS.CHOSE_METHOD}
        onSubmit={onChoseMethod}
        onMore={() => setStep(STEPS.FAQ)}
      />
      <Faq opened={step === STEPS.FAQ} onClose={onCloseHandler} onBack={() => setStep(STEPS.CHOSE_METHOD)} />
      <QuickScenario item={item} onClose={onCloseHandler} onBack={() => setStep(STEPS.CHOSE_METHOD)} opened={step === STEPS.QUICK} />
      <SecondaryScenario
        item={item}
        onClose={onCloseHandler}
        opened={step === STEPS.SECONDARY}
        onBack={() => setStep(STEPS.CHOSE_METHOD)}
      />
    </>
  );
};
