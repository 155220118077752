import React from 'react';

import type { IWithdrawForm } from '../../../../model/types';

import { Button, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  opened: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  info?: IWithdrawForm;
  isLoading: boolean;
  commission: number;
}
export const QuickWithdrawConfirm = ({ onBack, onSubmit, onClose, opened, isLoading, info, commission }: IProps) => {
  const { t, i18n } = useLocales('wallet');
  const commissionPrice = ((info?.amount || 0) * commission) / 100;
  const totalPrice = (info?.amount || 0) + commissionPrice;
  return (
    <Modal size={'lg'} opened={opened} onClose={onClose} title={t('modals.withdraw.quick.title')}>
      <div className={'text-2 font-500 text-black_text_opacity_70 flex flex-col gap-5 mt-10'}>
        <div className={'flex flex-col gap-3 border-2 border-gray p-3 rounded-2xl'}>
          <div className={'flex justify-between'}>
            <div>{i18n.t('name_form_fields.amount_usdt')}</div>
            <div>{info?.amount} USDT</div>
          </div>
          <div className={'flex justify-between items-center'}>
            <div>{i18n.t('name_form_fields.wallet_address')}</div>
            <div className={'max-w-[120px] mobile:max-w-[300px] truncate'}>{info?.wallet_address}</div>
          </div>
          <div className={'flex justify-between items-center'}>
            <div>{i18n.t('name_form_fields.network_wallet')}</div>
            <div>{info?.wallet_network}</div>
          </div>
          <div className={'flex justify-between items-center border-t-2 border-gray pt-2'}>
            <div>{t('modals.withdraw.quick.commission')}</div>
            <div>{commissionPrice.toFixed(2)} USDT</div>
          </div>
        </div>
        <div className={'flex justify-between items-center'}>
          <div>{t('modals.withdraw.total_price')}</div>
          <div>{totalPrice.toFixed(2)} USDT</div>
        </div>
        <div className={'flex gap-3'}>
          <Button onClick={onBack} className={'border-blue text-blue w-full'} variant={'outline'}>
            {t('back')}
          </Button>
          <Button onClick={onSubmit} className={'bg-blue w-full'} loading={isLoading} disabled={isLoading}>
            {t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
