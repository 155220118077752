import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authApi } from '../api/auth.api';

import { JWTToken } from '8-shared/lib/webStorage/JWTToken';

interface IAuthSlice {
  isAuth: boolean;
  isLoading: boolean;
  isShowVerify: boolean;
}

const initialState: IAuthSlice = {
  isAuth: false,
  isLoading: false,
  isShowVerify: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state: IAuthSlice, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setIsShowVerify: (state: IAuthSlice, action: PayloadAction<boolean>) => {
      state.isShowVerify = action.payload;
    },
    clearRegisterState: (state: IAuthSlice) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state: IAuthSlice, { payload }) => {
      JWTToken.setTokens(payload.access, payload.refresh);
      state.isAuth = true;
    });
    builder.addMatcher(authApi.endpoints.socialLogin.matchFulfilled, (state: IAuthSlice, { payload }) => {
      JWTToken.setTokens(payload.access, payload.refresh);
      state.isAuth = true;
    });
    // send email register
    builder.addMatcher(authApi.endpoints.sendEmail.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.sendEmail.matchFulfilled, (state: IAuthSlice) => {
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.sendEmail.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });

    // confirm email register
    builder.addMatcher(authApi.endpoints.confirmEmail.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.confirmEmail.matchFulfilled, (state: IAuthSlice, { payload }) => {
      JWTToken.setTokens(payload.access, payload.refresh);
      state.isAuth = true;
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.confirmEmail.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });

    // send phone register
    builder.addMatcher(authApi.endpoints.sendPhone.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.sendPhone.matchFulfilled, (state: IAuthSlice) => {
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.sendPhone.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });

    // confirm phone register
    builder.addMatcher(authApi.endpoints.confirmPhone.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.confirmPhone.matchFulfilled, (state: IAuthSlice) => {
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.confirmPhone.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });

    // create session verify register
    builder.addMatcher(authApi.endpoints.getVerify.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.getVerify.matchFulfilled, (state: IAuthSlice) => {
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.getVerify.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });

    // send survey register
    builder.addMatcher(authApi.endpoints.sendSurvey.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.sendSurvey.matchFulfilled, (state: IAuthSlice) => {
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.sendSurvey.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });

    // send local verify register
    builder.addMatcher(authApi.endpoints.sendLocalVerify.matchPending, (state: IAuthSlice) => {
      state.isLoading = true;
    });
    builder.addMatcher(authApi.endpoints.sendLocalVerify.matchFulfilled, (state: IAuthSlice) => {
      state.isLoading = false;
    });
    builder.addMatcher(authApi.endpoints.sendLocalVerify.matchRejected, (state: IAuthSlice) => {
      state.isLoading = false;
    });
  },
});
export const { setIsAuth, setIsShowVerify } = authSlice.actions;
export const selectIsAuth = (state: RootState) => state.auth.isAuth;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;
export const selectIsShowVerify = (state: RootState) => state.auth.isShowVerify;
