import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SecondarySaleForm } from './secondarySaleForm/secondarySaleForm';
import { SecondarySaleConfirm } from './secondarySaleConfirm/secondarySaleConfirm';
import { ISecondarySaleForm } from '../../../model/types';
import { saleP2P } from '../../../model/thunks';
import { getSecondaryOrderInfo } from '../../../model/getSecondaryOrderInfo';

import { IPortfolioDeals, selectUser } from '7-entities/user';
import { ISaleP2PBody } from '7-entities/p2p/model/types';

import { useAppDispatch } from '8-shared/hooks';
import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  item: IPortfolioDeals;
  onBack: () => void;
  onClose: () => void;
  opened: boolean;
}

enum STEPS {
  FORM = 'form',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
}

export const SecondaryScenario = ({ opened, onClose, onBack, item }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [step, setStep] = useState<STEPS>(STEPS.FORM);
  const [form, setForm] = useState<ISecondarySaleForm | undefined>();
  const { t } = useLocales('sale');
  const onSubmitForm = (form: ISecondarySaleForm) => {
    setStep(STEPS.CONFIRM);
    setForm(form);
  };
  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };
  const onSendOrder = () => {
    if (!form) return;
    setIsLoading(true);
    const data: ISaleP2PBody = {
      operation: item.id,
      status: 'Opened',
      comment: 'sale p2p',
      ...form,
    };
    dispatch(saleP2P(data))
      .unwrap()
      .then(() => {
        myTracker(user?.deal_id, TRACKER_EVENT.sell_secondary);
        setStep(STEPS.SUCCESS);
      })
      .finally(() => changeLoading());
    dataLayerEventHandler('sold_operations');
  };

  return (
    <>
      <SecondarySaleForm
        onClose={onClose}
        opened={opened && step === STEPS.FORM}
        max={item.remain}
        onBack={onBack}
        onSubmit={onSubmitForm}
      />
      <SecondarySaleConfirm
        opened={opened && step === STEPS.CONFIRM}
        onSubmit={onSendOrder}
        onBack={() => setStep(STEPS.FORM)}
        onClose={onClose}
        info={getSecondaryOrderInfo(form)}
        isLoading={isLoading}
      />
      <StatusScreen
        opened={opened && step === STEPS.SUCCESS}
        onClose={onClose}
        title={t('secondary_sale.success.title')}
        description={t('secondary_sale.success.description')}
        status={'success'}
      />
    </>
  );
};
