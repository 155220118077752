import React, { useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSelector } from 'react-redux';

import { replenishmentUSDTWallet } from '../../../../model/wallet.thunks';
import type { IReplenishUSDTForm } from '../../../../model/types';

import { selectUser } from '7-entities/user';

import { Button, Checkbox, CopyButton, Input, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { config } from '8-shared/config/config';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const ReplenishUSDTForm = ({ opened, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useLocales('wallet');
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(1, i18n.t('validation.min_count', { count: 1 })),
    sendManager: yup.boolean().required(i18n.t('validation.required')).isTrue(i18n.t('validation.required')),
  });

  const { control, handleSubmit, reset, watch } = useForm<IReplenishUSDTForm>({
    resolver: yupResolver(schema),
  });

  useLayoutEffect(() => {
    if (!opened) reset();
  }, [opened]);

  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  const onSubmit = (data: IReplenishUSDTForm) => {
    setIsLoading(true);
    dispatch(replenishmentUSDTWallet({ amount: data.amount }))
      .unwrap()
      .then(() => myTracker(user?.deal_id, TRACKER_EVENT.try_replenish_success(watch('amount'))))
      .catch(() => myTracker(user?.deal_id, TRACKER_EVENT.try_replenish_failed(watch('amount'))))
      .finally(() => changeLoading());
  };
  const onCloseHandler = () => {
    onClose();
    myTracker(user?.deal_id, TRACKER_EVENT.try_replenish_unfinished(watch('amount')));
  };

  return (
    <Modal opened={opened} onClose={onCloseHandler} title={t('modals.replenishment.title')} size={'lg'}>
      <form className={'mt-10 flex flex-col gap-5 ![&_>_div]:bg-blue'} onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} name={'amount'} label={i18n.t('name_form_fields.amount_usdt')} type={'number'} />
        <div className={'flex gap-2 w-full justify-between'}>
          <Input
            className={'w-full'}
            control={control}
            defaultValue={config.AM_WALLET_ADDRESS}
            name={'address'}
            label={i18n.t('name_form_fields.wallet_address')}
            readOnly
          />
          <CopyButton
            className={'h-auto'}
            value={config.AM_WALLET_ADDRESS}
            label={i18n.t('common.copy')}
            copiedLabel={i18n.t('common.copied')}
          />
        </div>
        <Input
          control={control}
          defaultValue={config.AM_WALLET_NETWORK}
          name={'network'}
          label={i18n.t('name_form_fields.network_wallet')}
          readOnly
        />
        <Checkbox name={'sendManager'} control={control} label={i18n.t('name_form_fields.send_manager')} />
        <Button type={'submit'} className={'bg-blue w-full'} variant={'filled'} loading={isLoading} disabled={isLoading}>
          {t('modals.replenishment.send')}
        </Button>
      </form>
    </Modal>
  );
};
