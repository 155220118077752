import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsAuth } from '7-entities/auth';
import { selectUnwatchNotifications } from '7-entities/user';
import { PATH_PAGE } from '8-shared/lib/router';
import { ReactComponent as NotificationsIcon } from '8-shared/assets/icons/notifications.svg';

export const Notifications = () => {
  const isAuth = useSelector(selectIsAuth);
  const unwatchNotification = useSelector(selectUnwatchNotifications);
  if (!isAuth) {
    return null;
  }
  return (
    <Link to={PATH_PAGE.profile.notifications} className={'mr-[20px] desktop:mr-[35px] relative hidden small_mobile:block'}>
      <NotificationsIcon />
      {!!unwatchNotification && (
        <div
          className={
            'absolute w-[14px] h-[14px] desktop:w-[20px] desktop:h-[20px] rounded-full bg-blue text-white top-0 right-0 desktop:right-[-10px] desktop:top-[-10px]'
          }
        >
          <div className={'text-center text-0.7 font-600 mt-[10%] desktop:text-1.2 desktop:mt-0'}>{unwatchNotification}</div>
        </div>
      )}
    </Link>
  );
};
