import React from 'react';
import { Stepper as MantineStepper, StepperProps } from '@mantine/core';
import { useStyles } from './styles';

export const Stepper = (props: StepperProps) => {
  const { classes } = useStyles();
  return (
    <MantineStepper classNames={classes} {...props}>
      {props.children}
    </MantineStepper>
  );
};
