import React from 'react';

import { IHistoryItem } from '../../model/types';

import { OPERATION_TYPE } from '7-entities/user/model/types';

import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  item: IHistoryItem;
}

export const Row = ({ item }: IProps) => {
  const { t } = useLocales('history');
  return (
    <div className={'flex justify-between items-center last:border-b-0 border-b border-white_opacity_10 text-white py-[20px]'}>
      <div className={'flex items-center'}>
        <img className={'w-[27px] h-auto mr-[10px]'} src={item?.logo} alt={'logo'} />
        <div className={'flex flex-col mobile:items-center mobile:flex-row'}>
          <div
            className={
              "text-1.2 leading-[12px] mobile:leading-[22px] text-white_opacity_50 mobile:text-1.8 mobile:text-white mobile:font-600 mr-[5px] mobile:after:content-[':'] mb-[2px] mobile:mb-0"
            }
          >
            {item.type === OPERATION_TYPE.SALE ? t('sale') : t('buy')}
          </div>
          <div className={'text-1.6 leading-[16px]  mobile:text-1.8 mobile:leading-[22px] text-white font-600'}>{item.name}</div>
        </div>
      </div>
      <div className={'text-1.6 leading-[20px] mobile:text-1.8 mobile:leading-[22px] text-white font-600'}>
        {item?.full_price || item?.amount} {item?.currency}
      </div>
    </div>
  );
};
