import { type BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { type FetchArgs, type FetchBaseQueryError, type FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import { JWTToken } from '../lib/webStorage/JWTToken';

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  FetchBaseQueryMeta
> = fetchBaseQuery({
  baseUrl: config.API_ENDPOINT,
  prepareHeaders: (headers, { getState }: { getState: RootState }) => {
    const token = JWTToken.getAccess();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    const lang = getState().app.currentLanguage;
    headers.set('Accept-Language', lang ?? 'ru');
    return headers;
  },
});
