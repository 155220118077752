import { IGoogleUserInfo } from './types';

export const getGoogleUserInfo = async (token: string): Promise<IGoogleUserInfo | null> => {
  try {
    const res = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
    const data = await res.json();
    return {
      name: data?.given_name,
      surname: data?.family_name,
      email: data?.email,
      id: data?.id,
    };
  } catch (e) {
    return null;
  }
};
