import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { closeWithdrawModal, selectWithdrawModalWallet } from '7-entities/user/model/user.slice';
import { useAppDispatch } from '8-shared/hooks';

import { StandardWithdrawScenario } from './standardWithdrawScenario/standardWithdrawScenario';
import { WithdrawMethod } from './saleMethod/withdrawMethod';
import { QuickWithdrawScenario } from './quickWithdrawScenario/quickWithdrawScenario';
import { WITHDRAW_METHOD, WITHDRAW_SCENARIO_STEPS } from '../../model/types';

export const WithdrawScenario = () => {
  const [step, setStep] = useState<WITHDRAW_SCENARIO_STEPS>(WITHDRAW_SCENARIO_STEPS.CHOSE_METHOD);
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectWithdrawModalWallet);

  const onCloseHandler = () => {
    dispatch(closeWithdrawModal());
    setStep(WITHDRAW_SCENARIO_STEPS.CHOSE_METHOD);
  };

  const onChoseMethod = (method: WITHDRAW_METHOD) => {
    if (method === WITHDRAW_METHOD.QUICK) setStep(WITHDRAW_SCENARIO_STEPS.QUICK);
    else setStep(WITHDRAW_SCENARIO_STEPS.STANDARD);
  };

  const onBackHandler = () => {
    setStep(WITHDRAW_SCENARIO_STEPS.CHOSE_METHOD);
  };

  if (!isOpen) return null;

  return (
    <>
      <WithdrawMethod onClose={onCloseHandler} opened={step === WITHDRAW_SCENARIO_STEPS.CHOSE_METHOD} onSubmit={onChoseMethod} />
      <QuickWithdrawScenario onClose={onCloseHandler} onBack={onBackHandler} opened={step === WITHDRAW_SCENARIO_STEPS.QUICK} />
      <StandardWithdrawScenario onClose={onCloseHandler} onBack={onBackHandler} opened={step === WITHDRAW_SCENARIO_STEPS.STANDARD} />
    </>
  );
};
