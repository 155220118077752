import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectIsAuth } from '7-entities/auth';
import { Button, type IButtonProps } from '8-shared/ui';
import { PATH_PAGE } from '8-shared/lib/router';

interface IProps extends IButtonProps {
  onClick: () => void;
}

export const MoreButton = ({ onClick, ...rest }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuth = useSelector(selectIsAuth);

  const onClickHandler = () => {
    if (!isAuth) {
      navigate(PATH_PAGE.login, { state: { backgroundLocation: location } });
      return;
    }
    onClick();
  };
  return <Button onClick={onClickHandler} {...rest} />;
};
