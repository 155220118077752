import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

export const CheckboxLabel = () => {
  const { t } = useLocales('auth');
  return (
    <div className={'text-0.8 laptop:text-1 font-400'}>
      <span>{t('register.first_step.confirm')}&nbsp;</span>
      <a
        href={`${process.env.REACT_APP_REF_URL}/media/documents/terms.pdf`}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className={'font-500 underline cursor-pointer'}
      >
        {t('register.first_step.offer')}&nbsp;
      </a>
      <span>{t('register.first_step.sign')}&nbsp;</span>
      <a
        href={`${process.env.REACT_APP_REF_URL}/media/documents/notice_registration.pdf`}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className={'font-500 underline cursor-pointer'}
      >
        {t('register.first_step.order')}
      </a>
    </div>
  );
};
