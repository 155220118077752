import { type IFloatingIPODto } from '../api/types';
import { IFloatingIPO } from '../model/types';

export const mapFloatingIpo = (dto: IFloatingIPODto): IFloatingIPO => {
  return {
    income_description: dto.income_description,
    logo: dto.logo,
    translations: dto.translations,
  };
};
