import React, { forwardRef, RefObject, useEffect, useState } from 'react';
import { Button as MantineButton, type ButtonProps } from '@mantine/core';
import { useStyles } from './styles';

export interface IButtonProps extends ButtonProps {
  variant?: 'filled' | 'outline';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSuccess?: boolean;
  successLabel?: string;
  bg?: string;
}

export const Button = forwardRef(
  (
    {
      children,
      isSuccess,
      successLabel,
      className,
      onClick,
      loading = false,
      type = 'submit',
      variant = 'filled',
      bg = '#4e6ee9',
      ...rest
    }: IButtonProps,
    ref
  ) => {
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
      if (isSuccess) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      }
    }, [isSuccess]);

    const { classes } = useStyles({ variant, success, bg, loading });
    return (
      <MantineButton
        ref={ref as RefObject<HTMLButtonElement>}
        className={`${className} ${success && 'bg-green_button_success'}`}
        classNames={classes}
        type={type}
        loading={loading}
        onClick={onClick}
        {...rest}
      >
        {success ? successLabel : children}
      </MantineButton>
    );
  }
);
Button.displayName = 'Button';
