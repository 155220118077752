import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { StandardWithdrawForm } from './standardWithdrawForm/standardWithdrawForm';
import { StandardWithdrawConfirm } from './standardWithdrawConfirm/standardWithdrawConfirm';
import { type IWithdrawForm, type IWithdrawRejectBody, STANDARD_WITHDRAW_STEPS } from '../../../model/types';
import { withdrawWallet } from '../../../model/wallet.thunks';

import { selectUser, selectWalletBalance, useWithdrawWalletMutation } from '7-entities/user';

import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  onBack: () => void;
  onClose: () => void;
  opened: boolean;
}

export const StandardWithdrawScenario = ({ opened, onClose, onBack }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const wallet = useSelector(selectWalletBalance);
  const user = useSelector(selectUser);
  const { t } = useLocales('wallet');

  const [step, setStep] = useState<STANDARD_WITHDRAW_STEPS>(STANDARD_WITHDRAW_STEPS.FORM);
  const [form, setForm] = useState<IWithdrawForm | undefined>();
  const [, { error }] = useWithdrawWalletMutation({ fixedCacheKey: 'withdrawWallet' });

  const onSubmitForm = (form: IWithdrawForm) => {
    setStep(STANDARD_WITHDRAW_STEPS.CONFIRM);
    setForm(form);
  };

  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  const onSendOrder = () => {
    if (form) {
      setIsLoading(true);
      dispatch(withdrawWallet({ ...form, urgently: false }))
        .unwrap()
        .then(() => {
          myTracker(user?.deal_id, TRACKER_EVENT.withdraw_standard);
          setStep(STANDARD_WITHDRAW_STEPS.SUCCESS);
        })
        .catch(() => setStep(STANDARD_WITHDRAW_STEPS.ERROR))
        .finally(() => changeLoading());
    }
  };

  return (
    <>
      <StandardWithdrawForm
        onClose={onClose}
        opened={opened && step === STANDARD_WITHDRAW_STEPS.FORM}
        max={wallet}
        onBack={onBack}
        onSubmit={onSubmitForm}
      />
      <StandardWithdrawConfirm
        opened={opened && step === STANDARD_WITHDRAW_STEPS.CONFIRM}
        onSubmit={onSendOrder}
        onBack={() => setStep(STANDARD_WITHDRAW_STEPS.FORM)}
        onClose={onClose}
        info={form}
        isLoading={isLoading}
      />
      <StatusScreen
        opened={opened && step === STANDARD_WITHDRAW_STEPS.SUCCESS}
        onClose={onClose}
        title={t('modals.withdraw.success')}
        description={t('modals.withdraw.standard.success_description')}
        status={'success'}
      />
      <StatusScreen
        opened={opened && step === STANDARD_WITHDRAW_STEPS.ERROR}
        onClose={onClose}
        title={'FAILED'}
        description={(error as IWithdrawRejectBody)?.data?.detail}
        status={'error'}
      />
    </>
  );
};
