import React, { FormEvent } from 'react';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useSelector } from 'react-redux';

import { type IGetVerifyResponse, selectIsLoading } from '7-entities/auth';
import { selectUser } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { Button } from '8-shared/ui';
import { useAppDispatch } from '8-shared/hooks';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';
import { changeVerifyStatus, getVerifyThunk } from '../../../model/model';
import { selectCurrentLanguage } from '7-entities/app';

interface IProps {
  onClose: () => void;
}

export const FormVerify = ({ onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const lang = useSelector(selectCurrentLanguage);
  const { isLoading } = useSelector(selectIsLoading);
  const { t } = useLocales('auth');

  const onVerify = (message: MESSAGES, url: string) => {
    if (message !== MESSAGES.FINISHED) return;
    dispatch(changeVerifyStatus({ veriff_url: url }))
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  const onBack = () => {
    myTracker(user?.deal_id, TRACKER_EVENT.register_stage_5_miss);
    onClose();
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (user.veriff_url) {
      dataLayerEventHandler('fifthStep');
      createVeriffFrame({
        url: user.veriff_url,
        lang,
        onEvent: (message) => onVerify(message, user.veriff_url),
      });
    } else {
      dispatch(getVerifyThunk())
        .unwrap()
        .then((data: IGetVerifyResponse) => {
          dataLayerEventHandler('fifthStep');
          createVeriffFrame({
            url: data.verification.url,
            lang,
            onEvent: (message) => onVerify(message, data.verification.url),
          });
        })
        .catch(() => {
          console.log('error veriff');
        });
    }
  };

  const info = [
    { number: 1, text: t('verify.info.0') },
    { number: 2, text: t('verify.info.1') },
    { number: 3, text: t('verify.info.2') },
  ];

  const onLocalClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    // eslint-disable-next-line
    // @ts-ignore
    Intercom?.('show');
  };

  return (
    <form onSubmit={onSubmit} className={'flex flex-col gap-4 w-full'}>
      <div className={'pr-2 pl-2 w-full flex flex-col gap-5 h-full justify-between pb-5'}>
        <div className={'text-center text-3 font-600'}>{t('verify.title')}</div>
        <div className={'flex flex-col gap-2'}>
          {info.map((el) => (
            <div className={'flex gap-3'} key={el.number}>
              <div className={'h-min px-2.5 py-1 text-1.2 text-center text-white bg-blue rounded-full font-600'}>{el.number}</div>
              <div className={'text-1.6 laptop:text-2'}>{el.text}</div>
            </div>
          ))}
        </div>
        <div className={'flex flex-col gap-5 m-auto'}>
          <Button className={'bg-blue w-full'} loading={isLoading} type={'submit'}>
            {t('verify.international')}
          </Button>
          <Button className={'border-blue w-full text-blue'} loading={isLoading} type={'button'} variant={'outline'} onClick={onBack}>
            {t('verify.finish')}
          </Button>
          <div className={'text-[14px] laptop:text-[18px] font-400 text-center'}>
            <span>{t('verify.localSupport')}&nbsp;</span>
            <a
              onClick={onLocalClick}
              href="#"
              target={'_blank'}
              rel={'noopener noreferrer'}
              className={'font-500 underline cursor-pointer block'}
            >
              {t('verify.localOpenChat')}
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};
