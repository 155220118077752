export const config = {
  API_ENDPOINT: '/api',
  INIT_THEME: 'dark',
  THEMES_VARIANTS: ['light', 'dark'],
  INIT_LANG: 'ru',
  SUPPORTED_LANG: ['ru', 'en'],
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
  APP_URL: process.env.REACT_APP_MAIN_URL,
  REGISTER_REF_LINK: `${process.env.REACT_APP_REF_URL}/ref/sign-up/`,
  AM_WALLET_ADDRESS: 'TPAmVYmdbszyEfgoMDAHx5NsQkoMLZphmS',
  AM_WALLET_NETWORK: 'TRC20',
} as const;
