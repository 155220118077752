import type {
  IDigitalSafeHistoryWithPaginationDto,
  IDocument,
  INotificationDto,
  IPortfolioHistoryDto,
  IRefUserWithPaginationDto,
  IReplenishmentUSDTWalletBody,
  IUserDto,
  IWalletBalanceDto,
  IWithdrawWalletBody,
  IReplenishmentCurrencyWalletBody,
} from './types';
import type {
  ICloseDigitalSafeParams,
  IDigitalSafeOperation,
  IMyReferalsQueryParams,
  IOpenDigitalSafeForm,
  IPortfolioDeals,
  IPortfolioHistory,
  IRefUserWithPagination,
  IRequestChangePasswordBody,
  ITransferBonusForm,
  IUser,
  IWalletBalance,
  IWalletHistory,
  INotification,
  IDigitalSafeHistory,
  IProfileInfo,
} from '../model/types';
import { mapUser } from '../lib/mapUser';
import { mapReferals } from '../lib/mapReferals';
import { mapPortfolioHistory } from '../lib/mapPortfolioHistory';
import { mapWalletBalance } from '../lib/mapWalletBalance';
import { mapNotifocation } from '../lib/mapNotifocation';
import { mapDigitalSafeHistory } from '../lib/mapDigitalSafeHistory';

import { IGetPaginationBody, IPaginationResponse } from '8-shared/model/interface';
import { baseApi } from '8-shared/api';
import { DIGITAL_SAFE, DOCUMENTS, HISTORY, NOTIFICATIONS, PORTFOLIO_DEALS, PROFILE_INFO, USER, WALLET } from '8-shared/api/tags';

export const userAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUser, void>({
      query: () => ({
        url: '/users/jwt/cookie/me/',
      }),
      providesTags: [USER],
      transformResponse: (response: IUserDto) => mapUser(response),
    }),
    getMyReferals: build.query<IRefUserWithPagination, IMyReferalsQueryParams>({
      query: ({ name, order, sort, email, phone, page, page_size }) => {
        return {
          url: `/users/my_referals/`,
          params: { name, phone, email, sort, order, page, page_size },
        };
      },
      transformResponse: (response: IRefUserWithPaginationDto) => mapReferals(response),
    }),
    changeAvatar: build.mutation<null, FormData>({
      query: (data) => {
        return {
          url: '/users/change_profile_photo/',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: [USER],
    }),
    changePassword: build.mutation<null, IRequestChangePasswordBody>({
      query: (data) => {
        return {
          url: '/users/change-password/',
          method: 'POST',
          body: data,
        };
      },
    }),
    createAgentRequest: build.mutation<null, void>({
      query: () => {
        return {
          url: '/users/create_become_ref_req/',
          method: 'POST',
        };
      },
    }),
    getBonusRequest: build.mutation<null, ITransferBonusForm>({
      query: (body) => {
        return {
          url: '/users/create_takeoff/',
          method: 'POST',
          body: body,
        };
      },
    }),
    getPortfolioHistory: build.query<IPortfolioHistory[], void>({
      query: () => {
        return {
          url: '/securities/history/',
        };
      },
      providesTags: [HISTORY],
      transformResponse: (response: IPortfolioHistoryDto[]) => response.map(mapPortfolioHistory),
    }),
    getWalletBalance: build.query<IWalletBalance, void>({
      query: () => {
        return {
          url: '/securities/wallet/',
        };
      },
      providesTags: [WALLET],
      transformResponse: (response: IWalletBalanceDto) => mapWalletBalance(response),
    }),
    getWalletHistory: build.query<IPaginationResponse<IWalletHistory>, IGetPaginationBody>({
      query: (data) => {
        return {
          url: '/securities/wallet-operations/',
          params: data,
        };
      },
      providesTags: [WALLET],
    }),
    getPorfolioDeals: build.query<IPaginationResponse<IPortfolioDeals>, IGetPaginationBody>({
      query: (data) => {
        return {
          url: '/securities/app/portfolio_bought/',
          params: data,
        };
      },
      providesTags: [PORTFOLIO_DEALS],
    }),
    replenishmentUSDTWallet: build.mutation<void, IReplenishmentUSDTWalletBody>({
      query: (data) => {
        return {
          url: '/securities/wallet/operation/replenishment/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [WALLET],
    }),
    replenishmentCurrencyWallet: build.mutation<void, IReplenishmentCurrencyWalletBody>({
      query: (data) => {
        return {
          url: '/securities/wallet/operation/replenishment/currency',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [WALLET],
    }),
    withdrawWallet: build.mutation<void, IWithdrawWalletBody>({
      query: (data) => {
        return {
          url: '/securities/wallet/operation/withdraw/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [WALLET],
    }),
    getMyDigitalSafeOperations: build.query<IPaginationResponse<IDigitalSafeOperation>, IGetPaginationBody>({
      query: (data) => {
        return {
          url: '/securities/safe-operations/',
          params: data,
        };
      },
      providesTags: [DIGITAL_SAFE],
    }),
    getDigitalSafeHistory: build.query<IDigitalSafeHistory[], void>({
      query: () => {
        return {
          url: '/securities/safe-operations/history',
        };
      },
      providesTags: [DIGITAL_SAFE],
      transformResponse: (response: IDigitalSafeHistoryWithPaginationDto) => response.results.map(mapDigitalSafeHistory),
    }),
    openDigitalSafe: build.mutation<void, IOpenDigitalSafeForm>({
      query: (data) => {
        return {
          url: '/securities/safe/operation/purchase/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [WALLET, DIGITAL_SAFE, PROFILE_INFO],
    }),
    closeDigitalSafe: build.mutation<void, ICloseDigitalSafeParams>({
      query: (data) => {
        return {
          url: '/securities/safe/operation/sale/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [WALLET, DIGITAL_SAFE, PROFILE_INFO],
    }),
    getNotification: build.query<INotification[], void>({
      query: () => {
        return {
          url: '/communication/messages/',
        };
      },
      transformResponse: (response: INotificationDto[]) => response.map(mapNotifocation),
      providesTags: [NOTIFICATIONS],
    }),
    watchNotifications: build.mutation<null, void>({
      query: () => {
        return {
          url: '/communication/set_is_read/',
          method: 'POST',
        };
      },
      invalidatesTags: [NOTIFICATIONS],
    }),
    getDocuments: build.query<IPaginationResponse<IDocument>, IGetPaginationBody>({
      query: (data) => ({
        url: '/users/generate-document/',
        params: data,
      }),
      providesTags: [DOCUMENTS],
    }),
    getProfileInfo: build.query<IProfileInfo, void>({
      query: () => ({
        url: '/users/diagram/',
      }),
      providesTags: [PROFILE_INFO],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetMyReferalsQuery,
  useCreateAgentRequestMutation,
  useChangeAvatarMutation,
  useLazyGetUserQuery,
  useChangePasswordMutation,
  useGetBonusRequestMutation,
  useGetPortfolioHistoryQuery,
  useGetWalletBalanceQuery,
  useGetWalletHistoryQuery,
  useReplenishmentUSDTWalletMutation,
  useReplenishmentCurrencyWalletMutation,
  useWithdrawWalletMutation,
  useGetPorfolioDealsQuery,
  useGetMyDigitalSafeOperationsQuery,
  useCloseDigitalSafeMutation,
  useOpenDigitalSafeMutation,
  useGetNotificationQuery,
  useWatchNotificationsMutation,
  useGetDigitalSafeHistoryQuery,
  useGetDocumentsQuery,
  useLazyGetNotificationQuery,
  useLazyGetWalletBalanceQuery,
  useLazyGetProfileInfoQuery,
} = userAPI;
