import React, { useMemo } from 'react';
import { useViewportSize } from '@mantine/hooks';

import { ReactComponent as ArrowIcon } from '8-shared/assets/icons/arrow_right_3.svg';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  currentPage: number;
  totalPages: number;
  onChangePage: (page: number) => void;
}

export const Pagination = ({ currentPage, onChangePage, totalPages }: IProps) => {
  const { t } = useLocales('agent');
  const { width } = useViewportSize();
  const isMobile = useMemo(() => width < 640, [width]);

  if (!totalPages) {
    return null;
  }

  return (
    <div className={'flex justify-center text-[16px] gap-4'}>
      {currentPage !== 1 && (
        <div
          onClick={() => onChangePage(currentPage - 1)}
          className={
            'cursor-pointer w-[50px] h-[50px] rounded-full border-[2px] border-blue flex items-center justify-center mobile:w-[170px] mobile:rounded-[8px]'
          }
        >
          {!isMobile && <span>{t('referals_table.table.prev_page')}</span>}
          {isMobile && <ArrowIcon className={'rotate-180'} />}
        </div>
      )}
      {currentPage !== totalPages && (
        <div
          onClick={() => onChangePage(currentPage + 1)}
          className={
            'cursor-pointer w-[50px] h-[50px] rounded-full bg-blue flex items-center justify-center mobile:w-[170px] mobile:rounded-[8px]'
          }
        >
          {!isMobile && <span>{t('referals_table.table.next_page')}</span>}
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};
