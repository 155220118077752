import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { ProfilePageLayout, AppLayout, MainPageLayout, ProtectedRoute, PublicRoute } from '3-layouts';

import { VenturePage } from '4-pages/venture';
import { AlternativePage } from '4-pages/alternative';
import { RealEstatePage } from '4-pages/realEstate';
import { LoginPage } from '4-pages/auth/login';
import { RegisterPage } from '4-pages/auth/register';
import { ForgotPasswordPage } from '4-pages/auth/forgotPassword';
import { AboutPage } from '4-pages/about';
import { FeedbackPage } from '4-pages/feedback';
import { PartnershipPage } from '4-pages/partnership';
import { ContactsPage } from '4-pages/contacts';
import { PortfolioPage } from '4-pages/profile/portfolio';
import { HistoryPage } from '4-pages/profile/history';
import { AgentPage } from '4-pages/profile/agent';
import { WalletPage } from '4-pages/profile/wallet';
import { SettingsPage } from '4-pages/profile/settings';
import { DigitalSafePage } from '4-pages/profile/digitalSafe';
import { DocumentsPage } from '4-pages/profile/documents';
import { NotificationsPage } from '4-pages/profile/notifications';
import { NotFoundPage } from '4-pages/notFound';

import { PATH_PAGE } from '8-shared/lib/router';

export const AppRoutes = () => {
  const location = useLocation();
  const prevLocation = location?.state?.backgroundLocation;

  return (
    <>
      <Routes location={prevLocation || location}>
        <Route path={PATH_PAGE.root.venture} element={<AppLayout />}>
          <Route path={PATH_PAGE.root.venture} element={<MainPageLayout />}>
            <Route path={PATH_PAGE.root.venture} element={<VenturePage />} />
            <Route path={PATH_PAGE.root.realEstate} element={<RealEstatePage />} />
            <Route path={PATH_PAGE.root.alternative} element={<AlternativePage />} />

            <Route path={PATH_PAGE.register} element={<RegisterPage />} />
            <Route path={PATH_PAGE.ref_register} element={<RegisterPage />} />
            <Route element={<PublicRoute />}>
              <Route path={PATH_PAGE.login} element={<LoginPage />} />
              <Route path={PATH_PAGE.forgot_password} element={<ForgotPasswordPage />} />
            </Route>
          </Route>
          <Route path={'*'} element={<NotFoundPage />} />
          <Route path={PATH_PAGE.contacts} element={<ContactsPage />} />
          <Route path={PATH_PAGE.about} element={<AboutPage />} />
          <Route path={PATH_PAGE.feedback} element={<FeedbackPage />} />
          <Route path={PATH_PAGE.partnership} element={<PartnershipPage />} />

          <Route element={<ProtectedRoute />}>
            <Route path={PATH_PAGE.profile.root} element={<ProfilePageLayout />}>
              <Route path={PATH_PAGE.profile.portfolio} element={<PortfolioPage />} />
              <Route path={PATH_PAGE.profile.history} element={<HistoryPage />} />
              <Route path={PATH_PAGE.profile.digital_safe} element={<DigitalSafePage />} />
              <Route path={PATH_PAGE.profile.settings} element={<SettingsPage />} />
              <Route path={PATH_PAGE.profile.wallet} element={<WalletPage />} />
              <Route path={PATH_PAGE.profile.agent} element={<AgentPage />} />
              <Route path={PATH_PAGE.profile.documents} element={<DocumentsPage />} />
              <Route path={PATH_PAGE.profile.notifications} element={<NotificationsPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      {prevLocation && (
        <Routes>
          <Route path={PATH_PAGE.register} element={<RegisterPage />} />
          <Route element={<PublicRoute />}>
            <Route path={PATH_PAGE.login} element={<LoginPage />} />
            <Route path={PATH_PAGE.forgot_password} element={<ForgotPasswordPage />} />
          </Route>
        </Routes>
      )}
    </>
  );
};
