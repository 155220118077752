import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { FloatingCompanies } from '5-widgets/floatingCompanies';
import { ActualDeals } from '5-widgets/actualDeals';

import { MonthDeal } from '6-features/monthDeal';

import { selectUser } from '7-entities/user';
import { selectRealEstateCompanies, selectRealEstateMonthDeal } from '7-entities/ipo';

import { useLocales } from '8-shared/lib/i18n';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const RealEstatePage = () => {
  const { t } = useLocales('main');
  const dealRef = useRef<HTMLDivElement | null>(null);

  const monthDeal = useSelector(selectRealEstateMonthDeal);
  const companies = useSelector(selectRealEstateCompanies);
  const user = useSelector(selectUser);

  const onClickBuyHandler = () => {
    dealRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const onClickMoreHandler = () => {
    myTracker(user?.deal_id, TRACKER_EVENT.lot_more);
  };

  return (
    <div>
      <MonthDeal onClickBuyHandler={onClickBuyHandler} onClickMoreHandler={onClickMoreHandler} deal={monthDeal} />
      <FloatingCompanies />
      <div>
        <div className={'text-center font-500 text-3.6 leading-[47px] mt-[90px] mobile:mt-[130px] mb-[60px]'}>
          {t('actual_deals.title')}
        </div>
        <div ref={dealRef}>
          <ActualDeals companies={companies} />
        </div>
      </div>
    </div>
  );
};
