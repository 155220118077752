import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  root: {
    position: 'relative',
    fontFamily: 'Montserrat,sans-serif',
  },
  input: {
    '&:focus, &:focus-within': {
      borderColor: 'rgba(78, 110, 233, 1)',
    },
  },
}));
