import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsAuth, selectIsLoading } from '7-entities/auth';

import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { logoutAction } from '8-shared/api';

interface IProps {
  onClick: () => void;
}
export const LogoutButton = ({ onClick }: IProps) => {
  const dispatch = useAppDispatch();
  const isAuth = useSelector(selectIsAuth);
  const isLoading = useSelector(selectIsLoading);
  const { t } = useLocales('navigation');

  const logoutHandler = () => {
    logoutAction(dispatch);
    onClick();
  };
  if (!isAuth) return null;
  return (
    <div className={'text-2 text-green leading-[24px] mb-[18px] cursor-pointer'} hidden={isLoading} onClick={logoutHandler}>
      {t('burger.logout')}
    </div>
  );
};
