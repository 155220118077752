import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

export const Documents = () => {
  const { t } = useLocales('about');

  const documents = [
    { title: t('documents.agreement'), link: `${process.env.REACT_APP_REF_URL}/media/documents/terms.pdf` },
    { title: t('documents.registration'), link: require('8-shared/assets/doduments/registration.pdf') },
    { title: t('documents.risk'), link: `${process.env.REACT_APP_REF_URL}/media/documents/warning_risk.pdf` },
  ];

  return (
    <div className={'layout-padding flex items-center w-full justify-center mb-[30px] mobile:mb-[70px] laptop:mb-[75px]'}>
      <div className={'flex flex-col laptop:flex-row flex-wrap w-full items-center justify-center laptop:max-w-[950px]'}>
        {documents.map((i) => (
          <a
            key={i.title}
            href={i.link}
            className={
              'w-full py-[15px] mr-[13px] mb-[13px] laptop:mb-[22px] laptop:mr-[22px] laptop:py-[37px] px-[20px] mobile:max-w-[340px] laptop:max-w-[450px] font-500 text-center text-1.8 leading-[24px] bg-white_opacity_10 rounded-10'
            }
            rel="noopener noreferrer"
            target={'_blank'}
          >
            {i.title}
          </a>
        ))}
      </div>
    </div>
  );
};
