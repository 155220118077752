import React from 'react';
import cn from 'classnames';

import { IWalletHistory, WALLET_HISTORY_STATUS, WALLET_HISTORY_TYPE } from '7-entities/user';
import { getDate } from '8-shared/lib/utils';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  item: IWalletHistory;
}

export const MobileRow = ({ item }: IProps) => {
  const { t } = useLocales('wallet');
  return (
    <div
      className={
        'w-full laptop:hidden grid grid-cols-2 justify-between laptop:justify-between mb-[15px] text-1.4 mobile:text-2 px-[15px] py-[10px] mobile:px-[25px] mobile:py-[30px] border border-white_opacity_12 rounded-15 bg-white_opacity_5'
      }
    >
      <div className={'flex flex-col gap-2'}>
        <span className={'uppercase font-600'}>
          {item.type === WALLET_HISTORY_TYPE.REPLENISHMENT && t('table.type.replenishment')}
          {item.type === WALLET_HISTORY_TYPE.WITHDRAW && t('table.type.withdraw')}
        </span>
        <span
          className={cn('uppercase font-500', {
            ['text-[#18AD00]']: item.status === WALLET_HISTORY_STATUS.APPROVED,
            ['text-[#FF2B2B]']: item.status === WALLET_HISTORY_STATUS.DECLINED,
            ['text-[#8E8E8C]']: item.status === WALLET_HISTORY_STATUS.IN_PROGRESS,
          })}
        >
          {item.status === WALLET_HISTORY_STATUS.APPROVED && t('table.status.approved')}
          {item.status === WALLET_HISTORY_STATUS.DECLINED && t('table.status.declined')}
          {item.status === WALLET_HISTORY_STATUS.IN_PROGRESS && t('table.status.in_progress')}
        </span>
      </div>
      <div className={'flex flex-col w-full items-end gap-2'}>
        <span className={'flex uppercase gap-2 font-600'}>
          <span className={'text-white'}>{item.type === WALLET_HISTORY_TYPE.WITHDRAW ? `-${item.amount}` : `+${item.amount}`}</span>
          <span>{item.currency}</span>
        </span>
        <span className={'uppercase font-600 text-white_opacity_50'}>{getDate(item.updated_at)}</span>
      </div>
    </div>
  );
};
