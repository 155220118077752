import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { loginThunk } from '../../model/login';
import { ILoginForm, ILoginRejectBody } from '../../model/types';

import { useLoginMutation } from '7-entities/auth';
import { IUser } from '7-entities/user';

import { Button, Input, Link, PasswordInput } from '8-shared/ui';
import { PATH_PAGE } from '8-shared/lib/router';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { formErrorHandler } from '8-shared/lib/form';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const LoginForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useLocales('auth');
  const dispatch = useAppDispatch();

  const [, { isLoading }] = useLoginMutation({ fixedCacheKey: 'login' });

  const schema = yup.object().shape({
    email: yup.string().email(i18n.t('validation.email')).required(i18n.t('validation.required')),
    password: yup.string().required(i18n.t('validation.required')),
  });

  const { control, handleSubmit, setError, clearErrors } = useForm<ILoginForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ILoginForm) => {
    dispatch(loginThunk(data))
      .unwrap()
      .then((data: IUser) => {
        //TODO replace this to navigate(-1)
        navigate(PATH_PAGE.root.venture);
        dataLayerEventHandler('authorization');
        myTracker(data?.deal_id, TRACKER_EVENT.login);
      })
      .catch((e: ILoginRejectBody) => formErrorHandler({ errors: e.data, setError }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors()}>
      <Input className={'mb-[10px]'} label={`${t('login.email')}`} name={'email'} control={control} />
      <PasswordInput className={'mb-[20px]'} label={`${t('login.password')}`} name={'password'} control={control} />
      <Input className={'mb-[20px]'} name={'detail'} hidden control={control} />
      <div className={'flex justify-between'}>
        <Button loading={isLoading} className={'bg-blue'}>
          {t('login.submit')}
        </Button>
        <Link
          replace
          to={PATH_PAGE.forgot_password}
          state={{
            backgroundLocation: (location?.state as { backgroundLocation?: Location })?.backgroundLocation ?? {},
          }}
        >
          {t('login.recovery_pass')}
        </Link>
      </div>
    </form>
  );
};
