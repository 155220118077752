import { AppDispatch } from '1-app/store/store';

import { clearUserData, userAPI } from '7-entities/user';
import { setIsAuth } from '7-entities/auth';

import { JWTToken } from '8-shared/lib/webStorage/JWTToken';

export const logoutAction = (dispatch: AppDispatch) => {
  JWTToken.removeTokens();
  dispatch(setIsAuth(false));
  dispatch(clearUserData());
  dispatch(userAPI.util.resetApiState());
};
