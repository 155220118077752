import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Information } from './information/information';
import { OpenDigitalSageScenario } from '../openDigitalSageScenario/openDigitalSageScenario';

import { selectCurrentLanguage } from '7-entities/app';
import { selectDigitalSafeInfo, useGetDigitalSafeInfoQuery } from '7-entities/digitalSafe';
import { selectUser } from '7-entities/user';

import { openUrlInNewTab } from '8-shared/lib/utils';
import { useLocales } from '8-shared/lib/i18n';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';
import { BuyButton, MoreButton } from '6-features/protectedButtons';

export const DigitalSafeBanner = () => {
  const { t } = useLocales('main');
  const digitalSafe = useSelector(selectDigitalSafeInfo);
  const lang = useSelector(selectCurrentLanguage);
  const [open, setOpen] = useState<boolean>(false);
  const user = useSelector(selectUser);

  useGetDigitalSafeInfoQuery();

  const onPressMore = () => {
    myTracker(user?.deal_id, TRACKER_EVENT.safe_more);
    openUrlInNewTab(digitalSafe?.translations?.[lang]?.detailed_pdf);
  };

  const onPressInvest = () => {
    setOpen(true);
  };

  return (
    <div className={'layout-padding-mobile'}>
      <div className={'flex flex-col gap-5 monthly_deal_bg py-8 px-4 desktop:p-10 rounded-30 border'}>
        <div className={'flex flex-col gap-5 laptop:flex-row justify-between desktop:h-full'}>
          <div className={'desktop:max-w-[500px]'}>
            <div className={'text-3 mobile:text-5 desktop:text-[50px] font-700'}>{t('digital_safe.title')}</div>
            <div className={'text-1.4  desktop:text-[20px]  whitespace-pre-wrap'}>
              {t('digital_safe.sub_title', { count: Number(digitalSafe?.profit_rate) })}
            </div>
          </div>
          <Information />
        </div>
        <div className={'h-max flex flex-col mobile:flex-row gap-3.5'}>
          <BuyButton onClick={onPressInvest} className={'bg-blue'}>
            {t('digital_safe.buttons.buy')}
          </BuyButton>
          <MoreButton onClick={onPressMore} variant={'outline'}>
            {t('digital_safe.buttons.more')}
          </MoreButton>
        </div>
      </div>
      <OpenDigitalSageScenario opened={open} onClose={() => setOpen(false)} />
    </div>
  );
};
