import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

export const Slogan = () => {
  const { t } = useLocales('main');
  return (
    <h1
      className={
        'text-[15px] mobile:text-[40px] uppercase max-w-[320px] mobile:max-w-[800px] font-600 text-center mb-[25px] tracking-widest'
      }
    >
      <div className={'text-[27px] mobile:text-[60px] font-700'}>{t('slogan.title')}</div>
      <div>{t('slogan.sub_title')}</div>
      <div>{t('slogan.company_name')}</div>
    </h1>
  );
};
