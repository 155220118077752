import { IIPO } from '../../ipo';

export type UserId = Brand<Id, 'UserId'>;

export interface IUser {
  is_registered_by_social: boolean;
  frozen_am_money: number;
  am_money: number;
  id: UserId;
  country_name: string;
  created_at: string;
  deal_id: string;
  email: Email;
  id_number: string;
  id_photo: Url;
  id_selfie: Url;
  is_id_validated: boolean;
  manager: IManager;
  name: string;
  patronymic: string;
  phone: string;
  profile_photo: Url;
  sold_securities: number;
  surname: string;
  updated_at: string;
  usdt_wallet: string;
  invite_ref: string | null;
  sum_of_invited: number;
  ref_money: number;
  sum_of_second_layer_invited: number;
  number_of_invited: number;
  number_of_second_layer_invited: number;
  ref_field: string;
  is_ref_participant: boolean;
  total_amount_of_money: number;
  is_agent: boolean;
  register_stage: REGISTER_STAGE;
  is_phone_confirmed: boolean;
  veriff_url: string;
  status_verify: VERIFY_STAGE;
  signed_user_assignment?: string;
}

export enum VERIFY_STAGE {
  STARTED = 'started',
  CREATED = 'created',
  EXPIRED = 'expired',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  DECLINED = 'declined',
  RESUBMISSION_REQUESTED = 'resubmission_requested',
  ABANDONED = 'abandoned',
  REVIEW = 'review',
}
export interface IManager {
  phone: string;
  name: string;
  patronymic: string;
  surname: string;
  manager_type: string;
  email: string;
  created_at: string;
  updated_at: string;
  user_model: number;
  profile_photo: Url;
}

export interface IRefUserWithPagination {
  number_of_pages: number;
  number_of_refs: number;
  refs: IRefUser[];
}

export interface IRefUser {
  full_name: string;
  phone: string;
  email: string;
  ref_money: number;
  number_of_invited: number;
  total_amount_of_money: number;
}

export interface IMyReferalsQueryParams {
  name?: string;
  order?: string;
  sort?: 'number_of_invited' | 'total_amount_of_money';
  email?: string;
  page: number;
  phone?: string;
  page_size?: number;
}

export enum REGISTER_STAGE {
  INITIAL = 'initial',
  LAST = 'secondary',
  PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  WITH_PROBLEM = 'with_problem',
}

export interface IRequestChangePasswordBody {
  password: string;
  password2: string;
  old_password: string;
}

export interface ITransferBonusForm {
  price: number;
  detail?: string;
}

export enum OPERATION_TYPE {
  BUY = 0,
  SALE = 1,
}

export interface IPortfolioHistory {
  belong: string;
  company: IIPO;
  cost: string;
  created_at: string;
  quantity: number;
  type: OPERATION_TYPE;
  full_price: string;
}

export interface IWalletBalance {
  amount_usdt: string;
}

export enum WALLET_HISTORY_TYPE {
  WITHDRAW = 'Withdraw',
  REPLENISHMENT = 'Replenishment',
}

export enum WALLET_HISTORY_STATUS {
  IN_PROGRESS = 'InProgress',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
}

export interface IWalletHistory {
  type: WALLET_HISTORY_TYPE;
  amount: string;
  status: WALLET_HISTORY_STATUS;
  created_at: string;
  updated_at: string;
  currency: string;
}

export interface IPortfolioDeals {
  company: IIPO;
  cost: string;
  current_price: number;
  difference: string;
  id: number;
  income_persentage: string;
  quantity: number;
  created_at: string;
  remain: number;
}

export enum DIGITAL_SAFE_TYPE {
  IN_PROGRESS = 'IN_PROGRESS',
  SOLD = 'SOLD',
}

export enum DIGITAL_SAFE_HISTORY_TYPE {
  BOUGHT = 'BOUGHT',
  SOLD = 'SOLD',
}

export interface IDigitalSafeOperation {
  id: number;
  type: DIGITAL_SAFE_TYPE;
  amount: string;
  transaction_date: string;
  operation_rate: string;
  total: string;
}

export interface IDigitalSafeHistory {
  id: number;
  type: DIGITAL_SAFE_HISTORY_TYPE;
  amount: string;
  transaction_date: string;
  operation_rate: string;
  total: string;
  logo: string;
}

export interface IOpenDigitalSafeForm {
  amount: number;
}

export interface ICloseDigitalSafeParams {
  operation_id: number;
}

export interface INotification {
  id: number;
  title: string;
  text: string;
  is_read: boolean;
  created_at: string;
}
export interface IProfileInfo {
  invest_amount: number;
  invest_profit: number;
  digital_safe: number;
  total_amount: number;
  profit_percent: number;
}
export enum CURRENCY {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  AED = 'AED',
}
