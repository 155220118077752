import React, { useEffect } from 'react';
import { FieldError, FieldErrors, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CheckboxLabel } from './checkboxLabel/checkboxLabel';
import { IFirstStepForm, IFirstStepRejectBody, sendEmailRegisterThunk } from '../../model';

import { STEPS_ENUM, selectIsLoading } from '7-entities/auth';

import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Checkbox, Input } from '8-shared/ui';
import { formErrorHandler } from '8-shared/lib/form';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { PATH_PAGE } from '8-shared/lib/router';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';
import { SocialLogin } from '../../../socialLogin/socialLogin';

export const FirstStepRegisterForm = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useSelector(selectIsLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useLocales('auth');
  const schema = yup.object().shape({
    email: yup.string().required(i18n.t('validation.required')).email(i18n.t('validation.email')),
    confirmOffer: yup.boolean().required(i18n.t('validation.required')).oneOf([true], i18n.t('validation.required')),
  });

  const { control, handleSubmit, watch, setError, clearErrors, trigger } = useForm<IFirstStepForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    clearErrors('confirmOffer');
  }, [watch('confirmOffer')]);

  const onSubmit = (data: IFirstStepForm) => {
    dispatch(sendEmailRegisterThunk({ email: data.email }))
      .unwrap()
      .then(() => {
        myTracker(undefined, TRACKER_EVENT.register_stage_1);
        dataLayerEventHandler('firstStep');
        navigate(`${PATH_PAGE.register}?step=${STEPS_ENUM.second}`, {
          replace: true,
          state: {
            backgroundLocation: location.state?.backgroundLocation,
            email: data.email,
          },
        });
      })
      .catch((error: IFirstStepRejectBody) => {
        const errors: FieldErrors<IFirstStepForm> = {
          email: {
            message: `${error.data?.email} ${error.data?.detail || ''}`,
            type: 'value',
          } as FieldError,
        };
        formErrorHandler({ errors, setError });
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors('detail')} className={'flex flex-col gap-4 w-full h-full'}>
        <div className={'text-1.3 mobile:text-1.6 text-center font-600'}>{t('register.step_title', { step: STEPS_ENUM.first })}</div>
        <div className={'w-full flex flex-col gap-5 h-full justify-between pb-5'}>
          <div>
            <div className={'text-center text-2 font-500 mb-5  laptop:hidden'}>{t('register.first_step.title')}</div>
            <Input className={'mb-[10px]'} label={i18n.t('name_form_fields.email')} name={'email'} control={control} />
            <Checkbox name={'confirmOffer'} control={control} label={<CheckboxLabel />} />
          </div>
          <Button className={'bg-blue w-full'} loading={isLoading}>
            {t('register.next')}
          </Button>
        </div>
      </form>
      <SocialLogin trigger={() => trigger('confirmOffer')} />
    </>
  );
};
