import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useViewportSize } from '@mantine/hooks';

import { changeMyReferalsFilters, IRefUser, selectReferalsFilters, useGetMyReferalsQuery } from '7-entities/user';
import { useAppDispatch } from '8-shared/hooks';

import { Filters } from './filters/filters';
import { Columns } from './columns/columns';
import { DesktopRow } from './row/desktopRow';
import { LaptopRow } from './row/laptopRow';
import { MobileRow } from './row/mobileRow';
import { Pagination } from './pagination/pagination';
import { IReferalsTableFilterForm } from '../../model/types';

export const ReferalsTable = () => {
  const { width } = useViewportSize();
  const dispatch = useAppDispatch();

  const filters = useSelector(selectReferalsFilters);

  const { data: referals, isFetching } = useGetMyReferalsQuery(filters);

  const filterReferalsList = (data: IReferalsTableFilterForm) => {
    dispatch(changeMyReferalsFilters({ ...filters, ...data, page: 1 }));
  };

  const onChangePage = (page: number) => {
    dispatch(changeMyReferalsFilters({ ...filters, page }));
  };

  const onSortColumn = (sort: 'total_amount_of_money' | 'number_of_invited') => {
    dispatch(changeMyReferalsFilters({ ...filters, sort }));
  };

  const currentItem = useCallback(
    (item: IRefUser, key: number) => {
      if (width < 640) return <MobileRow item={item} key={key} />;
      if (width < 1024) return <LaptopRow item={item} key={key} />;
      return <DesktopRow item={item} key={key} />;
    },
    [width]
  );

  return (
    <div className={'flex flex-col w-full relative gap-4'}>
      <Filters isLoading={isFetching} filterReferalsList={filterReferalsList} />
      <Columns onSortColumn={onSortColumn} />
      <div className={'flex flex-col'}>{referals?.refs?.map((el, index) => currentItem(el, index))}</div>
      <Pagination currentPage={filters.page} totalPages={referals?.number_of_pages ?? 1} onChangePage={onChangePage} />
    </div>
  );
};
