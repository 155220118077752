import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { buyIPO } from '../../model/thunks';
import { getInfoOrder } from '../../model/getInfoOrder';

import { BuyForm } from '6-features/buy/ui/buyForm/buyForm';
import { ConfirmBuy, IBuyForm } from '6-features/buy';

import { closeBuyModal, selectBuyIpoModal } from '7-entities/ipo/model/ipo.slice';
import { useBuyIPOMutation } from '7-entities/ipo/api/ipo.api';
import { IBuyIPOBody } from '7-entities/ipo/api/types';
import { selectCurrentLanguage } from '7-entities/app';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

import { StatusScreen } from '8-shared/ui';
import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { selectUser } from '7-entities/user';

export enum PURCHASE_STEPS {
  FORM = 'form',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const BuyIPOScenario = () => {
  const [isLoading, setIsLoading] = useState(false);
  const lang = useSelector(selectCurrentLanguage);
  const [step, setStep] = useState<PURCHASE_STEPS>(PURCHASE_STEPS.FORM);
  const [formData, setFormData] = useState<IBuyForm | undefined>();
  const { isOpen, item } = useSelector(selectBuyIpoModal);
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const [, { reset }] = useBuyIPOMutation({ fixedCacheKey: 'buyIPO' });
  const { t } = useLocales('buy');
  const itemData = item?.translations?.[lang];

  const availableLots = itemData?.remain || 0;
  const onCloseHandler = () => {
    setStep(PURCHASE_STEPS.FORM);
    dispatch(closeBuyModal());
    reset();
  };
  const onSubmitForm = (data: IBuyForm) => {
    setFormData(data);
    setStep(PURCHASE_STEPS.CONFIRM);
  };
  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };
  const onSubmit = () => {
    setIsLoading(true);
    const body: IBuyIPOBody = {
      quantity: formData?.quantity || 0,
      company: item?.id || 0,
      description: 'buy_ipo',
      type: 0,
    };
    dispatch(buyIPO(body))
      .unwrap()
      .then(() => {
        myTracker(user?.deal_id, TRACKER_EVENT.buy_first);
        setStep(PURCHASE_STEPS.SUCCESS);
      })
      .catch(() => {
        setStep(PURCHASE_STEPS.FAILED);
      })
      .finally(() => changeLoading());
  };

  if (!isOpen) return null;

  return (
    <>
      <BuyForm
        min={itemData?.min_buy_lots || 1}
        onClose={onCloseHandler}
        opened={step === PURCHASE_STEPS.FORM}
        onSubmit={onSubmitForm}
        max={availableLots}
      />
      <ConfirmBuy
        isLoading={isLoading}
        onClose={onCloseHandler}
        opened={step === PURCHASE_STEPS.CONFIRM}
        onSubmit={onSubmit}
        onBack={() => setStep(PURCHASE_STEPS.FORM)}
        info={getInfoOrder({ item: itemData, count: formData?.quantity })}
      />
      <StatusScreen
        opened={step === PURCHASE_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={t('success.title')}
        description={t('success.description')}
        status={'success'}
      />
      <StatusScreen
        opened={step === PURCHASE_STEPS.FAILED}
        onClose={onCloseHandler}
        title={t('failed.title')}
        description={t('failed.description')}
        status={'error'}
      />
    </>
  );
};
