import { createAsyncThunk } from '@reduxjs/toolkit';

import { feedbackApi } from '7-entities/feedback';

interface IParams {
  name: string;
  phone: Phone;
  recaptcha_token: string;
}

export const sendFeedbackThunk = createAsyncThunk<void, IParams, { state: RootState }>(
  'auth/login',
  async (body: IParams, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(feedbackApi.endpoints.sendFeedback.initiate(body, { fixedCacheKey: 'send_feedback' })).unwrap();
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
