import React from 'react';
import { useSelector } from 'react-redux';

import { INotification } from '7-entities/user';
import { selectCurrentLanguage } from '7-entities/app';
import { ReactComponent as AMLogo } from '8-shared/assets/icons/company_logo_large.svg';
import { getFullDate, getHoursAndMinutes } from '8-shared/lib/utils';

interface IProps {
  item: INotification;
}

export const NotificationCard = ({ item }: IProps) => {
  const lang = useSelector(selectCurrentLanguage);
  return (
    <div className={'bg-blue_bg px-[10px] py-[20px] rounded-15 mb-[25px]'}>
      <div className={'flex justify-between'}>
        <div className={'flex items-center mb-[17px]'}>
          <AMLogo className={'w-[30px] h-auto mr-[10px]'} />
          <div className={'text-1.2 mobile:text-1.4 font-600 leading-[16px]'}>AM Capital</div>
        </div>
        <div className={'text-right text-1.2'}>
          <div className={'text-white'}>{getFullDate(item?.created_at, lang)}</div>
          <div className={'opacity-50'}>{getHoursAndMinutes(item?.created_at)}</div>
        </div>
      </div>

      <div className={'text-1.6 font-400 text-white mb-[20px]'}>{item.title}</div>

      <div className={'text-1.4 font-400 text-white'}>{item.text}</div>
    </div>
  );
};
