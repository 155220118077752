import { type IIPODto } from '../api/types';
import { IPO_TYPE, type IIPO, type IIPOId } from '../model/types';

export const mapIpo = (dto: IIPODto): IIPO => {
  return {
    id: dto.id as IIPOId,
    translations: {
      en: {
        ...dto.translations.en,
        type: dto.translations.en.type as IPO_TYPE,
      },
      ru: {
        ...dto.translations.ru,
        type: dto.translations.ru.type as IPO_TYPE,
      },
    },
  };
};
