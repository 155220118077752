import React from 'react';
import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  onClose: () => void;
}

export const ProgressVerify = ({ onClose }: IProps) => {
  const { t } = useLocales('auth');
  return (
    <div className={'flex flex-col mt-5 gap-5 items-center max-w-[300px] mx-auto'}>
      <div className={'text-2 font-600 text-center'}>{t('sticky.veriff_status.pending')}</div>
      <Button onClick={onClose} variant={'filled'} className={'bg-blue'} fullWidth>
        Ok
      </Button>
    </div>
  );
};
