import React from 'react';
import { useSelector } from 'react-redux';

import { useGetFloatingIPOQuery, selectFloatingIPOCompanies, type IFloatingIPO } from '7-entities/ipo';
import shadow from '8-shared/assets/images/marketplace_floating_shadow.webp';
import { useFillArray } from '8-shared/hooks';
import { FloatingCompany } from './floatingCompany';

export const FloatingCompanies = () => {
  const floatingCompanies = useSelector(selectFloatingIPOCompanies);

  useGetFloatingIPOQuery();
  const floatingArray = useFillArray<IFloatingIPO>(floatingCompanies);

  return (
    <div className={'mt-[50px] z-0 relative layout-padding-mobile'}>
      <img alt={'main_page_photo_3'} className={'absolute z-[3] w-[20%] h-full left-[-1px] top-0'} src={shadow} />
      <div className={'flex items-center justify-center'}>
        <div className={'w-[200%] h-[140px] overflow-hidden relative'}>
          <div className={'animate-floating_companies_scroll w-[200%] flex items-center h-full justify-around absolute left-0 z-[2]'}>
            {floatingArray?.map((item, index: number) => (
              <FloatingCompany key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
      <img alt={'main_page_photo_3'} className={'absolute z-[3] w-[20%] h-full top-0 right-[-1px] rotate-[180deg]'} src={shadow} />
    </div>
  );
};
