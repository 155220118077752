import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { selectCurrentLanguage } from '7-entities/app';
import { IPortfolioDeals } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { BuyButton } from '6-features/protectedButtons';

interface IProps {
  item: IPortfolioDeals;
  onSell: (item: IPortfolioDeals) => void;
}

export const Row = ({ item, onSell }: IProps) => {
  const { t, i18n } = useLocales('portfolio');
  const lang = useSelector(selectCurrentLanguage);
  return (
    <div
      className={
        'flex flex-col rounded-[15px] mb-[15px] items-center px-[10px] py-[15px] desktop:px-[20px] border border-white_opacity_13 bg-white_opacity_5 w-full'
      }
    >
      <div className={'flex justify-between items-center w-full'}>
        <div className={'flex items-center mobile:w-[50%] desktop:w-[30%]'}>
          <img
            src={item?.company?.translations?.[lang].logo}
            className={'w-[25px] h-auto mr-[10px] mobile:w-[50px] mobile:mr-[20px] desktop:w-[70px]'}
            alt={`logo`}
          />
          <div className={'flex flex-col'}>
            <div className={'font-600  text-1.6 mobile:text-2 leading-[20px] mobile:leading-[28px] text-white'}>
              {item?.company?.translations?.[lang].title}
            </div>
            <div
              className={
                'flex desktop:hidden whitespace-nowrap flex-row text-1 mobile:text-1.2 font-400 text-white opacity-60 leading-[12px] mobile:leading-[16px]'
              }
            >
              <div>{i18n.t('plural.lot', { count: item.remain })}</div>
              <div className={'mx-[10px]'}>|</div>
              <div>{`${item?.remain * Number(item.cost)} $`}</div>
            </div>
          </div>
        </div>
        <BuyButton
          isNeedVerify={true}
          bg={'#ffffff08'}
          onClick={() => onSell(item)}
          className={'hidden mobile:flex bg-white_opacity_3 border border-white_opacity_13'}
        >
          {t('table.row.sell_btn')}
        </BuyButton>
        <div className={'grid desktop:grid-cols-3 mobile:w-[30%] mobile:justify-end desktop:w-[50%]'}>
          <div className={'hidden desktop:flex items-center justify-center text-2 leading-[28px]'}>
            <div>{i18n.t('plural.lot', { count: item.remain })}</div>
          </div>
          <div className={'hidden desktop:flex items-center justify-center text-2 leading-[28px]'}>{`${
            item?.remain * Number(item.cost)
          } $`}</div>
          <div className={'flex flex-col text-white justify-center items-center'}>
            <div
              className={
                'font-600 text-1.6 whitespace-nowrap leading-[16px] mobile:text-1.8 mobile:leading-[29px] desktop:text-2.8 desktop:leading-[36px] mb-[5px]'
              }
            >
              {`${item.difference} $`}
            </div>
            <div
              className={cn(
                'text-1 leading-[12px] text-green mobile:text-1.4 mobile:leading-[20px] desktop:text-2 desktop:leading-[28px]',
                {
                  ['text-red']: Number(item?.income_persentage) < 0,
                }
              )}
            >
              {`${Number(item.income_persentage) === 0 ? 0 : Number(item.income_persentage).toFixed(2)}%`}
            </div>
          </div>
        </div>
      </div>
      <BuyButton isNeedVerify={true} onClick={() => onSell(item)} className={'mobile:hidden bg-blue w-full mt-[20px] px-[14px] text-1.5'}>
        {t('table.row.sell_btn')}
      </BuyButton>
    </div>
  );
};
