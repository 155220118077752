import React from 'react';

import { NotificationsList } from '5-widgets/profile/notifications';
import { useLocales } from '8-shared/lib/i18n';

export const NotificationsPage = () => {
  const { t } = useLocales('notifications');

  return (
    <div className={'w-full desktop:w-1/2'}>
      <div className={'text-3.6 font-700 mb-[25px]'}>{t('title')}</div>
      <NotificationsList />
    </div>
  );
};
