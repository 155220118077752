import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUser, selectVerifyStatus, useLazyGetUserQuery } from '7-entities/user';
import { selectIsAuth, setIsShowVerify, VERIFY_STATUS } from '7-entities/auth';
import { VERIFY_STAGE } from '7-entities/user/model/types';

import { PATH_PAGE } from '8-shared/lib/router';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';

export const StickyLine = () => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const { t } = useLocales('auth');
  const user = useSelector(selectUser);
  const verifyStatus = useSelector(selectVerifyStatus);
  const isAuth = useSelector(selectIsAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const [getUser] = useLazyGetUserQuery();
  const dispatch = useAppDispatch();
  const handleScroll = () => {
    const currentScrollOffset = window.scrollY;
    setScrollOffset(currentScrollOffset);
  };
  const onClick = async () => {
    await getUser();
    if (isAuth && verifyStatus !== VERIFY_STATUS.COMPLETED_VERIFY) {
      return dispatch(setIsShowVerify(true));
    } else {
      navigate(PATH_PAGE.register, { state: { backgroundLocation: location } });
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const texts = {
    notAuth: t('sticky.not_auth'),
    notPhone: t('sticky.not_phone'),
    notSurvey: t('sticky.not_survey'),
    notVeriff: t('sticky.not_veriff'),
    veriffStatus: {
      pending: t('sticky.veriff_status.pending'),
      declined: t('sticky.veriff_status.declined'),
      resubmit: t('sticky.veriff_status.resubmit'),
    },
  };
  const selectText = () => {
    if (!isAuth) {
      return texts.notAuth;
    }
    if (verifyStatus === VERIFY_STATUS.PROGRESS_VERIFY) {
      return texts.veriffStatus.pending;
    }
    if (user?.status_verify === VERIFY_STAGE.DECLINED) {
      return texts.veriffStatus.declined;
    }
    if (user?.status_verify === VERIFY_STAGE.RESUBMISSION_REQUESTED) {
      return texts.veriffStatus.resubmit;
    }
    if (verifyStatus === VERIFY_STATUS.PHONE_VERIFIED) {
      return texts.notVeriff;
    }
    if (verifyStatus === VERIFY_STATUS.SURVEY_VERIFIED) {
      return texts.notVeriff;
    } else {
      return texts.notPhone;
    }
  };

  if (verifyStatus === VERIFY_STATUS.COMPLETED_VERIFY) return null;

  return (
    <div
      onClick={onClick}
      className={`w-full sticky bg-black z-50 text-white text-center cursor-pointer py-2 text-1.6 pt-2 ${
        scrollOffset > 20 && 'fixed left-0 top-0'
      }`}
    >
      {selectText()}
    </div>
  );
};
