import React from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalSafesHistory, selectPortfolioHistory } from '7-entities/user';
import { selectCurrentLanguage } from '7-entities/app';
import { EmptyData } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

import { Day } from '../day/day';
import { prepareDataForHistory } from '../../model/prepareHistory';

export const HistoryList = () => {
  const { t } = useLocales();
  const history = useSelector(selectPortfolioHistory);
  const digitalSafesHistory = useSelector(selectDigitalSafesHistory);
  const lang = useSelector(selectCurrentLanguage);

  const historyWithDates = prepareDataForHistory(history, digitalSafesHistory, lang);

  if (!historyWithDates?.length) {
    return <EmptyData title={t('common.empty_table')} />;
  }
  return (
    <div className={'min-h-[200px]'}>
      <div className={'desktop:w-1/2'}>
        {historyWithDates?.map((i, index) => (
          <Day key={index} item={i} />
        ))}
      </div>
    </div>
  );
};
