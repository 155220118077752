import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as PmpRuLogo } from '8-shared/assets/icons/pmp_company_RUS.svg';
import { ReactComponent as PmpEnLogo } from '8-shared/assets/icons/pmp_company_ENG.svg';
import { ReactComponent as CompanyLogo } from '8-shared/assets/icons/company_logo_small.svg';
import { LangVariants, useLocales } from '8-shared/lib/i18n';

import { footerLinks } from '../../model/footerLinks';

export const Footer = () => {
  const {
    t,
    i18n: { language },
  } = useLocales('navigation');

  return (
    <div
      className={
        'z-[3] relative flex px-[100px] laptop:pl-0 laptop:pr-[150px] desktop:px-[30px] mt-auto laptop:items-center laptop:justify-center border-t border-white_opacity_15 z-[9] py-[46px]'
      }
    >
      <CompanyLogo className={'absolute left-[30px] top-[46px]'} />
      <div
        className={
          'flex flex-col justify-between gap-x-[50px] gap-y-[20px] w-[70%] small_mobile:w-auto laptop:flex-row laptop:items-center'
        }
      >
        {footerLinks(language as LangVariants).map((i) => (
          <Link key={i.label} className={'font-roboto text-1.8 font-400 leading-[21px]'} to={i.path}>
            {t(`${i.label}`)}
          </Link>
        ))}
      </div>
      <a href={'https://pmp-tech.ru/'} target={'_blank'} rel="noreferrer">
        {language === 'ru' ? (
          <PmpRuLogo
            className={
              'absolute w-[110px] right-[30px] bottom-[30px] mobile:w-[160px] mobile:right-[185px] mobile:top-[39px] laptop:top-[23px] desktop:right-[130px]'
            }
          />
        ) : (
          <PmpEnLogo
            className={
              'absolute w-[110px] right-[30px] bottom-[30px] mobile:w-[160px] mobile:right-[185px] mobile:top-[39px] laptop:top-[23px] desktop:right-[130px]'
            }
          />
        )}
      </a>
    </div>
  );
};
