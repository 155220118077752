import React from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { ForgotPasswordForm } from '6-features/auth/forgotPassword';
import { Link, Modal } from '8-shared/ui';
import { PATH_PAGE } from '8-shared/lib/router';
import { useLocales } from '8-shared/lib/i18n';

export const ForgotPasswordPage = () => {
  const { t } = useLocales('auth');
  const navigate = useNavigate();
  const location = useLocation();
  const onPressCloseModalHandler = () => {
    navigate(-1);
  };

  return (
    <Modal size={'lg'} closeOnClickOutside={false} title={t('forgot_password.title')} opened={true} onClose={onPressCloseModalHandler}>
      <div className={'flex mb-[32px]'}>
        <span className={'text-1.4 laptop:text-1.6 font-300 leading-[25px] mr-[5px]'}>{t('forgot_password.or')}</span>
        <Link
          to={PATH_PAGE.login}
          replace
          state={{
            backgroundLocation: (location?.state as { backgroundLocation?: Location })?.backgroundLocation ?? {},
          }}
        >
          {t('forgot_password.sign_in')}
        </Link>
      </div>
      <ForgotPasswordForm />
    </Modal>
  );
};
