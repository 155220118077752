import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBuyIPOBody } from '7-entities/ipo/api/types';
import { ipoApi } from '7-entities/ipo';

export const buyIPO = createAsyncThunk<void, IBuyIPOBody, { state: RootState }>(
  'ipo/buyIpo',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(ipoApi.endpoints.buyIPO.initiate(data, { fixedCacheKey: 'buyIPO' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
