import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles(
  (theme, { variant, success, bg, loading }: { variant: 'filled' | 'outline'; success: boolean; bg: string; loading: boolean }) => ({
    root: success
      ? {
          height: 'min-content',
          padding: '16px 20px',
          borderRadius: rem(8),
          fontSize: rem(18),
          fontWeight: 400,
          fontFamily: 'Roboto',
          color: '#ffffff',
          border: variant === 'outline' ? '2px solid rgba(255, 255, 255, 0.25)' : '2px solid #099268',
          backgroundColor: '#099268',
          '&:hover': {
            backgroundColor: variant === 'outline' ? 'transparent' : '#099268',
          },
          '&:disabled': {
            backgroundColor: '#099268',
            opacity: 0.9,
            color: '#ffffff',
            cursor: 'default',
          },
          ['data-loading']: {
            backgroundColor: '#099268',
          },
        }
      : {
          height: 'min-content',
          padding: '16px 20px',
          borderRadius: rem(8),
          fontSize: rem(18),
          fontWeight: 400,
          fontFamily: 'Roboto',
          color: '#ffffff',
          border: loading ? 'none' : variant === 'outline' ? '2px solid rgba(255, 255, 255, 0.25)' : `2px solid ${bg}`,
          backgroundColor: bg,
          '&:hover': {
            backgroundColor: variant === 'outline' ? 'transparent' : bg,
          },
          '&:disabled': {
            backgroundColor: bg,
            opacity: 0.9,
            cursor: 'default',
          },
        },
  })
);
