import React from 'react';
import { StructureCard } from './card';
import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  scrollTo: () => void;
}

export const PartnerProgram = ({ scrollTo }: IProps) => {
  const { t } = useLocales('partnership');

  const steps = [
    { title: t('program_step_1.title'), value: t('program_step_1.value') },
    { title: t('program_step_2.title'), value: t('program_step_2.value') },
    { title: t('program_step_3.title'), value: t('program_step_3.value') },
    { title: t('program_step_4.title'), value: t('program_step_4.value') },
  ];

  return (
    <div className={'layout-padding mb-[35px] mobile:mb-[60px] laptop:mb-[80px]'}>
      <hr className={'hidden laptop:block w-full border-white_opacity_50 mb-[120px]'} />
      <div
        className={
          'laptop:w-3/4 uppercase text-2.3 mobile:text-3.6 font-500 leading-[30px] mobile:leading-[47px] mb-[60px] laptop:mb-[92px]'
        }
      >
        {t('program_title')}
      </div>
      <div className={'grid grid-cols-1 laptop:grid-cols-2 laptop:gap-[100px]'}>
        {steps.map((el, index) => (
          <StructureCard key={index} number={index + 1} title={el.title} description={el.value} />
        ))}
      </div>
      <div className={'flex justify-center laptop:justify-end mb-[60px] mobile:mb-[90px] mt-[35px]'}>
        <Button onClick={scrollTo} className={'w-full mobile:w-max bg-blue'}>
          {t('create_request')}
        </Button>
      </div>
    </div>
  );
};
