import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectNotifications, selectUnwatchNotifications } from '7-entities/user';
import { useAppDispatch } from '8-shared/hooks';

import { NotificationCard } from './notificationCard/notificationCard';
import { watchNotificationThunk } from '../../model/watchNotification.thunk';

export const NotificationsList = () => {
  const dispatch = useAppDispatch();
  const notifications = useSelector(selectNotifications);
  const unwatchNotifications = useSelector(selectUnwatchNotifications);

  const watchNotifications = () => {
    dispatch(watchNotificationThunk());
  };

  useEffect(() => {
    if (!!unwatchNotifications) {
      watchNotifications();
    }
  }, [unwatchNotifications]);

  return (
    <div>
      {notifications.map((i) => (
        <NotificationCard key={i?.id} item={i} />
      ))}
    </div>
  );
};
