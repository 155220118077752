import { baseApi } from '8-shared/api';

import { type IRequestFeedbackBody } from './types';

export const feedbackApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    sendFeedback: build.mutation<null, IRequestFeedbackBody>({
      query: (data) => ({
        url: '/users/call_request/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendFeedbackMutation } = feedbackApi;
