import React from 'react';
import { Modal as MantineModal, type ModalProps } from '@mantine/core';
import { ReactComponent as CloseIcon } from '8-shared/assets/icons/cross.svg';
import { useStyles } from './styles';

export const Modal = ({ children, onClose, title, ...rest }: ModalProps) => {
  const { classes } = useStyles();
  return (
    <MantineModal
      size={rest.size || 'xl'}
      padding={0}
      withCloseButton={false}
      centered
      classNames={classes}
      overlayProps={{
        opacity: 0.2,
        blur: 4,
      }}
      onClose={onClose}
      {...rest}
      title={undefined}
    >
      <div className={'flex flex-col bg-white h-max rounded-10 px-[20px] py-[35px] laptop:p-[35px]'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-2.2 desktop:text-2.8 text-black_text font-600'}>{title}</h1>
          <CloseIcon onClick={onClose} className={'absolute cursor-pointer opacity-30 top-[24px] right-[24px] w-[16px] h-[16px]'} />
        </div>
        {children}
      </div>
    </MantineModal>
  );
};
