import React, { useEffect, useId, useRef, useState } from 'react';
import { Control, FieldValues, Path, PathValue, useController, UseFormSetError } from 'react-hook-form';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

interface IProps<T extends FieldValues> extends PhoneInputProps {
  label?: string;
  nameNumberField: Path<T>;
  nameCountryField: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
  setError: UseFormSetError<T>;
}

export const PhoneInput = <T extends FieldValues>({
  nameNumberField,
  nameCountryField,
  control,
  defaultValue,
  label = '',
  ...rest
}: IProps<T>) => {
  const ref = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(!!rest.country);
  const id = useId();

  const {
    field: { value: value = '', onChange: fieldOnChange, onBlur, ...field },
    fieldState,
  } = useController<T>({
    name: nameNumberField,
    control,
    defaultValue,
  });
  const {
    field: { onChange: fieldOnChangeCountry },
  } = useController<T>({
    name: nameCountryField,
    control,
  });
  useEffect(() => {
    if (ref.current) fieldOnChange(ref.current.value);
  }, [ref.current]);
  return (
    <div className={'relative'}>
      <label
        htmlFor={id}
        className={`text-[18px] transition-all text-black_text_opacity_40 absolute 
                   z-10 left-[60px] top-[20px] ${(focused || !!value) && '!top-[8px] !lowercase !text-[14px]'}`}
      >
        {label}
      </label>
      <ReactPhoneInput
        inputClass={`!w-full !text-[18px] !text-black focus:!border-[#228be6] focus:!shadow-none !pt-[30px] !pb-[10px] ${rest.inputClass}`}
        dropdownClass={`!max-h-[150px] !max-w-[250px] ${rest.dropdownClass}`}
        value={value}
        onChange={(e, country) => {
          fieldOnChange(e);
          if ('name' in country) {
            fieldOnChangeCountry(country.name);
          }
        }}
        placeholder={''}
        autoFormat
        isValid={!fieldState.invalid}
        inputProps={{
          ...field,
          ref: ref,
          id,
          onFocus: () => setFocused(true),
          onBlur: () => {
            onBlur();
            setFocused(false);
          },
        }}
        {...rest}
      />
      {fieldState.error?.message && <div className={'text-red text-[14px] mt-[10px]'}>{fieldState.error?.message}</div>}
    </div>
  );
};
