import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { useSendFeedbackMutation } from '7-entities/feedback';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Input, StatusScreen } from '8-shared/ui';
import { useAppDispatch, useCaptcha } from '8-shared/hooks';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { formErrorHandler } from '8-shared/lib/form';

import { sendFeedbackThunk } from '../../model/sendFeedback';
import { IFeedbackForm, IFeedbackRejectBody } from '../../model/types';

export const FeedbackForm = () => {
  const { t, i18n } = useLocales('feedback');
  const dispatch = useAppDispatch();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [, { isLoading: isActionFetching }] = useSendFeedbackMutation({ fixedCacheKey: 'send_feedback' });

  const feedbackSchema = yup.object().shape({
    name: yup.string().required(i18n.t('validation.required')),
    phone: yup.string().required(i18n.t('validation.required')),
  });
  const { control, handleSubmit, reset, setError } = useForm<IFeedbackForm>({
    resolver: yupResolver(feedbackSchema),
  });

  const { sendWithCaptcha } = useCaptcha();
  const onSubmit = async (data: IFeedbackForm) => {
    const cb = (recaptcha_token: string) => {
      dispatch(sendFeedbackThunk({ ...data, recaptcha_token: recaptcha_token }))
        .unwrap()
        .then(() => {
          setIsSuccessOpen(true);
          reset();
        })
        .catch((e: IFeedbackRejectBody) => {
          formErrorHandler({ errors: e.data, setError });
        });
      dataLayerEventHandler('feedback');
    };
    await sendWithCaptcha(cb);
  };

  return (
    <>
      <StatusScreen
        opened={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
        title={t('form.success')}
        description={t('form.success_description')}
        status={'success'}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col mb-[30px] gap-6'}>
        <Input textColor={'#ffffff'} name={'name'} label={i18n.t('name_form_fields.name')} control={control} />
        <Input textColor={'#ffffff'} name={'phone'} label={i18n.t('name_form_fields.phone')} control={control} />
        <Input textColor={'#ffffff'} name={'detail'} hidden control={control} />
        <Button loading={isActionFetching} className={'bg-blue'}>
          {t('form.submit')}
        </Button>
      </form>
    </>
  );
};
