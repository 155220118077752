import React, { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { Balance } from './balance';
import { Notifications } from './notifications';

import { WithdrawScenario, ReplenishScenario } from '6-features/wallet';
import { BurgerMenu } from '6-features/burgerMenu';
import { LangSwitcher } from '6-features/langSwitch';

import { selectIsAuth } from '7-entities/auth';
import { selectUser } from '7-entities/user';
import { Avatar, Burger, Button, Group, Header } from '8-shared/ui';
import { ReactComponent as CompanyLogo } from '8-shared/assets/icons/company_logo_large.svg';
import { ReactComponent as CompanyLogoSmall } from '8-shared/assets/icons/company_logo_small.svg';
import { useLocales } from '8-shared/lib/i18n';
import { PATH_PAGE } from '8-shared/lib/router';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';

export const LayoutHeader = () => {
  const { t } = useLocales('navigation');
  const navigate = useNavigate();
  const location = useLocation();

  const isAuth = useSelector(selectIsAuth);
  const user = useSelector(selectUser);

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const { classes } = useStyles();

  const onClickFeedbackHandler = () => {
    navigate(PATH_PAGE.feedback);
  };

  const onClickAuthHandler = () => {
    navigate(PATH_PAGE.register, {
      state: {
        backgroundLocation: location,
      },
    });
  };

  const onClickLogoHandler = () => {
    navigate(PATH_PAGE.root.venture);
  };

  const onClickProfileLogo = () => {
    navigate(PATH_PAGE.profile.portfolio);
  };

  useEffect(() => {
    if (isAuth && user?.id) {
      dataLayerEventHandler('userId', {
        event: 'userId',
        user_id: user.id,
      });
    }
  }, [isAuth]);

  return (
    <>
      <ReplenishScenario />
      <WithdrawScenario />
      <Header height={'min-content'}>
        <Group position="apart" sx={{ height: '100%' }}>
          <CompanyLogoSmall onClick={onClickLogoHandler} className={classes.logo} />
          <CompanyLogo onClick={onClickLogoHandler} className={classes.logo_large} />
          <Group>
            <Group>
              <Button className={'border-blue hidden laptop:block'} onClick={onClickFeedbackHandler} variant={'outline'} type={'button'}>
                {t('header.feedback')}
              </Button>
              <Notifications />
              <Balance />
              <Button className={`border-blue ${isAuth && 'hidden'}`} onClick={onClickAuthHandler} variant={'outline'} type={'button'}>
                {t('header.auth')}
              </Button>
              <Avatar className={`cursor-pointer ${!isAuth && 'hidden'}`} onCLick={onClickProfileLogo} src={user?.profile_photo} />
            </Group>
            <div className={'hidden mobile:block'}>
              <LangSwitcher />
            </div>
            <Burger size={'md'} color={drawerOpened ? '#ffffff' : '#42FF00'} opened={drawerOpened} onClick={toggleDrawer} />
          </Group>
        </Group>
      </Header>
      <BurgerMenu closeDrawer={closeDrawer} opened={drawerOpened} />
    </>
  );
};
