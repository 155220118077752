import React from 'react';
import { useSelector } from 'react-redux';

import { ActualDeals } from '5-widgets/actualDeals';
import { FloatingCompanies } from '5-widgets/floatingCompanies';
import { DigitalSafeBanner } from '6-features/digitalSafe';
import { selectAlternativeCompanies } from '7-entities/ipo';
import { useLocales } from '8-shared/lib/i18n';

export const AlternativePage = () => {
  const { t } = useLocales('main');
  const companies = useSelector(selectAlternativeCompanies);

  return (
    <div>
      <DigitalSafeBanner />
      <FloatingCompanies />
      <div className={'text-center font-500 text-3.6 leading-[47px] mt-[90px] mobile:mt-[130px] mb-[60px]'}>{t('actual_deals.title')}</div>
      <ActualDeals companies={companies} />
    </div>
  );
};
