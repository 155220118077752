import React from 'react';

import { IRefUser } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  item: IRefUser;
}
export const MobileRow = ({ item }: IProps) => {
  const { t } = useLocales('agent');
  return (
    <div className={'flex flex-col rounded-[15px] p-[20px] border border-white_opacity_50 bg-white_opacity_3 mb-[10px]'}>
      <span className={'text-[20px] truncate'}>{item.full_name}</span>
      <div
        className={`text-[12px] text-white_opacity_50
                            pb-[10px] border-b border-white_opacity_50
                            flex flex-row justify-between truncate`}
      >
        <span className={'w-[140px] truncate '}>{item.phone}</span>
        <span className={''}>{item.email}</span>
      </div>
      <div
        className={`text-white_opacity_50 py-[10px]
                            flex justify-between`}
      >
        <div className={`flex flex-col text-white `}>
          <span className={'text-[10px] text-white_opacity_50'}>{t('referals_table.table.referals_count')}</span>
          <span className={'text-[16px]  w-[120px] truncate'}>{item.number_of_invited}</span>
        </div>
        <div className={'flex flex-col text-right text-white'}>
          <span className={'text-[10px] text-white_opacity_50'}>{t('referals_table.table.amount')}</span>
          <span className={'text-[16px] w-[120px] truncate'}>{item.total_amount_of_money}</span>
        </div>
      </div>
    </div>
  );
};
