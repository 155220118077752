import React from 'react';
import cn from 'classnames';

export interface ISwitchTab {
  value: string | undefined;
  title: string;
}

interface IProps {
  tabs: ISwitchTab[];
  currentTab: ISwitchTab;
  onChange: (tab: ISwitchTab) => void;
  className?: string;
}

export const Switch = ({ tabs, onChange, currentTab, className }: IProps) => {
  return (
    <div className={`flex w-full overflow-y-scroll laptop:w-max justify-start whitespace-pre no-scrollbar ${className}`}>
      <div className={'flex w-full rounded-8 mobile:bg-white_opacity_8 cursor-pointer '}>
        {tabs.map((i, index) => (
          <div
            key={index}
            className={cn(
              'flex items-center justify-center min-w-[100px] w-full py-[5px] mobile:py-[20px] px-[15px] text-1.3 mobile:text-1.4 desktop:text-1.6 leading-[13px]',
              {
                ['bg-blue rounded-8']: currentTab.value === i.value,
              }
            )}
            onClick={() => onChange(i)}
          >
            {i.title}
          </div>
        ))}
      </div>
    </div>
  );
};
