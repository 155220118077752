import React from 'react';

import { FeedbackForm } from '6-features/feedback';
import contacts_shadow from '8-shared/assets/images/contacts_bg_image.webp';
import { useLocales } from '8-shared/lib/i18n';

export const ContactsPage = () => {
  const { t } = useLocales('contacts');
  const contact_rows = [
    { title: t('legal_body_name.title'), value: t('legal_body_name.value') },
    { title: t('legal_body_number.title'), value: t('legal_body_number.value') },
    { title: t('address.title'), value: t('address.value') },
    { title: t('activities.title'), value: t('activities.value') },
    { title: t('license_number.title'), value: t('license_number.value') },
    { title: t('license_date.title'), value: t('license_date.value') },
    { title: t('email.title'), value: t('email.value'), link: 'mailto:support@amcapital.pro' },
    { title: t('phone.title'), value: t('phone.value'), link: 'tel:971585913631' },
  ];

  return (
    <div className={'layout-padding pt-[24px] mobile:pt-[50px] laptop:pt-[95px] desktop:pt-[240px] pb-[100px] laptop:pb-[300px]'}>
      <img
        className={'w-full h-full opacity-0 mobile:opacity-100 absolute top-0 left-0'}
        alt={'contacts_page_photo_1'}
        src={contacts_shadow}
      />
      <h1 className={'block text-white font-700 mobile:font-600 text-3.6 mobile:text-4  laptop:text-7 tracking-widest mb-[90px]'}>
        {t('title')}
      </h1>
      <div className={'desktop:pl-80'}>
        <div className={'relative z-[1] flex flex-col justify-start desktop:justify-end'}>
          {contact_rows.map((el, index) => (
            <div key={index} className={'font-500 text-2 laptop:text-2.8 leading-[36px] mb-[42px]'}>
              <span className={'font-800 mr-[10px]'}>{el.title}</span>
              {el.link ? (
                <a className={'underline cursor-pointer'} href={el.link}>
                  {el.value}
                </a>
              ) : (
                <span>{el.value}</span>
              )}
            </div>
          ))}
          <hr className={'border-white_opacity_50 mb-[69px]'} />
        </div>
        <div className={'w-full laptop:w-1/2'}>
          <div className={'font-500 text-2.8 leading-[36px] mb-[11px]'}>{t('has_question')}</div>
          <div className={'font-300 text-1.6 leading-[20px] mb-[44px]'}>{t('has_question')}</div>
          <FeedbackForm />
        </div>
      </div>
    </div>
  );
};
