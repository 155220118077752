import React from 'react';

import { FeedbackForm } from '6-features/feedback';
import feedback_shadow from '8-shared/assets/images/feedback_shodow.webp';
import { useLocales } from '8-shared/lib/i18n';

export const FeedbackPage = () => {
  const { t } = useLocales('feedback');
  return (
    <div className={'layout-padding py-10 desktop:py-40'}>
      <img className={'absolute top-0 left-0 h-full opacity-30'} alt={'feedback_page_photo_1'} src={feedback_shadow} />
      <div className={'laptop:grid grid-cols-2 items-start gap-[100px]'}>
        <h1
          className={
            'uppercase font-600 mt-[20px] text-3 mobile:text-4 laptop:text-7 laptop:leading-[70px] mobile:w-full laptop:text-center z-10 text-white tracking-widest'
          }
        >
          {t('title')}
        </h1>
        <div className={'relative flex z-[1] py-3  flex-col mobile:py-10 mobile:w-4/6 laptop:w-4/5'}>
          <FeedbackForm />
        </div>
      </div>
    </div>
  );
};
