import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InfoBlock, InviteLink, ReferalsTable } from '5-widgets/profile/agent';
import { selectUser } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { config } from '8-shared/config';

export const AgentPage = () => {
  const { t } = useLocales('agent');

  const user = useSelector(selectUser);

  const individual_volumes = useMemo(
    () => [
      { title: t('individual_volume.count'), value: user?.number_of_invited ?? 0 },
      { title: t('individual_volume.amount'), value: `${user?.sum_of_invited ?? 0} $` },
    ],
    [user, t]
  );
  const group_volumes = useMemo(
    () => [
      { title: t('group_volume.count'), value: user?.number_of_second_layer_invited ?? 0 },
      { title: t('group_volume.amount'), value: `${user?.sum_of_second_layer_invited ?? 0} $` },
    ],
    [user, t]
  );

  return (
    <div className={'flex flex-col'}>
      <div className={'flex flex-col mb-[50px]'}>
        <div className={'flex flex-col py-[30px] '}>
          <span className={'text-3.6 font-600 leading-[35px] mobile:text-2.2 mb-[24px]'}>{t('title')}</span>
          <span className={'text-[16px] w-full mobile:w-[380px]  text-white_opacity_70'}>{t('description')}</span>
        </div>
        <div className={'flex flex-col laptop:flex-row'}>
          <InfoBlock title={`${t('individual_volume.title')}`} info={individual_volumes} />
          <InfoBlock title={`${t('group_volume.title')}`} info={group_volumes} />
        </div>
        <InviteLink inviteLink={`${config.REGISTER_REF_LINK}${user?.ref_field ?? ''}`} />
      </div>
      <div className={'py-[40px] border-t border-white_opacity_15'}>
        <ReferalsTable />
      </div>
    </div>
  );
};
