import { type IDigitalSageInfoDto } from '../api/types';
import { type IDigitalSageInfo } from '../model/types';

export const mapDigitalSafeInfo = (dto: IDigitalSageInfoDto): IDigitalSageInfo => {
  return {
    created_at: dto.created_at,
    deposit_amount: dto.deposit_amount,
    deposit_period: dto.deposit_period,
    fine: dto.fine,
    first_period: dto.first_period,
    icon: dto.icon,
    name: dto.name,
    next_stringperiod: dto.next_stringperiod,
    profit_rate: dto.profit_rate,
    translations: dto.translations,
  };
};
