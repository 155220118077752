import { IDataWithTranslate } from '8-shared/model';

export type IIPOId = Brand<Id, 'IPOId'>;

export enum IPO_TYPE {
  PRE_IPO = 'Pre-IPO',
  PROPERTIES = 'Property',
  VENTURE = 'Venture',
  ALTERNATIVE = 'Alternative',
}

export interface IIPO {
  id: IIPOId;
  translations: IDataWithTranslate<IIPOInfo>;
}

export interface IIPOInfo {
  capitalization: string;
  current_assessment: string;
  logo: Url;
  max_securities: number;
  presentation: Url;
  profit: string;
  primary_price: number;
  price: number;
  remain: number;
  video: string;
  type: IPO_TYPE | null;
  commission_percent: number;
  is_popular: boolean;
  year_of_placement: string;
  min_buy_lots: number;

  capitalization_measure: string;
  description: string;
  promo: string;

  title: string;
  dop_first_title: string;
  dop_first_description: string;
  dop_second_title: string;
  dop_second_description: string;
  dop_third_title: string;
  dop_third_description: string;

  detail_file: string;
  detail_link: string;
}

export interface IFloatingIPO {
  income_description: string;
  logo: Url;
  translations: IDataWithTranslate<IFloatingIPOTitle>;
}

export interface IFloatingIPOTitle {
  title: string;
}
