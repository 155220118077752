import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITransferBonusForm, userAPI } from '7-entities/user';
import { ICommonError, IRequestError } from '8-shared/model';

export type ITransferBonusReject = IRequestError<ICommonError>;

export const transferBonusThunk = createAsyncThunk<void, ITransferBonusForm, { state: RootState }>(
  'auth/login',
  async (data: ITransferBonusForm, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.getBonusRequest.initiate(data, { fixedCacheKey: 'getBonusRequest' })).unwrap();
      await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
