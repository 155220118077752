import React from 'react';

import { WalletHistory } from '5-widgets/profile/wallet';

import { Wallet } from '6-features/wallet';

import { useGetWalletBalanceQuery } from '7-entities/user';

export const WalletPage = () => {
  useGetWalletBalanceQuery();

  return (
    <div className={'flex flex-col gap-4'}>
      <Wallet />
      <WalletHistory />
    </div>
  );
};
