import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  root: {
    position: 'relative',
  },
  body: {
    alignItems: 'center',
  },
  label: {
    fontFamily: 'Montserrat, sans-serif',
  },
  input: {
    cursor: 'pointer',
    '&:checked': {
      backgroundColor: 'rgba(78, 110, 233, 1)',
      borderColor: 'rgba(78, 110, 233, 1)',
    },
  },
}));
