import { type IIPODto, type IFloatingIPODto, IBuyIPOBody, ISaleIPOBody } from './types';
import { mapIpo } from '../lib/mapIpo';
import { type IIPO, type IFloatingIPO } from '../model/types';
import { mapFloatingIpo } from '../lib/mapFloatingIpo';

import { baseApi } from '8-shared/api';
import { DOCUMENTS, HISTORY, IPO, PORTFOLIO_DEALS, PROFILE_INFO, WALLET } from '8-shared/api/tags';

export const ipoApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getIPO: build.query<IIPO[], void>({
      query: () => {
        return {
          url: 'securities/companies/',
          method: 'GET',
        };
      },
      providesTags: [IPO],
      transformResponse: (response: IIPODto[]) => response.map(mapIpo),
    }),

    getFloatingIPO: build.query<IFloatingIPO[], void>({
      query: () => {
        return {
          url: `/securities/floating_shares/`,
          method: 'GET',
        };
      },
      providesTags: [IPO],
      transformResponse: (response: IFloatingIPODto[]) => response.map(mapFloatingIpo),
    }),

    buyIPO: build.mutation<void, IBuyIPOBody>({
      query: (data) => {
        return {
          url: 'securities/portfolio_bought/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [IPO, PORTFOLIO_DEALS, WALLET, HISTORY, DOCUMENTS, PROFILE_INFO],
    }),

    saleIPO: build.mutation<void, ISaleIPOBody>({
      query: (data) => {
        return {
          url: '/securities/portfolio_sold/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [PORTFOLIO_DEALS, WALLET, IPO, HISTORY, DOCUMENTS, PROFILE_INFO],
    }),
  }),
});

export const { useGetIPOQuery, useGetFloatingIPOQuery, useBuyIPOMutation, useSaleIPOMutation } = ipoApi;
