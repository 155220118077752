import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import { invalidateJWTTokenListener } from '8-shared/api/listener';

import { baseApi } from '8-shared/api';

export const setupStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware).prepend(invalidateJWTTokenListener.middleware),
});

// Infer the `RootState` and `AppDispatch` model.ts from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof setupStore.dispatch;
