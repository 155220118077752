import { useState, useEffect } from 'react';

export const useFillArray = <T extends object>(arr: T[]) => {
  const [array, setArray] = useState<T[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const effectiveWidth = Math.min(windowWidth, 1660);
    const maxElements = Math.ceil(effectiveWidth / 225) + 2;

    if (arr.length < maxElements) {
      return setArray(new Array(maxElements).fill(arr).flat().slice(0, maxElements));
    }

    setArray(arr);
  }, [arr, windowWidth]);

  return array;
};
