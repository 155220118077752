import React, { useLayoutEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { IWithdrawForm } from '../../../../model/types';

import { Button, Input, Modal, Select } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

export interface IProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: IWithdrawForm) => void;
  max: number;
  onBack: () => void;
}

export const QuickWithdrawForm = ({ onSubmit, max, onBack, opened, onClose }: IProps) => {
  const { t, i18n } = useLocales('wallet');

  const schema = yup.object().shape({
    wallet_network: yup
      .string()
      .required(i18n.t('validation.required'))
      .max(256, i18n.t('validation.max', { count: 256 })),
    wallet_address: yup
      .string()
      .required(i18n.t('validation.required'))
      .max(256, i18n.t('validation.max', { count: 256 })),
    amount: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(1, i18n.t('validation.min_count', { count: 1 }))
      .max(max, i18n.t('validation.max_count', { count: max })),
  });

  const { control, handleSubmit, reset } = useForm<IWithdrawForm>({
    resolver: yupResolver(schema),
  });

  useLayoutEffect(() => {
    if (!opened) reset();
  }, [opened]);

  const data = ['TRC20', 'ERC20'];

  return (
    <Modal opened={opened} onClose={onClose} title={t('modals.withdraw.quick.title')} size={'lg'}>
      <form className={'mt-10 flex flex-col gap-5'} onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} name={'amount'} label={i18n.t('name_form_fields.amount_usdt')} type={'number'} />
        <Input control={control} name={'wallet_address'} label={i18n.t('name_form_fields.wallet_address')} />
        <Select
          className={'!mt-0 mb-5'}
          control={control}
          label={i18n.t('name_form_fields.network_wallet')}
          name={'wallet_network'}
          data={data}
          defaultValue={data[0]}
        />
        <div className={'flex gap-5 flex-col mobile:flex-row'}>
          <Button type={'button'} onClick={onBack} className={'border-blue text-blue w-full'} variant={'outline'}>
            {t('back')}
          </Button>
          <Button type={'submit'} className={'bg-blue w-full'}>
            {t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
