import { createAsyncThunk } from '@reduxjs/toolkit';

import { userAPI } from '7-entities/user';
import { ICommonError, IRequestError } from '8-shared/model';

export type ICreateAgentReject = IRequestError<ICommonError>;

export const becomeAgentThunk = createAsyncThunk<void, void, { state: RootState }>(
  'auth/login',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.createAgentRequest.initiate(_, { fixedCacheKey: 'createAgentRequest' })).unwrap();
      await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
