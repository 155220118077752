import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  inner: {
    padding: '0px',
    section: {
      borderRadius: 10,
    },
  },
}));
