import type { INotificationDto } from '../api/types';
import type { INotification } from '../model/types';

export const mapNotifocation = (dto: INotificationDto): INotification => {
  return {
    id: dto.id,
    title: dto.title,
    text: dto.text,
    is_read: dto.is_read,
    created_at: dto.created_at,
  };
};
