import { createSlice } from '@reduxjs/toolkit';
import { IDigitalSageInfo } from './types';
import { digitalSafeApi } from '../api/digitalSafe.api';

interface IIdoSlice {
  digital_safe_info: IDigitalSageInfo | undefined;
}

const initialState: IIdoSlice = {
  digital_safe_info: undefined,
};

export const digitalSafeSlice = createSlice({
  name: 'ido',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(digitalSafeApi.endpoints.getDigitalSafeInfo.matchFulfilled, (state: IIdoSlice, { payload }) => {
      state.digital_safe_info = payload;
    });
  },
});

export const selectDigitalSafeInfo = (state: RootState): IDigitalSageInfo => state.ido.digital_safe_info;
