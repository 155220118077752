import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { SALE_METHOD } from '../../../model/types';

import { useLocales } from '8-shared/lib/i18n';
import { Button, Modal } from '8-shared/ui';

interface IProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (method: SALE_METHOD) => void;
  onMore: () => void;
}

export const SaleMethod = ({ onSubmit, onMore, opened, onClose }: IProps) => {
  const { t } = useLocales('sale');

  const schema = yup.object().shape({
    method: yup.string(),
  });

  const { handleSubmit, setValue } = useForm<{ method: SALE_METHOD }>({
    resolver: yupResolver(schema),
  });

  return (
    <Modal opened={opened} onClose={onClose} title={t('title')} size={'lg'}>
      <form onSubmit={handleSubmit((data) => onSubmit(data.method))}>
        <button onClick={onMore} className={'text-blue underline text-[16px]'} type={'button'}>
          {t('learn_more')}
        </button>
        <div className={'flex flex-col gap-5 mt-10'}>
          <Button onClick={() => setValue('method', SALE_METHOD.QUICK)} type={'submit'} className={'bg-blue w-full'}>
            <div className={'flex flex-col gap-2.5 text-[16px] text-center'}>
              <span className={'font-600'}>{t('quick_sale.title')}</span>
              <span>{t('quick_sale.increased_commission')}</span>
            </div>
          </Button>
          <Button onClick={() => setValue('method', SALE_METHOD.SECONDARY)} type={'submit'} className={'bg-blue w-full'}>
            <div className={'flex flex-col gap-2.5 text-[16px] text-center'}>
              <span className={'font-600'}>{t('secondary_sale.title')}</span>
              <span>{t('secondary_sale.standard_commission')}</span>
            </div>
          </Button>
        </div>
      </form>
    </Modal>
  );
};
