import { useEffect, useState } from 'react';

interface IProps {
  initialTime: number;
  callBack?: () => void;
}
export const useCountdownTimer = ({ initialTime, callBack }: IProps) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  useEffect(() => {
    if (timeLeft <= 0) {
      callBack?.();
    }
    const interval = setInterval(() => {
      setTimeLeft((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);
  const restart = () => {
    setTimeLeft(initialTime);
  };
  return { timeLeft, restart };
};
