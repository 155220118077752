import React from 'react';

import { IWalletHistory } from '7-entities/user';

import { DesktopRow } from './desktopRow';
import { MobileRow } from './mobileRow';

interface IProps {
  item: IWalletHistory;
}

export const Row = ({ item }: IProps) => {
  return (
    <>
      <MobileRow item={item} />
      <DesktopRow item={item} />
    </>
  );
};
