import React from 'react';
import { useSelector } from 'react-redux';

import { Wallet } from '6-features/wallet';

import { selectProfileInfo } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  onClose: () => void;
  opened: boolean;
}
export const BalanceLaptop = ({ onClose, opened }: IProps) => {
  const { t } = useLocales('wallet');
  const info = useSelector(selectProfileInfo);

  return (
    <div
      className={`fixed top-[76px] left-0 bg-[#29264f] flex flex-col p-5 gap-6 rounded-b-15 w-full mobile:max-w-[640px] mobile:static mobile:rounded-15 mobile:border-white_opacity_12 mobile:border desktop:!hidden ${
        opened && 'block'
      }`}
    >
      <Wallet onClick={onClose} />
      <div className={'w-full flex  flex-col'}>
        <div className={'text-1.4 text-gray flex items-center justify-between'}>
          <div>{t('portfolio')}</div>
          <div className={'text-1.6'}>
            <span className={'text-white font-600'}>{info?.invest_amount || 0}</span> $
          </div>
        </div>
        <div className={'text-1.4 text-gray flex items-center justify-between'}>
          <div>{t('earned')}</div>
          <div className={'text-1.6'}>
            <span className={'text-white font-600'}>{info?.invest_profit || 0}</span> $
          </div>
        </div>
        <div className={'text-1.4 text-gray flex items-center justify-between'}>
          <div>{t('digital_safe')}</div>
          <div className={'text-1.6'}>
            <span className={'text-white font-600'}>{info?.digital_safe || 0}</span> $
          </div>
        </div>
      </div>
    </div>
  );
};
