import React from 'react';

interface IProps {
  title: string;
  value: string;
}

export const Property = ({ title, value }: IProps) => {
  return (
    <div className={'flex mobile:flex-col justify-between  mobile:items-start mb-[14px]'}>
      <div className={'font-400 leading-[20px] text-1.2 mobile:text-1.4  text-white_opacity_75'}>{title}</div>
      <div className={'font-600 leading-[25px] text-1.5 mobile:text-1.8'}>{value}</div>
    </div>
  );
};
