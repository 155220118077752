import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsShowVerify, setIsShowVerify, VERIFY_STATUS } from '7-entities/auth';

import { Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch, useAppSelector } from '8-shared/hooks';
import { selectVerifyStatus } from '7-entities/user';
import { VerifyPhoneNumberForm, Veriff } from '6-features/verify';

export const VerifyModal = () => {
  const { t } = useLocales('auth');
  const verifyStatus = useSelector(selectVerifyStatus);
  const dispatch = useAppDispatch();
  const isShowVerify = useAppSelector(selectIsShowVerify);

  const onPressCloseModalHandler = () => {
    dispatch(setIsShowVerify(false));
  };

  return (
    <Modal
      size={'lg'}
      opened={isShowVerify}
      keepMounted
      onClose={onPressCloseModalHandler}
      closeOnClickOutside={true}
      title={t('verify.description')}
      className={'[&_h1]:text-center'}
    >
      <div className={'flex mt-8'}>
        {verifyStatus === VERIFY_STATUS.NOT_VERIFIED ? <VerifyPhoneNumberForm /> : <Veriff onClose={onPressCloseModalHandler} />}
      </div>
    </Modal>
  );
};
