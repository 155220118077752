import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { ActualDeals } from '5-widgets/actualDeals';
import { FloatingCompanies } from '5-widgets/floatingCompanies';

import { QuicklyDeals } from '6-features/quicklyDeals';
import { MonthDeal } from '6-features/monthDeal';

import { selectCurrentLanguage } from '7-entities/app';
import { selectVentureCompanies, selectVentureMonthDeal } from '7-entities/ipo';
import { selectUser } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { openUrlInNewTab } from '8-shared/lib/utils';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const VenturePage = () => {
  const { t } = useLocales('main');
  const dealRef = useRef<HTMLDivElement | null>(null);

  const monthDeal = useSelector(selectVentureMonthDeal);
  const lang = useSelector(selectCurrentLanguage);
  const companies = useSelector(selectVentureCompanies);
  const user = useSelector(selectUser);

  const onClickBuyHandler = () => {
    dealRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onClickMoreHandler = () => {
    myTracker(user?.deal_id, TRACKER_EVENT.lot_more);
    openUrlInNewTab(monthDeal?.translations?.[lang]?.detail_file ?? monthDeal?.translations?.[lang]?.detail_link);
  };

  return (
    <div>
      <MonthDeal onClickBuyHandler={onClickBuyHandler} onClickMoreHandler={onClickMoreHandler} deal={monthDeal} />
      <FloatingCompanies />
      <div>
        <div className={'text-center font-500 text-3.6 leading-[47px] mt-[90px] mobile:mt-[130px] mb-[60px]'}>
          {t('actual_deals.title')}
        </div>
        <div className={'relative'} ref={dealRef}>
          <ActualDeals companies={companies} />
        </div>
        <QuicklyDeals />
      </div>
    </div>
  );
};
