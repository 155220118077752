import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type IP2pDeal } from './types';
import { p2pApi } from '../api/p2p.api';

interface ICompanySlice {
  p2pDeals: IP2pDeal[];
  buyP2PModal: {
    isOpen: boolean;
    item: IP2pDeal | undefined;
  };
}

const initialState: ICompanySlice = {
  p2pDeals: [],
  buyP2PModal: {
    isOpen: false,
    item: undefined,
  },
};

export const p2pSlice = createSlice({
  name: 'p2p',
  initialState,
  reducers: {
    openBuyP2PModal: (state: ICompanySlice, action: PayloadAction<IP2pDeal>) => {
      state.buyP2PModal = {
        isOpen: true,
        item: action.payload,
      };
    },
    closeBuyP2PModal: (state: ICompanySlice) => {
      state.buyP2PModal = {
        isOpen: false,
        item: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(p2pApi.endpoints.getP2PDeals.matchFulfilled, (state: ICompanySlice, { payload }) => {
      state.p2pDeals = payload;
    });
  },
});
export const { openBuyP2PModal, closeBuyP2PModal } = p2pSlice.actions;
export const selectP2pDeals = (state: RootState): IP2pDeal[] => state.p2p.p2pDeals;
export const selectBuyP2PModal = (state: RootState): { item: IP2pDeal | undefined; isOpen: boolean } => state.p2p.buyP2PModal;
