import React, { useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSelector } from 'react-redux';

import { IReplenishCurrencyForm } from '../../../../model/types';
import { replenishmentCurrencyWallet } from '../../../../model/wallet.thunks';

import { selectUser, CURRENCY } from '7-entities/user';

import { Button, Input, Modal, Select } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const ReplenishCurrencyForm = ({ opened, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useLocales('wallet');
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(1, i18n.t('validation.min_count', { count: 1 })),
    currency: yup.string().required(i18n.t('validation.required')),
  });

  const { control, handleSubmit, reset, watch } = useForm<IReplenishCurrencyForm>({
    resolver: yupResolver(schema),
  });

  useLayoutEffect(() => {
    if (!opened) reset();
  }, [opened]);

  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  const onSubmit = (data: IReplenishCurrencyForm) => {
    setIsLoading(true);
    dispatch(replenishmentCurrencyWallet({ amount: data.amount, currency: data.currency }))
      .unwrap()
      .then(() => myTracker(user?.deal_id, TRACKER_EVENT.try_replenish_success(watch('amount'))))
      .catch(() => myTracker(user?.deal_id, TRACKER_EVENT.try_replenish_failed(watch('amount'))))
      .finally(() => changeLoading());
  };
  const onCloseHandler = () => {
    onClose();
    myTracker(user?.deal_id, TRACKER_EVENT.try_replenish_unfinished(watch('amount')));
  };

  const params = [
    { value: CURRENCY.USD, label: CURRENCY.USD },
    { value: CURRENCY.EUR, label: CURRENCY.EUR },
    { value: CURRENCY.RUB, label: CURRENCY.RUB },
    { value: CURRENCY.AED, label: CURRENCY.AED },
  ];

  return (
    <Modal opened={opened} onClose={onCloseHandler} title={t('modals.replenishment.title')} size={'lg'}>
      <form className={'mt-10 flex flex-col gap-5 ![&_>_div]:bg-blue'} onSubmit={handleSubmit(onSubmit)}>
        <Select control={control} name={'currency'} data={params} label={i18n.t('name_form_fields.currency')} />
        <Input control={control} name={'amount'} label={i18n.t('name_form_fields.amount')} type={'number'} />
        <div className={'text-1.6 text-black_text_opacity_70'}>{t('modals.replenishment.call_manager')}</div>
        <Button type={'submit'} className={'bg-blue w-full'} variant={'filled'} loading={isLoading} disabled={isLoading}>
          {t('modals.replenishment.send')}
        </Button>
      </form>
    </Modal>
  );
};
