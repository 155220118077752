import { IUserDto } from '../api/types';
import { IUser, REGISTER_STAGE, UserId } from '../model/types';

export const mapUser = (dto: IUserDto): IUser => {
  return {
    ...dto,
    id: dto.id as UserId,
    ref_money: dto.ref_money ? Number(dto.ref_money) : 0,
    manager: {
      ...dto.manager,
    },
    register_stage: dto.register_stage as REGISTER_STAGE,
  };
};
