import React from 'react';

import { DigitalSafeBanner, DigitalSafes } from '5-widgets/profile/digitalSafe';
import { useGetDigitalSafeInfoQuery } from '7-entities/digitalSafe';
import { Loader } from '8-shared/ui';

export const DigitalSafePage = () => {
  const { isLoading: infoLoading } = useGetDigitalSafeInfoQuery();

  if (infoLoading) {
    return <Loader />;
  }

  return (
    <div className={'flex flex-col gap-5'}>
      <DigitalSafeBanner />
      <DigitalSafes />
    </div>
  );
};
