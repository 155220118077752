import React from 'react';
import { useSelector } from 'react-redux';

import { BuyButton } from '6-features/protectedButtons';

import { selectWalletBalance } from '7-entities/user';
import { openReplenishmentModal, openWithdrawModal } from '7-entities/user/model/user.slice';

import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';

interface IProps {
  onClick?: () => void;
}

export const Wallet = ({ onClick }: IProps) => {
  const { t } = useLocales('wallet');
  const dispatch = useAppDispatch();
  const wallet = useSelector(selectWalletBalance);
  const onBuy = () => {
    onClick?.();
    dispatch(openReplenishmentModal());
  };
  const onSale = () => {
    onClick?.();
    dispatch(openWithdrawModal());
  };

  return (
    <div
      className={
        'flex flex-col mobile:flex-row mobile:items-center gap-5 justify-between bg-white_opacity_10 p-3 laptop:p-5 border border-[#52506F] rounded-15'
      }
    >
      <div className={'flex flex-col gap-2'}>
        <div className="opacity-50 text-white text-1.4 leading-[14px] font-600">{t('balance')}</div>
        <div className="text-white text-2 font-600 leading-[28px] whitespace-nowrap">{`${wallet.toFixed(2)} USDT`}</div>
      </div>
      <div className={'flex gap-5'}>
        <BuyButton onClick={onBuy} bg={'#3AC247'} className={'bg-[#3AC247] w-full text-1.6 px-[10px] mobile:px-[30px] py-[15px]'}>
          {t('buy')}
        </BuyButton>
        <BuyButton
          isNeedVerify={true}
          bg={'#E94E4E'}
          onClick={onSale}
          className={'bg-[#E94E4E] w-full text-1.6 px-[10px] mobile:px-[30px] py-[15px]'}
        >
          {t('sale')}
        </BuyButton>
      </div>
    </div>
  );
};
