import React from 'react';
import { Header as MantineHeader, type HeaderProps } from '@mantine/core';
import { useStyles } from './styles';

export const Header = ({ children, ...rest }: HeaderProps) => {
  const { classes } = useStyles();
  return (
    <MantineHeader className={classes.header} {...rest}>
      {children}
    </MantineHeader>
  );
};
