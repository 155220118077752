import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';

import { getUserFullName } from '../model/getUserFullName';

export const UserInfo = () => {
  const { t } = useLocales('settings');
  const user = useSelector(selectUser);
  return (
    <div className={'flex flex-col font-400 text-1.6 small_mobile:text-1.8 leading-[22px] mt-[20px] mb-[20px]'}>
      <div className={'mb-[5px]'}>{getUserFullName(user)}</div>
      <div className={'flex flex-col gap-4'}>
        {user?.phone && (
          <div className={'flex '}>
            <div className={'text-white_opacity_50 small_mobile:text-white mr-[5px]'}>{t('phone_number')}</div>
            <div>{user?.phone}</div>
          </div>
        )}
        <div className={'flex'}>
          <div className={'text-white_opacity_50 small_mobile:text-white mr-[5px]'}>{t('contract_number')}</div>
          <div className={'text-blue'}>{user?.deal_id}</div>
        </div>
      </div>
    </div>
  );
};
