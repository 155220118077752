import React from 'react';
import { Button, CopyButton as MantineCopyButton } from '@mantine/core';

interface IProps {
  value: string;
  label: string;
  copiedLabel: string;
  className?: string;
}

export const CopyButton = ({ value, label, copiedLabel, className }: IProps) => {
  return (
    <MantineCopyButton value={value}>
      {({ copied, copy }) => (
        <Button
          className={`${copied ? 'bg-[#099269]' : 'bg-blue'} mobile:h-auto ${className}`}
          color={copied ? 'teal' : 'indigo'}
          onClick={copy}
        >
          {copied ? copiedLabel : label}
        </Button>
      )}
    </MantineCopyButton>
  );
};
