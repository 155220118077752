import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useViewportSize } from '@mantine/hooks';

import { selectUser } from '7-entities/user';
import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import agent_banner_image_420 from '8-shared/assets/images/agent_banner_image_420.png';
import agent_banner_image_640 from '8-shared/assets/images/agent_banner_image_640.png';
import agent_banner_image_1024 from '8-shared/assets/images/agent_banner_image_1024.png';
import agent_banner_bg_420 from '8-shared/assets/images/agent_banner_bg_420.png';
import agent_banner_bg_640 from '8-shared/assets/images/agent_banner_bg_640.png';
import agent_banner_bg_1024 from '8-shared/assets/images/agent_banner_bg_1024.png';

import { AgentRequestScenario } from '../agentRequestScenario/agentRequestScenario';

export const AgentBanner = () => {
  const { t } = useLocales('settings');
  const user = useSelector(selectUser);

  const [open, setOpen] = useState(false);

  const { width } = useViewportSize();

  const onClickInviteHandler = () => {
    setOpen(true);
  };
  const onClickCloseInviteHandler = () => {
    setOpen(false);
  };

  const getImageForScreen = useCallback(() => {
    if (width < 640) return [agent_banner_image_420, agent_banner_bg_420];
    if (width < 1280) return [agent_banner_image_640, agent_banner_bg_640];
    return [agent_banner_image_1024, agent_banner_bg_1024];
  }, [width]);

  if (user?.is_agent) {
    return null;
  }

  return (
    <div className={'w-full h-full relative rounded-10 large:rounded-30'}>
      <AgentRequestScenario opened={open} onClose={onClickCloseInviteHandler} />

      <img alt={'agent_bg'} src={getImageForScreen()[1]} className={'absolute z-[3]  w-full h-full'} />
      <img
        alt={'agent_bg_image'}
        src={getImageForScreen()[0]}
        className={'absolute z-[3] right-0 h-full overflow-hidden mobile:overflow-auto'}
      />
      <div className={'flex flex-col gap-[15px] px-[22px] py-[30px] large:px-[50px]'}>
        <div className={'text-2.2 mobile:text-3.6  font-500'}>{t('agent_banner.title')}</div>
        <div className={'text-1.4 mobile:text-1.8 font-400 text-white_opacity_70 w-[284px] mobile:w-[300px]'}>
          {t('agent_banner.description')}
        </div>
        <Button type={'button'} onClick={onClickInviteHandler} className={'bg-blue w-max px-[30px] relative text-1.8 z-10'}>
          {t('agent_banner.invite_btn')}
        </Button>
      </div>
    </div>
  );
};
