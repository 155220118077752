import React from 'react';

interface IProps {
  label: string;
  color: string | null;
  className?: string;
}

export const Tag = ({ label, color, className }: IProps) => {
  if (!label) return null;
  return <div className={`shrink-0 text-1.5 font-600 text-black rounded-[10px] p-[10px] ${color} ${className}`}>{label}</div>;
};
