import React from 'react';
import cn from 'classnames';

import { BuyButton } from '6-features/protectedButtons';

import { openBuyP2PModal } from '7-entities/p2p/model/p2p.slice';
import { selectCurrentLanguage } from '7-entities/app';
import { IP2pDeal } from '7-entities/p2p';

import { useLocales } from '8-shared/lib/i18n';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '8-shared/hooks';

interface IProps {
  deal: IP2pDeal;
}

export const QuicklyDealCard = ({ deal }: IProps) => {
  const { t, i18n } = useLocales('main');
  const lang = useSelector(selectCurrentLanguage);
  const dispatch = useAppDispatch();

  const onClickBuy = () => {
    dispatch(openBuyP2PModal(deal));
  };

  if (deal?.is_fake) {
    return (
      <div className={'min-w-[350xp] h-[164px] trading-red-light-card rounded-14 px-[10px] pt-[17px] pb-[20px] mb-[10px] mx-[10px]'}>
        <div className={'text-2 text-center'}>{t('quickly_deals.fake_card_title')}</div>
        <hr className={'border border-white border-opacity-60 my-[14px]'} />
      </div>
    );
  }
  return (
    <div
      className={cn('trading-red-card h-[164px] rounded-14 px-[10px] pt-[17px] pb-[20px] mb-[10px] mx-[10px]', {
        ['trading-blue-card']: deal.color === 'blue',
        ['trading-red-card']: deal.color === 'red',
        ['trading-purple-card']: deal.color === 'green',
      })}
    >
      <div className={'flex justify-between items-center'}>
        <img
          alt={'main_page_company_logo'}
          src={`${deal?.operation?.company?.translations?.[lang]?.logo}`}
          className={'h-[23px]  -indent-[99999px]'}
        />
        <div className={'flex flex-col gap-[6px]'}>
          <div className={'font-600 text-2.2 leading-[27px]'}>{`$ ${(Number(deal?.price_per_security) * deal?.quantity)?.toFixed(2)}`}</div>
          <div className={'flex gap-[10px] justify-between font-400 text-1.2 leading-[12px] text-white_opacity_60'}>
            <div>{`${i18n.t('plural.lot', { count: deal?.quantity })}`}</div>
            <div>|</div>
            <div>{Number(deal?.price_per_security)?.toFixed(2)}$</div>
          </div>
        </div>
      </div>

      <hr className={'border border-white border-opacity-60 my-[14px]'} />

      <div className={'grid grid-cols-2 gap-[10px]'}>
        <div className={'text-white_opacity_75 text-1.2 leading-[16px]'}>{deal?.comment === 'offer' ? '' : deal?.comment}</div>
        <BuyButton onClick={onClickBuy} disabled={deal?.is_mine} className={'bg-blue'}>
          {t('quickly_deals.buy')}
        </BuyButton>
      </div>
    </div>
  );
};
