import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { OpenDigitalSafeFormModal } from './openDigitalSafeFormModal/openDigitalSafeFormModal';
import { ConfirmOpenDigitalSafeModal } from './confirmOpenDigitalSafeModal/confirmOpenDigitalSafeModal';
import { OPEN_DIGITAL_SAFE_STEPS } from '../../model/types';
import { openDigitalSafeThunk } from '../../model/digitalSafe.thunk';

import { IOpenDigitalSafeForm, selectUser, useOpenDigitalSafeMutation } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { StatusScreen } from '8-shared/ui';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const OpenDigitalSageScenario = ({ opened, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocales('digitalSafe');
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [step, setStep] = useState<OPEN_DIGITAL_SAFE_STEPS>(OPEN_DIGITAL_SAFE_STEPS.FORM);
  const [form, setForm] = useState<IOpenDigitalSafeForm | null>(null);
  const [, { reset }] = useOpenDigitalSafeMutation({ fixedCacheKey: 'openDigitalSafe' });

  const onSubmitForm = (data: IOpenDigitalSafeForm) => {
    setForm(data);
    setStep(OPEN_DIGITAL_SAFE_STEPS.CONFIRM);
  };
  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };
  const openDigitalSafe = () => {
    if (!form) {
      setStep(OPEN_DIGITAL_SAFE_STEPS.FORM);
      return;
    }
    setIsLoading(true);
    dispatch(openDigitalSafeThunk(form))
      .unwrap()
      .then(() => {
        myTracker(user?.deal_id, TRACKER_EVENT.buy_digital_safe);
        setStep(OPEN_DIGITAL_SAFE_STEPS.SUCCESS);
      })
      .catch(() => setStep(OPEN_DIGITAL_SAFE_STEPS.ERROR))
      .finally(() => changeLoading());
  };

  const onClickBackToForm = () => {
    setStep(OPEN_DIGITAL_SAFE_STEPS.FORM);
  };

  const onCloseHandler = () => {
    onClose();
    reset();
    setStep(OPEN_DIGITAL_SAFE_STEPS.FORM);
  };

  if (!opened) {
    return null;
  }

  return (
    <>
      <OpenDigitalSafeFormModal onClose={onCloseHandler} opened={step === OPEN_DIGITAL_SAFE_STEPS.FORM} onSubmitForm={onSubmitForm} />
      <ConfirmOpenDigitalSafeModal
        onClose={onCloseHandler}
        opened={step === OPEN_DIGITAL_SAFE_STEPS.CONFIRM}
        onSubmit={openDigitalSafe}
        isLoading={isLoading}
        info={form}
        onBack={onClickBackToForm}
      />
      <StatusScreen
        opened={step === OPEN_DIGITAL_SAFE_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={`${t('open_modals.statuses.success')}`}
        description={`${t('open_modals.statuses.success_desc')}`}
        status={'success'}
      />
      <StatusScreen
        opened={step === OPEN_DIGITAL_SAFE_STEPS.ERROR}
        onClose={onCloseHandler}
        title={t('open_modals.statuses.error')}
        description={t('open_modals.statuses.error_desc')}
        status={'error'}
      />
    </>
  );
};
