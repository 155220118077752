const REFERAL_NAME = 'referal_id';

export const setReferalId = (id: string) => {
  localStorage.setItem(REFERAL_NAME, id);
};

export const getReferalId = (): string | undefined => {
  const id = localStorage.getItem(REFERAL_NAME);
  return id || undefined;
};
