import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

export const OurNumbers = () => {
  const { t } = useLocales('about');

  const numbers = [
    { title: t('our_numbers.companies'), count: '4' },
    { title: t('our_numbers.purchased_property'), count: '3' },
    { title: t('our_numbers.venture'), count: '3' },
    { title: t('our_numbers.pre_ipo_count'), count: '7' },
    { title: t('our_numbers.deals'), count: '9' },
    { title: t('our_numbers.currency'), count: '218 %' },
    { title: t('our_numbers.experience'), count: `>10 ${t('our_numbers.years')}` },
  ];

  return (
    <div className={'layout-padding-mobile mb-[50px] mobile:mb-[75px]'}>
      <div className={'about_today_bg rounded-30 py-[30px] px-[15px] mobile:px-[28px] mobile:py-[70px] laptop:px-[47px]'}>
        <div
          className={
            'font-500 uppercase text-center text-2.3 leading-[30px] mobile:text-3.6 mobile:leading-[47px] pb-[25px] mobile:pb-[70px]'
          }
        >
          {t('our_numbers.title')}
        </div>
        <div className={'grid grid-cols-1 mobile:grid-cols-2 laptop:grid-cols-4 mobile:gap-[43px]'}>
          {numbers.map((i) => (
            <div
              key={i.title}
              className={
                'grid grid-cols-2 font-600 mobile:grid-cols-1 items-center py-[10px] border-b border-white_opacity_20 mobile:border-none'
              }
            >
              <div className={'text-1.4 mobile:text-1.6 leading-[19px] mobile:leading-[21px] text-white_opacity_70'}>{i.title}</div>
              <div
                className={
                  'font-600 mobile:font-300 mobile:text-3.6 laptop:text-5.6 laptop:leading-[73px] mobile:leading-[47px] text-2.3 leading-[30px]'
                }
              >
                {i.count}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
