import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { mainPageTabs } from '../../model/mainPageTabs';

import { selectUser } from '7-entities/user';

import { type ITab, TabSwitcher } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { PATH_PAGE } from '8-shared/lib/router';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const MainPageSwitcher = () => {
  const { t } = useLocales('main');
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);

  const [tab, setTab] = useState<ITab>(mainPageTabs[0]);

  const onChangeCurrentTabHandler = (currentTab: ITab) => {
    if (currentTab.value !== tab?.value) {
      navigate(currentTab.value);
      setTab(currentTab);
    }
    if (currentTab.value === PATH_PAGE.root.venture) {
      myTracker(user?.deal_id, TRACKER_EVENT.venture);
    }
    if (currentTab.value === PATH_PAGE.root.realEstate) {
      myTracker(user?.deal_id, TRACKER_EVENT.property);
    }
    if (currentTab.value === PATH_PAGE.root.alternative) {
      myTracker(user?.deal_id, TRACKER_EVENT.altertative);
    }
  };

  useEffect(() => {
    onChangeCurrentTabHandler(mainPageTabs.find((i) => i.value === location.pathname) || mainPageTabs[0]);
  }, []);

  return (
    <div className={'w-full flex flex-col items-center justify-center'}>
      <span className={'text-[14px] mobile:text-[26px] text-center mb-[25px]'}>{t('change_category')}</span>
      <TabSwitcher data={mainPageTabs} activeTab={tab?.value} onChange={onChangeCurrentTabHandler} />
    </div>
  );
};
