import { LangVariants } from './types';
import { config } from '../../config';

export const language = {
  getCurrentLanguage() {
    if (localStorage.getItem('lang')) {
      return localStorage.getItem('lang');
    } else {
      this.setLanguage(navigator.language.includes('ru') ? config.SUPPORTED_LANG[0] : config.SUPPORTED_LANG[1]);
      return localStorage.getItem('lang');
    }
  },
  setLanguage(lang: LangVariants) {
    return localStorage.setItem('lang', lang);
  },
};
