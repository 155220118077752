import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { selectCurrentLanguage } from '7-entities/app';
import { type IWalletHistory, WALLET_HISTORY_STATUS, WALLET_HISTORY_TYPE } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { getDate, getMonthName } from '8-shared/lib/utils';

interface IProps {
  item: IWalletHistory;
}

export const DesktopRow = ({ item }: IProps) => {
  const { t } = useLocales('wallet');
  const lang = useSelector(selectCurrentLanguage);
  return (
    <div
      className={
        'w-full hidden laptop:flex items-center flex-row justify-between mb-[15px] text-2 border border-white_opacity_12 px-[25px] py-[30px] rounded-15 bg-white_opacity_5'
      }
    >
      <span className={'desktop:w-[40%] w-[30%] uppercase font-600'}>
        {item.type === WALLET_HISTORY_TYPE.REPLENISHMENT && t('table.type.replenishment')}
        {item.type === WALLET_HISTORY_TYPE.WITHDRAW && t('table.type.withdraw')}
      </span>
      <span
        className={cn('w-[20%] text-center uppercase font-500', {
          ['text-[#18AD00]']: item.status === WALLET_HISTORY_STATUS.APPROVED,
          ['text-[#FF2B2B]']: item.status === WALLET_HISTORY_STATUS.DECLINED,
          ['text-[#8E8E8C]']: item.status === WALLET_HISTORY_STATUS.IN_PROGRESS,
        })}
      >
        {item.status === WALLET_HISTORY_STATUS.APPROVED && t('table.status.approved')}
        {item.status === WALLET_HISTORY_STATUS.DECLINED && t('table.status.declined')}
        {item.status === WALLET_HISTORY_STATUS.IN_PROGRESS && t('table.status.in_progress')}
      </span>
      <span className={'flex justify-center w-[25%] text-center uppercase gap-2 font-600'}>
        <span className={'text-white'}>{item.type === WALLET_HISTORY_TYPE.WITHDRAW ? `-${item.amount}` : `+${item.amount}`}</span>
        <span className={'text-[#8E8E8C]'}>{item.currency}</span>
      </span>
      <span className={'flex flex-col w-[20%] text-center uppercase font-600'}>
        <span>{getDate(item.updated_at)}</span>
        <span className={'text-0.7 text-[#8E8E8C]'}>{getMonthName(item?.updated_at, lang)}</span>
      </span>
    </div>
  );
};
