import React from 'react';

import image_1 from '8-shared/assets/images/about_why_we_image_1.webp';
import image_2 from '8-shared/assets/images/about_why_we_image_2.webp';
import image_3 from '8-shared/assets/images/about_why_we_image_3.webp';
import image_4 from '8-shared/assets/images/about_why_we_image_4.webp';
import image_5 from '8-shared/assets/images/about_why_we_image_5.webp';
import image_6 from '8-shared/assets/images/about_why_we_image_6.webp';
import { useLocales } from '8-shared/lib/i18n';

export const WhyWe = () => {
  const { t } = useLocales('about');

  const why_cards = [
    { title: t('why_we_title_1'), image: image_1 },
    { title: t('why_we_title_2'), image: image_2 },
    { title: t('why_we_title_3'), image: image_3 },
    { title: t('why_we_title_4'), image: image_4 },
    { title: t('why_we_title_5'), image: image_5 },
    { title: t('why_we_title_5'), image: image_6 },
  ];

  return (
    <div className={'border-dashed layout-padding mb-[72px] mobile:mb-[90px] laptop:mb-[143px]'}>
      <div className={'uppercase text-3.6 leading-[47px] font-500 pb-[43px] mobile:pb-[57px] laptop:pb-[110px]'}>{t('why_we_title')}</div>
      <div className={'grid grid-cols-1 items-center laptop:grid-cols-3 laptop:gap-[70px]'}>
        {why_cards.map((i, index) => (
          <div key={index} className={'flex flex-col mobile:flex-row laptop:flex-col laptop:items-start items-center gap-[20px]'}>
            <img src={i.image} alt={''} className={'w-[150px] h-[150px]'} />
            <div className={'text-center mobile:text-left text-1.4 mobile:text-2 font-500 leading-[19px] mobile:leading-[26px]'}>
              {i.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
