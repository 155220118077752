import React, { useState } from 'react';
import { useViewportSize } from '@mantine/hooks';
import { useSelector } from 'react-redux';

import { OpenDigitalSageScenario } from '6-features/digitalSafe';
import { BuyButton, MoreButton } from '6-features/protectedButtons';

import { selectDigitalSafeInfo } from '7-entities/digitalSafe';
import { selectCurrentLanguage } from '7-entities/app';
import { selectUser } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { openUrlInNewTab } from '8-shared/lib/utils';
import img420 from '8-shared/assets/images/digital_safe_bg_image_420.png';
import img640 from '8-shared/assets/images/digital_safe_bg_image_640.png';
import img1024 from '8-shared/assets/images/digital_safe_bg_image_1024.png';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const DigitalSafeBanner = () => {
  const { t, i18n } = useLocales('digitalSafe');
  const { width } = useViewportSize();
  const digitalSafe = useSelector(selectDigitalSafeInfo);
  const lang = useSelector(selectCurrentLanguage);
  const user = useSelector(selectUser);

  const [open, setOpen] = useState<boolean>(false);

  const getImageForScreen = () => {
    if (width < 640) return img420;
    if (width < 1280) return img640;
    return img1024;
  };

  const onPressMore = () => {
    openUrlInNewTab(digitalSafe.translations[lang].detailed_pdf);
    myTracker(user?.deal_id, TRACKER_EVENT.safe_more);
  };

  return (
    <div className={'w-full h-full relative rounded-10 large:rounded-30 digital_safe_gradient_bg'}>
      <img
        alt={'staking_bg_image'}
        src={getImageForScreen()}
        className={'absolute z-3 right-0 h-full overflow-hidden mobile:overflow-auto'}
      />
      <div className={'flex flex-col px-6 py-8 large:px-12 gap-4'}>
        <div className={'flex flex-col laptop:flex-row w-full justify-between gap-4'}>
          <div className={'flex flex-col text-2.8 font-500 '}>
            <div>{t('title')}</div>
            <div className={'text-[#82FB4D]'}>{t('condition', { count: Number(digitalSafe?.profit_rate) })}</div>
          </div>

          <div
            className={'flex flex-col mobile:flex-row mobile:justify-between p-4 gap-2 bg-white_opacity_10 rounded-10 w-full laptop:w-max'}
          >
            <div className={'flex mobile:flex-col w-full mobile:max-w-[120px] mobile:text-center gap-1 justify-between'}>
              <div className={'text-1.2 text-white_opacity_80'}>{digitalSafe?.translations?.[lang]?.title}</div>
              <div className={'text-1.6 laptop:text-2 font-700 whitespace-pre'}>
                {`${i18n.t('plural.month', { count: digitalSafe?.deposit_period })}`}
              </div>
            </div>
            <div className={'flex mobile:flex-col mobile:max-w-[120px] mobile:text-center gap-1 justify-between'}>
              <div className={'text-1.2 text-white_opacity_80'}>{digitalSafe?.translations?.[lang]?.title2}</div>
              <div className={'text-1.6 laptop:text-2 font-700 whitespace-pre'}>{`от ${Number(digitalSafe?.deposit_amount)}`}</div>
            </div>
            <div className={'flex mobile:flex-col mobile:max-w-[120px] mobile:text-center gap-1 justify-between'}>
              <div className={'text-1.2 text-white_opacity_80'}>{digitalSafe?.translations?.[lang]?.title3}</div>
              <div className={'text-1.6 laptop:text-2 font-700 whitespace-pre'}>{`${digitalSafe?.profit_rate}%`}</div>
            </div>
          </div>
        </div>

        <div className={'flex flex-col mobile:flex-row gap-4'}>
          <MoreButton onClick={onPressMore} className={'w-full laptop:w-max'} variant={'outline'}>
            {t('more_btn')}
          </MoreButton>
          <BuyButton onClick={() => setOpen(true)} className={'bg-blue w-full laptop:w-max'}>
            {t('invest_btn')}
          </BuyButton>
        </div>
      </div>
      <OpenDigitalSageScenario opened={open} onClose={() => setOpen(false)} />
    </div>
  );
};
