import React from 'react';
import cn from 'classnames';

interface IProps {
  max: number;
  current: number;
  isSmall?: boolean;
}

export const RemainIndicator = ({ max, current, isSmall = false }: IProps) => {
  const percentDifference = Math.round(((max - current) / max) * 100);
  return (
    <div
      className={cn('relative w-full h-[6px] mobile:h-[10px] rounded-11 bg-black_opacity_25', {
        ['h-[4px] mobile:h-[4px]']: isSmall,
      })}
    >
      <div
        style={{ width: `${percentDifference}%` }}
        className={cn('absolute h-full rounded-11  top-0 left-0', {
          ['green_gradient_process_bg']: percentDifference < 85,
          ['bg-red_custom']: percentDifference >= 85,
        })}
      />
    </div>
  );
};
