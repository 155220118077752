import { baseApi } from '8-shared/api';

import type { IDigitalSageInfoDto } from './types';
import { mapDigitalSafeInfo } from '../lib/mapDigitalSafeInfo';
import { IDigitalSageInfo } from '../model/types';

export const digitalSafeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDigitalSafeInfo: build.query<IDigitalSageInfo, void>({
      query: () => {
        return {
          url: '/securities/safe/',
        };
      },
      transformResponse: (response: IDigitalSageInfoDto) => mapDigitalSafeInfo(response),
    }),
  }),
});

export const { useGetDigitalSafeInfoQuery } = digitalSafeApi;
