import { useEffect } from 'react';
import { config } from '../config';

export const useCaptcha = () => {
  useEffect(() => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_KEY}`;
    document.head.appendChild(recaptchaScript);
  }, []);

  const sendWithCaptcha = (cb: (token: string) => void) => {
    //eslint-disable-next-line
    // @ts-ignore
    grecaptcha.ready(async () => {
      //eslint-disable-next-line
      // @ts-ignore
      const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' });
      cb(token);
      // await sendFeedback({ ...data, recaptcha_token: token }).unwrap();
    });
  };

  return { sendWithCaptcha };
};
