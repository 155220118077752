import { IReplenishmentCurrencyWalletBody, IReplenishmentUSDTWalletBody, IWithdrawWalletBody } from '7-entities/user/api/types';

import { IRequestError } from '8-shared/model';

export enum WITHDRAW_METHOD {
  QUICK = 'quick',
  STANDARD = 'standard',
}
export interface IReplenishUSDTForm extends IReplenishmentUSDTWalletBody {
  address: string;
  network: string;
  sendManager: boolean;
  detail?: string;
}
export type IReplenishUSDTRejectBody = IRequestError<IReplenishUSDTForm>;

export interface IReplenishCurrencyForm extends IReplenishmentCurrencyWalletBody {
  detail?: string;
}
export type IReplenishCurrencyRejectBody = IRequestError<IReplenishCurrencyForm>;

export interface IWithdrawForm extends IWithdrawWalletBody {
  detail?: string;
}
export type IWithdrawRejectBody = IRequestError<IWithdrawForm>;

export enum REPLENISH_STEPS {
  METHOD = 'method',
  USDT = 'usdt',
  CURRENCY = 'currency',
}

export enum WITHDRAW_SCENARIO_STEPS {
  CHOSE_METHOD = 'chose_method',
  QUICK = 'quick',
  STANDARD = 'standard',
}

export enum STANDARD_WITHDRAW_STEPS {
  FORM = 'form',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
  ERROR = 'error',
}
