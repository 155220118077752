import { createAsyncThunk } from '@reduxjs/toolkit';

import { userAPI } from '7-entities/user';

export const watchNotificationThunk = createAsyncThunk<void, void, { state: RootState }>(
  'p2p/sale',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.watchNotifications.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
