import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme, { floating, textColor }: { floating: boolean; textColor: string }) => ({
  root: {
    position: 'relative',
    fontFamily: 'Montserrat,sans-serif',
  },

  label: {
    position: 'absolute',
    zIndex: 2,
    top: 35,
    left: 33,
    pointerEvents: 'none',
    color: theme.colors.dark[1],
    textTransform: floating ? 'lowercase' : 'none',
    transition: 'transform 150ms ease, color 150ms ease, font-size 150ms ease',
    transform: floating ? `translate(-${theme.spacing.sm}, ${rem(-28)})` : `translate(-${theme.spacing.sm}, ${rem(-15)})`,
    fontSize: floating ? rem(12) : rem(16),
    fontWeight: floating ? 400 : 500,
  },

  required: {
    transition: 'opacity 150ms ease',
    opacity: floating ? 1 : 0,
  },

  input: {
    color: textColor,
    background: 'transparent',
    textTransform: 'none',
    fontSize: rem(18),
    height: 65,
    padding: floating ? '30px 20px 10px 20px' : '20px 20px',
    '&::placeholder': {
      top: 10,
      transition: 'color 150ms ease',
      color: !floating ? 'transparent' : undefined,
    },
  },
}));
