import React from 'react';

import { REPLENISH_STEPS } from '../../../model/types';

import { Button, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { ReactComponent as USDTIcon } from '8-shared/assets/icons/usdt.svg';
import { ReactComponent as CardIcon } from '8-shared/assets/icons/card.svg';

interface IProps {
  opened: boolean;
  onClose: () => void;
  onSelect: (value: REPLENISH_STEPS.USDT | REPLENISH_STEPS.CURRENCY) => void;
}
export const ReplenishMethod = ({ opened, onClose, onSelect }: IProps) => {
  const { t } = useLocales('wallet');

  return (
    <Modal opened={opened} onClose={onClose} title={t('modals.replenishment.method.title')} size={'md'}>
      <div className={'flex flex-col gap-3 pt-7'}>
        <Button onClick={() => onSelect(REPLENISH_STEPS.USDT)} className={'text-blue border-blue'} variant={'outline'}>
          <div className={'w-max flex gap-2 items-center'}>
            <USDTIcon width={24} height={24} className={'stroke-blue items-center'} />
            {t('modals.replenishment.method.usdt')}
          </div>
        </Button>
        <Button onClick={() => onSelect(REPLENISH_STEPS.CURRENCY)} className={'text-blue border-blue'} variant={'outline'}>
          <div className={'w-max flex gap-2 items-center'}>
            <CardIcon width={24} height={24} className={'stroke-blue'} />
            {t('modals.replenishment.method.currency')}
          </div>
        </Button>
      </div>
    </Modal>
  );
};
