import React, { useState } from 'react';

import { CloseDigitalSafeScenario } from '6-features/digitalSafe';

import { IDigitalSafeOperation } from '7-entities/user';
import { DigitalSafesTable } from './table/table';

export const DigitalSafes = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<IDigitalSafeOperation | null>(null);

  const onCloseSafe = (data: IDigitalSafeOperation) => {
    setCurrentItem(data);
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    setCurrentItem(null);
  };

  return (
    <div>
      <CloseDigitalSafeScenario opened={open} onClose={onCloseModal} safe={currentItem} />
      <DigitalSafesTable onCloseSafe={onCloseSafe} />
    </div>
  );
};
