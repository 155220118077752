import { baseApi } from '8-shared/api';
import { USER } from '8-shared/api/tags';

import type {
  IChangeRegisterStageBody,
  IConfirmEmailRegisterBody,
  IConfirmPhoneRegisterBody,
  IGetVerifyResponse,
  ISendEmailRegisterBody,
  ISendPhoneRegisterBody,
  ISocialLoginBody,
  ISurveyRegisterBody,
  RequestForgotPasswordBody,
  RequestLoginBody,
} from './types';
import { IAuthResponse } from '8-shared/model';

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<IAuthResponse, RequestLoginBody>({
      query: (data) => ({
        url: 'users/jwt/cookie/create/',
        method: 'POST',
        body: data,
      }),
    }),
    socialLogin: build.mutation<IAuthResponse, ISocialLoginBody>({
      query: (data) => ({
        url: '/users/auth/oauth/',
        method: 'POST',
        body: data,
      }),
    }),
    forgotPassword: build.mutation<null, RequestForgotPasswordBody>({
      query: (data) => ({
        url: 'users/send_password/',
        method: 'POST',
        body: data,
      }),
    }),

    // api`s registration
    sendEmail: build.mutation<void, ISendEmailRegisterBody>({
      query: (data) => ({
        url: '/users/pre-register/',
        body: { email: data.email, resource: 'mobile' },
        method: 'POST',
      }),
    }),
    confirmEmail: build.mutation<IAuthResponse, IConfirmEmailRegisterBody>({
      query: (data) => ({
        url: '/users/jwt/cookie/create/',
        body: data,
        method: 'POST',
      }),
    }),
    sendPhone: build.mutation<void, ISendPhoneRegisterBody>({
      query: (data) => ({
        url: '/users/generate/identity-code/',
        body: data,
        method: 'POST',
      }),
    }),
    confirmPhone: build.mutation<void, IConfirmPhoneRegisterBody>({
      query: (data) => ({
        url: '/users/confirm/phone/',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [USER],
    }),
    getVerify: build.query<IGetVerifyResponse, void>({
      query: () => ({
        url: '/users/create-session-verify/',
      }),
    }),
    changeVerifyStatus: build.mutation<void, IChangeRegisterStageBody>({
      query: (data) => ({
        url: '/users/change-register-stage/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [USER],
    }),
    sendSurvey: build.mutation<void, ISurveyRegisterBody>({
      query: (data) => ({
        url: '/users/register/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [USER],
    }),
    sendLocalVerify: build.mutation<void, FormData>({
      query: (data) => ({
        url: '/users/final-register/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [USER],
    }),
  }),
});

export const { useLoginMutation, useForgotPasswordMutation, useSocialLoginMutation } = authApi;
