import { IP2pDealDto } from '../api/types';
import { type IP2pDeal, type IP2pDealId } from '../model/types';

export const mapCompany = (dto: IP2pDealDto): IP2pDeal => {
  return {
    color: dto.color,
    comment: dto.comment,
    id: dto.id as IP2pDealId,
    operation: dto.operation,
    price_per_security: dto.price_per_security,
    quantity: dto.quantity,
    status: dto.status,
    is_mine: dto.is_mine,
    is_fake: dto?.is_fake,
  };
};
