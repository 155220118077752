import { IRequestError } from '8-shared/model';

export enum SALE_METHOD {
  QUICK = 'quick',
  SECONDARY = 'secondary',
}
export interface IQuickSaleForm {
  quantity: number;
  detail?: string;
}
export type IQuickSaleRejectBody = IRequestError<IQuickSaleForm>;

export interface ISecondarySaleForm {
  quantity: number;
  price_per_security: number;
  detail?: string;
}
export type ISecondarySaleRejectBody = IRequestError<ISecondarySaleForm>;

export interface IQuickConfirmOrder {
  quantity: number;
  priceLot: number;
  totalPrice: number;
  commission: number;
  commissionPrice: number;
}
export interface ISecondaryConfirmOrder {
  quantity: number;
  priceLot: number;
  totalPrice: number;
}
