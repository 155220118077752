import React, { useEffect } from 'react';
import * as yup from 'yup';
import { FieldError, FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { confirmEmailRegisterThunk, ISecondStepForm, ISecondStepRejectBody, sendEmailRegisterThunk } from '../../model';
import { TryAgain } from '../tryAgain/tryAgain';

import { IUser } from '7-entities/user';
import { STEPS_ENUM, selectIsLoading } from '7-entities/auth';

import { Button, Input } from '8-shared/ui';
import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { formErrorHandler } from '8-shared/lib/form';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { PATH_PAGE } from '8-shared/lib/router';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const SecondStepRegisterForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isLoading } = useSelector(selectIsLoading);

  const { t, i18n } = useLocales('auth');
  const email = location?.state?.email;
  const prevLocation = location.state?.backgroundLocation;

  // if there is no email, then return to the first step
  useEffect(() => {
    if (!email)
      navigate(`${PATH_PAGE.register}?step=${STEPS_ENUM.first}`, {
        replace: true,
        state: { backgroundLocation: prevLocation },
      });
  }, [email]);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(i18n.t('validation.required'))
      .min(12, i18n.t('validation.min', { count: '12' })),
  });

  const { control, handleSubmit, setError, clearErrors } = useForm<ISecondStepForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: ISecondStepForm) => {
    dispatch(confirmEmailRegisterThunk({ email, password: data.password }))
      .unwrap()
      .then((data: IUser) => {
        myTracker(data?.deal_id, TRACKER_EVENT.register_stage_2);
        dataLayerEventHandler('secondStep');
      })
      .catch((error: ISecondStepRejectBody) => {
        const errors: FieldErrors<ISecondStepForm> = {
          password: {
            message: `${error.data?.password || ''} ${error.data?.detail || ''}`,
            type: 'value',
          } as FieldError,
        };
        formErrorHandler({ errors, setError });
      });
  };
  const onBack = () => {
    navigate(`${PATH_PAGE.register}?step=${STEPS_ENUM.first}`, {
      replace: true,
      state: { backgroundLocation: prevLocation },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors('detail')} className={'flex flex-col gap-4 w-full h-min'}>
      <div className={'text-1.3 mobile:text-1.6 text-center font-600'}>{t('register.step_title', { step: STEPS_ENUM.second })}</div>
      <div className={'w-full flex flex-col gap-5 h-full justify-between pb-5'}>
        <div className={'w-full flex flex-col gap-5'}>
          <div className={'flex flex-col gap-2'}>
            <div className={'text-center text-2 font-500 laptop:hidden'}>{t('register.second_step.title')}</div>
            <span className={'text-1.6 font-400 text-center'}>
              {t('register.second_step.help_message')} {email}
            </span>
          </div>
          <Input control={control} label={i18n.t('name_form_fields.code_from_email')} name={'password'} />
          <TryAgain
            callBack={() => {
              dispatch(sendEmailRegisterThunk({ email }));
            }}
          />
          <Input className={'mb-[20px]'} name={'detail'} hidden control={control} />
        </div>
        <div className={'flex flex-col gap-2 mobile:flex-row mobile:gap-5'}>
          <Button onClick={onBack} className={'border-blue w-full text-blue'} type={'button'} loading={isLoading} variant={'outline'}>
            {t('register.back')}
          </Button>
          <Button className={'bg-blue w-full'} loading={isLoading} type={'submit'}>
            {t('register.next')}
          </Button>
        </div>
      </div>
    </form>
  );
};
