import React from 'react';
import { useSelector } from 'react-redux';

import { IDigitalSafeOperation } from '7-entities/user';
import { selectDigitalSafeInfo } from '7-entities/digitalSafe';
import { useLocales } from '8-shared/lib/i18n';
import { Button } from '8-shared/ui';
import { convertNumber, decimalPlaces, getDate } from '8-shared/lib/utils';

interface IProps {
  item: IDigitalSafeOperation;
  onClose: (item: IDigitalSafeOperation) => void;
}

export const Row = ({ item, onClose }: IProps) => {
  const { t } = useLocales('digitalSafe');
  const digitalSafe = useSelector(selectDigitalSafeInfo);
  const income = convertNumber(Number(item?.total) - Number(item?.amount));

  return (
    <div
      className={
        'flex flex-col rounded-[15px] mb-[15px] items-center px-[10px] py-[15px] desktop:px-[20px] border border-white_opacity_13 bg-white_opacity_5 w-full'
      }
    >
      <div className={'flex justify-between items-start mobile:items-center w-full'}>
        <div className={'flex items-center mobile:w-[50%] desktop:w-[30%]'}>
          <img
            src={digitalSafe?.icon}
            className={'w-[25px] h-auto mr-[10px] mobile:w-[50px] mobile:mr-[20px] desktop:w-[70px]'}
            alt={`logo`}
          />
          <div className={'flex flex-col gap-2'}>
            <div className={'font-600  text-1.6 mobile:text-2 leading-[20px] mobile:leading-[28px] text-white'}>USDT</div>
            <div
              className={
                'flex desktop:hidden whitespace-nowrap flex-row text-1 mobile:text-1.2 font-400 text-white opacity-60 leading-[12px] mobile:leading-[16px]'
              }
            >
              <div>{getDate(item?.transaction_date)}</div>
              <div className={'mx-[10px]'}>|</div>
              <div>{`${item?.amount} $`}</div>
            </div>
          </div>
        </div>
        <Button
          bg={'#ffffff08'}
          onClick={() => onClose(item)}
          className={'hidden mobile:flex bg-white_opacity_3 border border-white_opacity_13'}
        >
          {t('table.row.sell_btn')}
        </Button>
        <div className={'grid desktop:grid-cols-3 mobile:w-[30%] mobile:justify-end desktop:w-[50%]'}>
          <div className={'hidden desktop:flex items-center justify-center text-2 leading-[28px]'}>
            <div>{`${item.amount}$`}</div>
          </div>
          <div className={'hidden desktop:flex items-center justify-center text-2 leading-[28px]'}>{getDate(item?.transaction_date)}</div>
          <div className={'flex flex-col text-white justify-center items-center'}>
            <div
              className={
                'font-600 text-green text-1.6 whitespace-nowrap leading-[16px] mobile:text-1.8 mobile:leading-[29px] desktop:text-2 desktop:leading-[36px] mb-[5px]'
              }
            >
              {`${income > 0 ? '+' : ''}${decimalPlaces(income)}`}
            </div>
          </div>
        </div>
      </div>
      <Button onClick={() => onClose(item)} className={'mobile:hidden bg-blue w-full mt-[20px] px-[14px] text-1.5'}>
        {t('table.row.sell_btn')}
      </Button>
    </div>
  );
};
