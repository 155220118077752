import React from 'react';

import { useLocales } from '8-shared/lib/i18n';
import { Button, Modal } from '8-shared/ui';

export interface IProps {
  opened: boolean;
  onClose: () => void;
  onBack: () => void;
}
export const Faq = ({ onBack, onClose, opened }: IProps) => {
  const { t } = useLocales('sale');
  return (
    <Modal size={'lg'} opened={opened} onClose={onClose} title={t('faq')}>
      <div className={'mt-10 text-1.6 flex flex-col gap-4'}>
        <div>{t('quick_sale.faq')}</div>
        <div>{t('secondary_sale.faq')}</div>
        <Button className={'border-blue text-blue w-full hover:text-white'} onClick={onBack}>
          {t('back')}
        </Button>
      </div>
    </Modal>
  );
};
