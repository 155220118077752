import React from 'react';

import { type IIPOInfo } from '7-entities/ipo';
import { useLocales } from '8-shared/lib/i18n';
import { RemainIndicator } from '8-shared/ui';

import { Property } from './property/property';

interface IProps {
  company: IIPOInfo;
}

export const Information = ({ company }: IProps) => {
  const { t, i18n } = useLocales('main');
  return (
    <div className={'grid-in-content flex flex-col items-start mb-[35px] desktop:mb-0'}>
      <div className={'flex flex-col'}>
        <img
          alt={'main_page_photo_3'}
          src={company?.logo}
          className={'self-start object-contain mb-[26px] h-[50px] desktop:h-[62px] desktop:mb-[36px]'}
        />
        <div className={'font-600 leading-[39px] text-3.2'}>{company?.title}</div>
        <div className={'font-400 leading-[25px] text-1.4 mobile:text-1.6 opacity-80 mb-[22px] desktop:mb-[41px]'}>{company?.promo}</div>
      </div>
      <div className={'flex flex-col pb-[25px] desktop:pb-[32px] w-full'}>
        <div className={'flex justify-between pb-[25px] desktop:pb-[15px]'}>
          <div className={'font-600 leading-[18px] text-1.4 desktop:text-2 desktop:leading-[20px]'}>
            {`${t('month_deal.information.remain')} ${i18n.t('plural.lot', {
              count: company?.remain,
            })}`}
          </div>
          <div className={'font-400 leading-[25px] text-1.4 desktop:text-1.6 opacity-30'}>
            {`${company?.max_securities - company?.remain}/${company?.max_securities}`}
          </div>
        </div>
        <RemainIndicator max={company?.max_securities} current={company?.remain} />
      </div>
      <div className={'flex flex-col mobile:flex-row gap-[10px] mobile:gap-[90px] desktop:gap-[70px]'}>
        <Property
          title={t('month_deal.information.capitalization')}
          value={`$ ${company?.capitalization} ${company?.capitalization_measure}`}
        />
        <Property title={t('month_deal.information.profit')} value={`${company?.profit}`} />
      </div>
    </div>
  );
};
