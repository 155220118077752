import React, { useState } from 'react';

import { IDigitalSafeOperation, useCloseDigitalSafeMutation } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { StatusScreen } from '8-shared/ui';

import { closeDigitalSafeThunk, IDigitalSafeThunksReject } from '../../model/digitalSafe.thunk';
import { CLOSE_DIGITAL_SAFE_STEPS } from '../../model/types';
import { ConfirmCloseDigitalSafeModal } from './confirmCloseDigitalSafeModal/confirmCloseDigitalSafeModal';

interface IProps {
  opened: boolean;
  safe: IDigitalSafeOperation | null;
  onClose: () => void;
}

export const CloseDigitalSafeScenario = ({ opened, safe, onClose }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocales('digitalSafe');
  const dispatch = useAppDispatch();

  const [step, setStep] = useState<CLOSE_DIGITAL_SAFE_STEPS>(CLOSE_DIGITAL_SAFE_STEPS.CONFIRM);
  const [, { reset, error }] = useCloseDigitalSafeMutation({ fixedCacheKey: 'closeDigitalSafe' });

  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  const closeDigitalSafe = () => {
    if (!safe) {
      return;
    }
    setIsLoading(true);
    dispatch(closeDigitalSafeThunk({ operation_id: safe?.id }))
      .unwrap()
      .then(() => {
        setStep(CLOSE_DIGITAL_SAFE_STEPS.SUCCESS);
      })
      .catch(() => {
        setStep(CLOSE_DIGITAL_SAFE_STEPS.ERROR);
      })
      .finally(() => changeLoading());
  };
  const onCloseHandler = () => {
    onClose();
    reset();
    setStep(CLOSE_DIGITAL_SAFE_STEPS.CONFIRM);
  };

  if (!opened || !safe) {
    return null;
  }

  return (
    <div>
      <ConfirmCloseDigitalSafeModal
        opened={step === CLOSE_DIGITAL_SAFE_STEPS.CONFIRM}
        onClose={onCloseHandler}
        onSubmit={closeDigitalSafe}
        safe={safe}
        isLoading={isLoading}
      />
      <StatusScreen
        opened={step === CLOSE_DIGITAL_SAFE_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={`${t('close_modals.statuses.success')}`}
        description={`${t('close_modals.statuses.success_desc')}`}
        status={'success'}
      />
      <StatusScreen
        opened={step === CLOSE_DIGITAL_SAFE_STEPS.ERROR}
        onClose={onCloseHandler}
        title={`${t('close_modals.statuses.error')}`}
        description={(error as IDigitalSafeThunksReject)?.data?.detail}
        status={'error'}
      />
    </div>
  );
};
