import React from 'react';

import { MoreButton } from '6-features/protectedButtons';
import { useLocales } from '8-shared/lib/i18n';
import { Button } from '8-shared/ui';

interface IProps {
  onClickBuyHandler: () => void;
  onClickMoreHandler: () => void;
}

export const Buttons = ({ onClickBuyHandler, onClickMoreHandler }: IProps) => {
  const { t } = useLocales('main');
  return (
    <div className={'grid-in-buttons h-max flex flex-col mobile:flex-row gap-3.5'}>
      <Button onClick={onClickBuyHandler} className={'bg-blue'}>
        {t('month_deal.buttons.buy')}
      </Button>
      <MoreButton onClick={onClickMoreHandler} variant={'outline'}>
        {t('month_deal.buttons.more')}
      </MoreButton>
    </div>
  );
};
