import React from 'react';
import { ReactComponent as SortIcon } from '8-shared/assets/icons/sort.svg';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  onSortColumn: (data: 'total_amount_of_money' | 'number_of_invited') => void;
}

export const Columns = ({ onSortColumn }: IProps) => {
  const { t } = useLocales('agent');
  return (
    <div
      className={
        'w-full flex z-[41] flex-row justify-end text-[10px] text-white_opacity_50 laptop:text-[14px] laptop:justify-between laptop:pr-[50px] mb-[15px]'
      }
    >
      <span className={'w-[300px] text-left hidden uppercase laptop:block'}>{t('referals_table.columns.full_name')}</span>
      <span className={'w-[200px] text-center uppercase hidden laptop:block'}>{t('referals_table.columns.phone')}</span>
      <span className={'w-[250px] text-center uppercase hidden laptop:block'}>{t('referals_table.columns.email')}</span>
      <div
        onClick={() => onSortColumn('total_amount_of_money')}
        className={'flex cursor-pointer items-center justify-end uppercase w-[125px] laptop:w-[200px]'}
      >
        <span className={'mr-[4px]'}>{t('referals_table.columns.amount')}</span>
        <SortIcon className={'w-[6px] laptop:w-[10px]'} />
      </div>
      <div
        className={'flex cursor-pointer items-center justify-end uppercase w-[90px] laptop:w-[150px]'}
        onClick={() => onSortColumn('number_of_invited')}
      >
        <span className={'mr-[4px]'}>{t('referals_table.columns.referals_count')}</span>
        <SortIcon className={'w-[6px] laptop:w-[10px]'} />
      </div>
    </div>
  );
};
