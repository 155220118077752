import React from 'react';
import * as yup from 'yup';
import { FieldError, FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IThirdStepForm, IThirdStepRejectBody, sendPhoneRegisterThunk } from '../../model';

import { selectUser } from '7-entities/user';
import { STEPS_ENUM, selectIsLoading } from '7-entities/auth';
import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { formErrorHandler } from '8-shared/lib/form';
import { Button, PhoneInput } from '8-shared/ui';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { PATH_PAGE } from '8-shared/lib/router';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const ThirdStepRegisterForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const { isLoading } = useSelector(selectIsLoading);
  const { t, i18n } = useLocales('auth');

  const schema = yup.object().shape({
    phone: yup.string().required(i18n.t('validation.required')),
  });

  const { control, handleSubmit, setError, clearErrors } = useForm<IThirdStepForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IThirdStepForm) => {
    dispatch(sendPhoneRegisterThunk({ phone: data.phone }))
      .unwrap()
      .then(() => {
        myTracker(user?.deal_id, TRACKER_EVENT.register_stage_3);
        dataLayerEventHandler('thirdStep');
        navigate(`${PATH_PAGE.register}?step=${STEPS_ENUM.fourth}`, {
          replace: true,
          state: {
            backgroundLocation: location.state?.backgroundLocation,
            phone: data.phone,
            country: data.country,
          },
        });
      })
      .catch((error: IThirdStepRejectBody) => {
        const errors: FieldErrors<IThirdStepForm> = {
          phone: {
            message: `${error.data?.phone} ${error.data?.detail || ''}`,
            type: 'value',
          } as FieldError,
        };
        formErrorHandler({ errors, setError });
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors('detail')} className={'flex flex-col gap-4 w-full h-min'}>
      <div className={'text-1.3 mobile:text-1.6 text-center font-600'}>{t('register.step_title', { step: STEPS_ENUM.third })}</div>
      <div className={'pr-2 pl-2 w-full flex flex-col gap-5 h-full justify-between pb-5'}>
        <PhoneInput
          label={i18n.t('name_form_fields.phone')}
          nameNumberField={'phone'}
          nameCountryField={'country'}
          control={control}
          setError={setError}
          country={'ae'}
        />
        <Button className={'bg-blue w-full'} loading={isLoading}>
          {t('register.third_step.button')}
        </Button>
      </div>
    </form>
  );
};
