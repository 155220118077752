import React, { useState } from 'react';

import { useCreateAgentRequestMutation } from '7-entities/user';
import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';

import { AgentRequestModal } from './agentRequestModal/agentRequestModal';
import { AGENT_INVITE_STEPS } from '../../model/types';
import { becomeAgentThunk, ICreateAgentReject } from '../../model/becomeAgent.thunk';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const AgentRequestScenario = ({ opened, onClose }: IProps) => {
  const { t } = useLocales('settings');
  const dispatch = useAppDispatch();

  const [step, setStep] = useState<AGENT_INVITE_STEPS>(AGENT_INVITE_STEPS.CONFIRM);

  const [, { isLoading, reset, error }] = useCreateAgentRequestMutation({ fixedCacheKey: 'createAgentRequest' });

  const createAgentRequest = () => {
    dispatch(becomeAgentThunk())
      .unwrap()
      .then(() => setStep(AGENT_INVITE_STEPS.SUCCESS))
      .catch(() => setStep(AGENT_INVITE_STEPS.ERROR));
  };

  const onCloseHandler = () => {
    onClose();
    reset();
    setStep(AGENT_INVITE_STEPS.CONFIRM);
  };

  if (!opened) {
    return null;
  }

  return (
    <>
      <AgentRequestModal
        onClose={onCloseHandler}
        opened={step === AGENT_INVITE_STEPS.CONFIRM}
        isLoading={isLoading}
        createAgentRequest={createAgentRequest}
      />
      <StatusScreen
        opened={step === AGENT_INVITE_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={`${t('agent_request.agent_request_statuses.success')}`}
        description={`${t('agent_request.agent_request_statuses.success_desc')}`}
        status={'success'}
      />
      <StatusScreen
        opened={step === AGENT_INVITE_STEPS.ERROR}
        onClose={onCloseHandler}
        title={`${t('agent_request.agent_request_statuses.error')}`}
        description={(error as ICreateAgentReject)?.data?.detail}
        status={'error'}
      />
    </>
  );
};
