import React, { ForwardedRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { Wallet } from '6-features/wallet';
import { selectProfileInfo } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const WalletInfo = forwardRef(({ opened, onClose }: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useLocales('wallet');
  const info = useSelector(selectProfileInfo);
  return (
    <div
      ref={ref}
      className={`z-50 absolute w-full hidden desktop:block transition-all top-[85px] max-h-[130px] mobile:px-[30px] large:px-[130px] overflow-hidden ${
        !opened && '!max-h-0'
      }`}
    >
      <div className={'bg-balance_desktop_bg p-5 flex rounded-b-15'}>
        <Wallet onClick={onClose} />
        <div className={'w-full flex justify-between pl-10 items-center '}>
          <div className={'text-1.4 text-gray flex flex-col items-center'}>
            <div>{t('portfolio')}</div>
            <div className={'text-1.6'}>
              <span className={'text-white font-600'}>{info?.invest_amount || 0}</span> $
            </div>
          </div>
          <div className={'text-1.4 text-gray flex flex-col items-center'}>
            <div>{t('earned')}</div>
            <div className={'text-1.6'}>
              <span className={'text-white font-600'}>{info?.invest_profit || 0}</span> $
            </div>
          </div>
          <div className={'text-1.4 text-gray flex flex-col items-center'}>
            <div>{t('digital_safe')}</div>
            <div className={'text-1.6'}>
              <span className={'text-white font-600'}>{info?.digital_safe || 0}</span> $
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
WalletInfo.displayName = 'walletInfo';
export { WalletInfo };
