import { createAsyncThunk } from '@reduxjs/toolkit';

import { authApi } from '7-entities/auth';
import { IForgotPasswordForm } from './types';

export const forgotPasswordForm = createAsyncThunk<void, IForgotPasswordForm, { state: RootState }>(
  'auth/login',
  async (body: IForgotPasswordForm, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.forgotPassword.initiate(body, { fixedCacheKey: 'forgotPassword' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
