import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { BalanceLaptop } from './balanceLaptop';

import { Wallet } from '6-features/wallet';

import { selectWalletBalance } from '7-entities/user';
import { selectIsAuth } from '7-entities/auth';

import { Popover, PopoverContent, PopoverTarget } from '8-shared/ui';
import { ReactComponent as ArrowIcon } from '8-shared/assets/icons/arrow_down_2.svg';

export const Balance = () => {
  const [opened, setOpened] = useState(false);
  const isAuth = useSelector(selectIsAuth);
  const wallet = useSelector(selectWalletBalance);

  if (!isAuth) {
    return null;
  }

  return (
    <>
      <Popover opened={opened} onChange={setOpened}>
        <PopoverTarget>
          <div className={`h-full cursor-pointer px-2.5`} onClick={() => setOpened((prevState) => !prevState)}>
            <div className={'flex gap-2.5 font-600 items-center text-1.1 mobile:text-1.6 h-full text-right'}>
              <div className={'flex flex-col'}>
                <span className={'tex-2.4'}>
                  {wallet} <span className={'text-gray'}>USDT</span>
                </span>
              </div>
              <ArrowIcon className={`fill-gray transition-all stroke-gray ${opened && 'rotate-180'}`} />
            </div>
          </div>
        </PopoverTarget>
        <PopoverContent>
          <>
            <div className={'hidden desktop:block'}>
              <Wallet onClick={() => setOpened(false)} />
            </div>
            <BalanceLaptop onClose={() => setOpened(false)} opened={opened} />
          </>
        </PopoverContent>
      </Popover>
    </>
  );
};
