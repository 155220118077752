import React, { useRef } from 'react';
import { FileButton } from '@mantine/core';

import { Button } from '8-shared/ui/index';

interface IProps {
  loading?: boolean;
  onChange: (data: File) => void;
  label: string;
}

export const UploadButton = ({ loading, label, onChange }: IProps) => {
  const resetValueRef = useRef<() => void>(null);

  const onUpload = (payload: File | null) => {
    if (payload) {
      onChange(payload);
      resetValueRef.current?.();
    }
  };

  return (
    <FileButton resetRef={resetValueRef} accept="image/png,image/jpeg" onChange={onUpload}>
      {(props) => (
        <Button type={'button'} variant={'outline'} loading={loading} {...props}>
          {label}
        </Button>
      )}
    </FileButton>
  );
};
