import { type FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { type FetchArgs, type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { type BaseQueryApi, type QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';

import { baseQuery } from './baseQuery';
import { invalidateRefreshTokenListener } from './invalidateTokenEvent';
import { JWTToken } from '../lib/webStorage/JWTToken';
import { IAuthResponse } from '../model';

export async function baseQueryWithReauth(
  args: string | FetchArgs,
  api: BaseQueryApi,
  // eslint-disable-next-line @typescript-eslint/ban-types
  extraOptions: {}
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> {
  const firstResult = await baseQuery(args, api, extraOptions);
  const refresh = JWTToken.getRefresh();
  if (firstResult?.error?.status === 401 && refresh) {
    //Make a request to refresh a token
    const refreshResult = await baseQuery(
      {
        url: '/users/jwt/cookie/refresh/',
        method: 'POST',
        body: { refresh },
      },
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      // if the refresh is successful,install new tokens and make the request again
      const data = refreshResult?.data as IAuthResponse;
      JWTToken.setTokens(data.access, data.refresh);
      return baseQuery(args, api, extraOptions);
    }
  }
  if (firstResult?.error?.status === 401) {
    //if failed or not refresh token then launch the logout event
    api.dispatch(invalidateRefreshTokenListener());
  }
  return firstResult;
}
