import React from 'react';
import * as yup from 'yup';
import { FieldError, FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSelector } from 'react-redux';

import {
  confirmPhoneRegisterThunk,
  IFourthStepForm,
  IFourthStepFormRejectBody,
  sendPhoneRegisterThunk,
} from '6-features/auth/register/model';
import { TryAgain } from '6-features/auth/register/ui/tryAgain/tryAgain';
import { selectIsLoading } from '7-entities/auth';
import { selectUser } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { formErrorHandler } from '8-shared/lib/form';
import { Button, Input, PinInput } from '8-shared/ui';
import { useAppDispatch } from '8-shared/hooks';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  phone?: string;
  country?: string;
  onBack: () => void;
}

export const ConfirmCodeForm = ({ phone, country, onBack }: IProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { isLoading } = useSelector(selectIsLoading);
  const { t, i18n } = useLocales('auth');

  const schema = yup.object().shape({
    code: yup
      .string()
      .required(i18n.t('validation.required'))
      .min(5, i18n.t('validation.min', { count: '6' })),
  });

  const { control, handleSubmit, setError, clearErrors } = useForm<IFourthStepForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFourthStepForm) => {
    dispatch(confirmPhoneRegisterThunk({ ...data, country: country as string }))
      .unwrap()
      .then(() => {
        myTracker(user?.deal_id, TRACKER_EVENT.register_stage_4);
        dataLayerEventHandler('fourthStep');
      })
      .catch((error: IFourthStepFormRejectBody) => {
        const errors: FieldErrors<IFourthStepForm> = {
          code: {
            message: `${error.data?.code ?? ''} ${error.data?.detail ?? ''}`,
            type: 'value',
          } as FieldError,
        };
        formErrorHandler({ errors, setError });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => clearErrors('detail')} className={'flex flex-col gap-4 w-full h-min'}>
      <div className={'text-center text-3 font-600'}>{t('verify.input_code')}</div>
      <div className={'w-full flex flex-col gap-5 h-full justify-between pb-5'}>
        <div className={'w-full flex flex-col gap-5'}>
          <div className={'flex flex-col gap-2'}>
            <span className={'text-1.6 font-400 text-center'}>
              {t('register.fourth_step.help_message')} {phone}
            </span>
          </div>
          <PinInput control={control} name={'code'} length={6} className={'m-auto w-min'} />
          <TryAgain
            callBack={() => {
              dispatch(sendPhoneRegisterThunk({ phone: phone as string }));
            }}
          />
          <Input className={'mb-[20px]'} name={'detail'} hidden control={control} />
        </div>
        <div className={'flex flex-col px-10 gap-2 mobile:flex-row mobile:gap-5'}>
          <Button type={'button'} onClick={onBack} className={'border-blue w-full text-blue'} loading={isLoading} variant={'outline'}>
            {t('register.back')}
          </Button>
          <Button type={'submit'} className={'bg-blue w-full'} loading={isLoading}>
            {t('register.next')}
          </Button>
        </div>
      </div>
    </form>
  );
};
