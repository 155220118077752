import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Order } from './order/order';

import { selectCurrentLanguage } from '7-entities/app';
import { useGetDocumentsQuery } from '7-entities/user';
import { IDocumentType } from '7-entities/user/api/types';

import { EmptyData, ISwitchTab, Loader, Pagination, Switch } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useWindowSize } from '8-shared/hooks';

const PAGE_SIZE = 10;

export const Orders = () => {
  const lang = useSelector(selectCurrentLanguage);
  const [currentPage, setCurrentPage] = useState(1);
  const { width } = useWindowSize();
  const { t } = useLocales('documents');
  const tabs = [
    { value: undefined, title: t('switcher.all') },
    { value: 'bought', title: t('switcher.bought') },
    { value: 'sold', title: t('switcher.sold') },
  ];
  const [filter, setFilter] = useState<ISwitchTab>(tabs[0]);
  const { data, isFetching } = useGetDocumentsQuery({
    type: filter.value as IDocumentType,
    page: currentPage,
    page_size: PAGE_SIZE,
  });
  const totalCount = Math.ceil((data?.count || 0) / PAGE_SIZE);
  const onChangeHandler = (tab: ISwitchTab) => {
    setFilter(tab);
    setCurrentPage(1);
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div>
      <div className={'flex flex-col gap-6 px-3.5 py-6 mobile:p-8 bg-black_opacity_30 rounded-2xl'}>
        <div className={'break-words text-2.4'}>{t('orders')}</div>
        <Switch
          tabs={tabs}
          currentTab={filter}
          onChange={onChangeHandler}
          className={'!whitespace-normal text-center laptop:max-w-[600px] laptop:!w-full'}
        />
        <div className={'flex flex-col gap-3.5'}>
          {!isFetching && !data?.results?.length ? (
            <EmptyData title={t('no_documents')} />
          ) : (
            <>
              {data?.results.map((el) => (
                <Order key={el.document_file} title={el?.translations?.[lang]?.document_name || ''} link={el.document_file} />
              ))}
            </>
          )}
        </div>
      </div>
      <Pagination
        className={'mt-10 w-max mx-auto'}
        total={totalCount}
        value={currentPage}
        onChange={setCurrentPage}
        size={width > 640 ? 'lg' : 'sm'}
      />
    </div>
  );
};
