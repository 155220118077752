import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLanguage } from '7-entities/app';
import { IFloatingIPO } from '7-entities/ipo';
import { ReactComponent as LineChartUpIcon } from '8-shared/assets/icons/line_chart_up.svg';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  item: IFloatingIPO;
}

export const FloatingCompany = ({ item }: IProps) => {
  const { t } = useLocales();
  const lang = useSelector(selectCurrentLanguage);
  return (
    <div
      className={
        'flex flex-col justify-between gap-[10px] min-w-[250px] h-full py-[27px] px-[27px] rounded-20 bg-black_opacity_5 border scale-90 border-white_opacity_16 duration-700 hover:scale-100'
      }
    >
      <div className={'flex items-center text-1.8 gap-[10px]'}>
        <img alt={'main_page_logo_company'} className={'h-[40px] max-w-[50%] object-contain rounded-15'} src={item?.logo} />
        <div className={'break-all'}>{item?.translations?.[lang]?.title}</div>
      </div>
      <div className={'flex items-center'}>
        <LineChartUpIcon className={'w-[24px] h-[24px] mr-[10px]'} />
        <span className={'text-1.8 text-green'}>{`${item?.income_description}% ${t('plural.month', { count: 1 })}`}</span>
      </div>
    </div>
  );
};
