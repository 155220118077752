import { IBuyConfirmInfo } from '../../buy';

import { IIPOInfo } from '7-entities/ipo';

interface IProps {
  item?: IIPOInfo;
  count?: number;
}
export const getInfoOrder = ({ item, count }: IProps): IBuyConfirmInfo => {
  const commission = item?.commission_percent || 0;
  const quantity = count || 0;
  const priceLot = item?.price || 0;
  const priceWithoutCommission = quantity * priceLot;
  const commissionPrice = (priceWithoutCommission * commission) / 100;
  const totalPrice = priceWithoutCommission + commissionPrice;

  return {
    totalPrice,
    commission,
    quantity,
    priceWithoutCommission,
    commissionPrice,
    priceLot,
  };
};
