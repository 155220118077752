const PUBLIC_PAGES = {
  login: '/sign-in/',
  register: '/sign-up/',
  ref_register: '/ref/sign-up/:id',
  forgot_password: '/forgot-password/',
  feedback: '/feedback/',
  about: '/about/',
  contacts: '/contacts/',
  partnership: '/partnership/',
  root: {
    venture: '/',
    realEstate: '/real-estate',
    alternative: '/alternative',
  },
};

const PRIVATE_PAGES = {
  profile: {
    root: '/profile',
    portfolio: '/profile/portfolio',
    history: '/profile/history',
    settings: '/profile/settings',
    digital_safe: '/profile/digital-safe',
    agent: '/profile/agent',
    wallet: '/profile/wallet',
    documents: '/profile/documents',
    notifications: '/profile/notifications',
  },
};

const COMMON_PAGES = {
  page404: '/404',
};

export const PATH_PAGE = {
  ...PUBLIC_PAGES,
  ...PRIVATE_PAGES,
  ...COMMON_PAGES,
};

export const mainSitePaths = {
  about: {
    ru: process.env.REACT_APP_MAIN_URL_RU,
    en: process.env.REACT_APP_MAIN_URL,
  },
  contacts: {
    ru: process.env.REACT_APP_MAIN_URL_RU + 'contacts',
    en: process.env.REACT_APP_MAIN_URL + 'contacts',
  },
  feedback: {
    ru: process.env.REACT_APP_MAIN_URL_RU + 'contacts#rec589043909',
    en: process.env.REACT_APP_MAIN_URL + 'contacts#rec589043909',
  },
  partnership: {
    ru: process.env.REACT_APP_MAIN_URL_RU + 'partnership',
    en: process.env.REACT_APP_MAIN_URL + 'partnership',
  },
};
