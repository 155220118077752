import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '7-entities/user';
import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { TransferBonusScenario } from '../transferBonusScenario/transferBonusScenario';

export const TransferBonus = () => {
  const { t } = useLocales('settings');
  const user = useSelector(selectUser);

  const [open, setOpen] = useState<boolean>(false);

  const onClickTransferHandler = () => {
    setOpen(true);
  };
  const onClickCloseTransferHandler = () => {
    setOpen(false);
  };

  if (!(!!user?.invite_ref || !!user?.is_ref_participant)) {
    return null;
  }

  return (
    <div>
      <TransferBonusScenario opened={open} onClose={onClickCloseTransferHandler} />
      <Button onClick={onClickTransferHandler} type={'button'} className={'bg-blue w-full text-[16px] px-[0px]'}>
        {t('transfer_bonus.transfer_bonus_request_btn')}
      </Button>
    </div>
  );
};
