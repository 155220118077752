import React from 'react';
import { Loader as MantineLoader } from '@mantine/core';

export const Loader = () => {
  return (
    <div className={'flex items-center justify-center'}>
      <MantineLoader size="lg" />
    </div>
  );
};
