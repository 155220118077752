import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CompanyLogo } from '8-shared/assets/icons/company_logo_large.svg';
import { ReactComponent as CrossIcon } from '8-shared/assets/icons/cross.svg';
import { ActionIcon, Drawer, Group } from '8-shared/ui';
import { LangVariants, useLocales } from '8-shared/lib/i18n';

import { useStyles } from './styles';
import { burgerLinks } from '../../model/burgerLinks';
import { LangSwitcher } from '../../../langSwitch';
import { LogoutButton } from '../../../auth/logout';
import { useSelector } from 'react-redux';
import { selectIsAuth } from '7-entities/auth';
import { PATH_PAGE } from '8-shared/lib/router';

interface IProps {
  opened: boolean;
  closeDrawer: () => void;
}

export const BurgerMenu = ({ opened, closeDrawer }: IProps) => {
  const {
    t,
    i18n: { language },
  } = useLocales('navigation');
  const isAuth = useSelector(selectIsAuth);
  const { classes } = useStyles();

  return (
    <Drawer zIndex={100000} classNames={classes} withCloseButton={false} position={'right'} opened={opened} onClose={closeDrawer}>
      <ActionIcon
        onClick={closeDrawer}
        className={'absolute w-[40px] h-[40px] right-[30px] top-[30px] fill-white cursor-pointer hover:bg-transparent'}
      >
        <CrossIcon />
      </ActionIcon>

      <Group mt={45} className={'flex flex-col gap-16'}>
        <CompanyLogo />
        <Group>
          <div className={'flex flex-col justify-center items-center'}>
            {burgerLinks(language as LangVariants).map((i) => (
              <Link key={i.label} onClick={closeDrawer} className={'text-2 text-green leading-[24px] mb-[18px] cursor-pointer'} to={i.path}>
                {t(`${i.label}`)}
              </Link>
            ))}
            <Link
              onClick={closeDrawer}
              className={`${!isAuth && 'hidden'} text-2 text-green leading-[24px] mb-[18px] cursor-pointer`}
              to={PATH_PAGE.profile.notifications}
            >
              {t('burger.notifications')}
            </Link>
            <Link
              onClick={closeDrawer}
              className={`${isAuth && 'hidden'} text-2 text-green leading-[24px] mb-[18px] cursor-pointer`}
              to={PATH_PAGE.login}
            >
              {t('burger.sign_in')}
            </Link>
            <LogoutButton onClick={closeDrawer} />
          </div>
        </Group>
        <LangSwitcher />
      </Group>
    </Drawer>
  );
};
