import { Checkbox as MantineCheckbox, type CheckboxProps as MantineCheckboxProps } from '@mantine/core';
import React from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { useStyles } from './styles';

interface IProps<T extends FieldValues> extends Omit<MantineCheckboxProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const Checkbox = <T extends FieldValues>({ name, control, defaultValue, ...rest }: IProps<T>) => {
  const {
    field: { value: value = '', onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });
  const { classes } = useStyles();
  return (
    <MantineCheckbox
      {...rest}
      {...field}
      classNames={classes}
      value={value}
      onChange={(e) => {
        fieldOnChange(e);
      }}
      error={fieldState.error?.message}
      autoComplete="nope"
    />
  );
};
