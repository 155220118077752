import React, { useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from '@mantine/hooks';
import { useSelector } from 'react-redux';

import { ReactComponent as HelperIcon } from '../model/icons/helper.svg';
import { helperArrayLinks } from '../model/helperArrayLinks';

import { selectUser } from '7-entities/user';

import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export const Helper = () => {
  const user = useSelector(selectUser);
  const [open, setOpen] = useState<boolean>(false);
  const ref = useClickOutside(() => setOpen(false));
  const onOpen = () => {
    setOpen((prevState) => !prevState);
    myTracker(user?.deal_id, TRACKER_EVENT.write_us);
  };
  const onClickSocial = () => {
    myTracker(user?.deal_id, TRACKER_EVENT.go_to_chat);
  };
  return (
    <div ref={ref} className={'fixed bottom-[80px] z-[40] right-[10px]'}>
      <div
        onClick={onOpen}
        className={' relative flex justify-center items-center w-[70px] h-[70px] rounded-full border border-white_opacity_20'}
      >
        <HelperIcon
          className={cn({
            ['fill-green']: open,
            ['fill-white']: !open,
          })}
        />
      </div>
      {open && (
        <div className={'absolute bottom-[80px] right-[15px] z-[10] flex flex-col'}>
          {helperArrayLinks.map(({ icon: Icon, link }, index) => (
            <a key={index} href={link} onClick={onClickSocial}>
              <Icon className={'mb-[10px]'} />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
