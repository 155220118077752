import React from 'react';
import { useSelector } from 'react-redux';

import { BuyButton, MoreButton } from '6-features/protectedButtons';
import { selectCurrentLanguage } from '7-entities/app';
import { type IIPO, openBuyModal } from '7-entities/ipo';
import { selectUser } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { openUrlInNewTab } from '8-shared/lib/utils';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  company: IIPO;
}
export const Buttons = ({ company }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useLocales('main');
  const user = useSelector(selectUser);

  const lang = useSelector(selectCurrentLanguage);

  const onClickBuy = () => {
    dispatch(openBuyModal(company));
  };
  const onClickMore = () => {
    myTracker(user?.deal_id, TRACKER_EVENT.lot_more);
    openUrlInNewTab(company?.translations?.[lang]?.detail_file ?? company?.translations?.[lang]?.detail_link);
  };

  return (
    <div className={'grid grid-cols-1 mobile:grid-cols-2 gap-[10px] mobile:gap-[20px]'}>
      <BuyButton onClick={onClickBuy} className={'bg-blue text-1.6 py-[12px]'}>
        {t('actual_deals.deal_card.buttons.buy')}
      </BuyButton>
      <MoreButton variant={'outline'} onClick={onClickMore} className={'text-1.6 px-[10px] py-[12px]'}>
        {t('actual_deals.deal_card.buttons.more')}
      </MoreButton>
    </div>
  );
};
