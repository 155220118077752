import React from 'react';
import { selectDigitalSafeInfo } from '7-entities/digitalSafe';
import { selectCurrentLanguage } from '7-entities/app';
import { useLocales } from '8-shared/lib/i18n';
import { useSelector } from 'react-redux';

export const Information = () => {
  const { i18n } = useLocales('main');
  const lang = useSelector(selectCurrentLanguage);
  const digitalSafe = useSelector(selectDigitalSafeInfo);

  return (
    <div className={'flex flex-col mobile:flex-row mobile:justify-between p-4 gap-2 bg-white_opacity_10 rounded-10 w-full laptop:w-1/2'}>
      <div className={'flex mobile:flex-col w-full mobile:max-w-[120px] mobile:text-center gap-1 justify-between'}>
        <div className={'text-1.4 text-white_opacity_80'}>{digitalSafe?.translations?.[lang]?.title}</div>
        <div className={'text-1.6 laptop:text-2.2 font-700 whitespace-pre'}>
          {`${i18n.t('plural.month', { count: digitalSafe?.deposit_period })}`}
        </div>
      </div>
      <div className={'flex mobile:flex-col mobile:max-w-[120px] mobile:text-center gap-1 justify-between'}>
        <div className={'text-1.4 text-white_opacity_80'}>{digitalSafe?.translations?.[lang]?.title2}</div>
        <div className={'text-1.6 laptop:text-2.2 font-700 whitespace-pre'}>{`от ${Number(digitalSafe?.deposit_amount)}`}</div>
      </div>
      <div className={'flex mobile:flex-col mobile:max-w-[120px] mobile:text-center gap-1 justify-between'}>
        <div className={'text-1.4 text-white_opacity_80'}>{digitalSafe?.translations?.[lang]?.title3}</div>
        <div className={'text-1.6 laptop:text-2.2 font-700 whitespace-pre'}>{`${digitalSafe?.profit_rate}%`}</div>
      </div>
    </div>

    // <div
    //   className={
    //     'flex flex-col px-[15px] mobile:px-[30px] mobile:px-[60px] bg-white_opacity_10 py-[30px] mobile:py-[60px] rounded-[30px] row-span-2 justify-between'
    //   }
    // >
    //   <div className={'flex justify-between w-full pb-[30px] laptop:pb-[47px]'}>
    //     <div>
    //       <div className={'flex mb-[15px] items-center'}>
    //         <img className={'w-[30px] h-[30px]'} src={digitalSafe?.icon} alt={'mainIDO_page_photo_1'} />
    //         <span className={'font-600 text-[25px] ml-[15px]'}>USDT</span>
    //       </div>
    //       <span className={'font-400 leading-[25px] text-1.4 mobile:text-1.6 text-white_opacity_80'}>{t('digital_safe.description')}</span>
    //     </div>
    //     <div className={'flex  flex-col w-1/2 text-right'}>
    //       <span className={'text-1.4 mobile:text-[16px] font-400 text-white_opacity_80'}>{t('digital_safe.price')}</span>
    //       <span className={'text-2.5 mobile:text-4 font-600 whitespace-pre'}>от {digitalSafe?.deposit_amount ?? 0}$</span>
    //     </div>
    //   </div>

    // {/*<div className={'flex flex-col w-full'}>*/}
    // {/*  <div className={'flex justify-between items-center pb-[15px]'}>*/}
    // {/*    <div className={'font-600 leading-[18px] text-[14px] mobile:text-2 desktop:leading-[20px]'}>{t('digital_safe.invest_size')}</div>*/}
    // {/*    <div className={'font-600 leading-[25px] text-1.4 mobile:text-2.2 opacity-60'}>*/}
    // {/*      {digitalSafeInfo?.amount_of_sold_coins?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} $*/}
    // {/*    </div>*/}
    // {/*  </div>*/}
    // {/*  <RemainIndicator*/}
    // {/*    max={digitalSafeInfo?.total_amount_of_coins}*/}
    // {/*    current={digitalSafeInfo?.total_amount_of_coins - digitalSafeInfo?.amount_of_sold_coins}*/}
    // {/*  />*/}
    // {/*</div>*/}
  );
};
