import React from 'react';

import { Orders, Statement } from '6-features/profile/documents';

export const DocumentsPage = () => {
  return (
    <div className={'flex flex-col gap-6'}>
      <Statement />
      <Orders />
    </div>
  );
};
