import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  dropdown: {
    padding: 0,
    borderRadius: '15px',
    backgroundColor: '#1e1b46',
    border: 'none',
  },
}));
