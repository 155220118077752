import React, { useState } from 'react';

import { Columns } from './historyTable/columns/columns';
import { Row } from './historyTable/row/row';

import { useGetWalletHistoryQuery, WALLET_HISTORY_TYPE } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { EmptyData, type ISwitchTab, Loader, Pagination, Switch } from '8-shared/ui';
import { useWindowSize } from '8-shared/hooks';

const PAGE_SIZE = 10;

export const WalletHistory = () => {
  const { t, i18n } = useLocales('wallet');
  const [currentPage, setCurrentPage] = useState(1);
  const { width } = useWindowSize();

  const tabs = [
    { value: undefined, title: t('table.filters.all') },
    { value: WALLET_HISTORY_TYPE.WITHDRAW, title: t('table.filters.withdraw') },
    { value: WALLET_HISTORY_TYPE.REPLENISHMENT, title: t('table.filters.replenishment') },
  ];

  const [filter, setFilter] = useState<ISwitchTab>(tabs[0]);
  const { data, isFetching } = useGetWalletHistoryQuery({ type: filter?.value, page_size: PAGE_SIZE, page: currentPage });
  const totalCount = Math.ceil((data?.count || 0) / PAGE_SIZE);

  const onChangeHandler = (tab: ISwitchTab) => {
    setFilter(tab);
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <div className={'flex flex-col gap-4 px-[10px] py-[30px] mobile:p-[30px] rounded-15 bg-black_opacity_30'}>
        <div className="text-white text-3.6 font-500 leading-[43px]">{t('history')}</div>
        <Switch tabs={tabs} currentTab={filter} onChange={onChangeHandler} />
        {!isFetching && !data?.results?.length ? (
          <EmptyData title={i18n.t('common.empty_table')} />
        ) : (
          <>
            <Columns />
            {data?.results.map((i, index) => (
              <Row key={index} item={i} />
            ))}
          </>
        )}
      </div>
      <Pagination
        disabled={isFetching}
        className={'mt-10 w-max mx-auto'}
        total={totalCount}
        value={currentPage}
        onChange={setCurrentPage}
        size={width > 640 ? 'lg' : 'sm'}
      />
    </>
  );
};
