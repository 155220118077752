import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectUser, selectVerifyStatus } from '7-entities/user';
import { VERIFY_STATUS } from '7-entities/auth/model/contants';
import { selectIsAuth, setIsShowVerify } from '7-entities/auth';
import { Button, type IButtonProps } from '8-shared/ui';
import { PATH_PAGE } from '8-shared/lib/router';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';
import { useAppDispatch } from '8-shared/hooks';

interface IProps extends IButtonProps {
  onClick: () => void;
  isNeedVerify?: boolean;
}

export const BuyButton = ({ onClick, isNeedVerify = false, ...rest }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  const verifyStatus = useSelector(selectVerifyStatus);
  const isAuth = useSelector(selectIsAuth);

  const onClickHandler = () => {
    if (!isAuth) {
      myTracker(user?.deal_id, TRACKER_EVENT.try_buy_unverified);
      navigate(PATH_PAGE.login, { state: { backgroundLocation: location } });
      return;
    }

    if (!isNeedVerify || verifyStatus === VERIFY_STATUS.COMPLETED_VERIFY) {
      myTracker(user?.deal_id, TRACKER_EVENT.try_buy_verified);
      return onClick();
    }

    myTracker(user?.deal_id, TRACKER_EVENT.try_buy_unverified);
    dispatch(setIsShowVerify(true));
  };
  return <Button onClick={onClickHandler} {...rest} />;
};
