import React from 'react';
import Tooltip from 'react-tooltip-lite';

import { IRefUser } from '7-entities/user';

interface IProps {
  item: IRefUser;
}
export const DesktopRow = ({ item }: IProps) => {
  return (
    <div
      className={
        'pl-[20px] pr-[45px] py-[40px] border rounded-[15px] border-white_opacity_50 flex mb-[20px] bg-white_opacity_3 justify-between text-2 text-center'
      }
    >
      <Tooltip
        mouseOutDelay={800}
        content={item.full_name}
        direction="up"
        background={'rgba(0, 0, 0, 0.3)'}
        tipContentClassName={'text-1.6 !w-[350px]'}
        className={'w-[250px] truncate text-left'}
      >
        {item.full_name}
      </Tooltip>
      <Tooltip
        mouseOutDelay={800}
        content={item.phone}
        direction="up"
        background={'rgba(0, 0, 0, 0.3)'}
        tipContentClassName={'text-1.6 !w-[350px]'}
        className={'w-[250px] truncate'}
      >
        {item.phone}
      </Tooltip>
      <Tooltip
        mouseOutDelay={800}
        content={item.email}
        direction="up"
        background={'rgba(0, 0, 0, 0.3)'}
        tipContentClassName={'text-1.6 !w-[350px]'}
        className={'w-[250px] truncate'}
      >
        {item.email}
      </Tooltip>
      <span className={'w-[200px] truncate'}>{item.total_amount_of_money}</span>
      <span className={'w-[150px] truncate'}>{item.number_of_invited}</span>
    </div>
  );
};
