import { createAsyncThunk } from '@reduxjs/toolkit';

import { authApi } from '7-entities/auth';
import { IUser, userAPI } from '7-entities/user';

interface IParams {
  email: Email;
  password: string;
}

export const loginThunk = createAsyncThunk<IUser, IParams, { state: RootState }>(
  'auth/login',
  async (body: IParams, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.login.initiate(body, { fixedCacheKey: 'login' })).unwrap();
      const res = await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
      await dispatch(userAPI.endpoints.getWalletBalance.initiate()).unwrap();
      await dispatch(userAPI.endpoints.getProfileInfo.initiate()).unwrap();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
