import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFloatingIPO, IIPO, IPO_TYPE } from './types';
import { ipoApi } from '../api/ipo.api';

interface IIpoSlice {
  buyIpoModal: {
    isOpen: boolean;
    item: IIPO | undefined;
  };
  ipo_companies: IIPO[];
  venture_companies: IIPO[];
  real_estate_companies: IIPO[];
  alternative_companies: IIPO[];
  ventureMonthDeal: IIPO | undefined;
  realEstateMonthDeal: IIPO | undefined;
  floating_ipo_companies: IFloatingIPO[];
}

const initialState: IIpoSlice = {
  buyIpoModal: {
    isOpen: false,
    item: undefined,
  },
  ipo_companies: [],
  venture_companies: [],
  real_estate_companies: [],
  alternative_companies: [],
  ventureMonthDeal: undefined,
  realEstateMonthDeal: undefined,
  floating_ipo_companies: [],
};

export const ipoSlice = createSlice({
  name: 'ipo',
  initialState,
  reducers: {
    openBuyModal: (state: IIpoSlice, action: PayloadAction<IIPO>) => {
      state.buyIpoModal = {
        isOpen: true,
        item: action.payload,
      };
    },
    closeBuyModal: (state: IIpoSlice) => {
      state.buyIpoModal = {
        isOpen: false,
        item: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ipoApi.endpoints.getIPO.matchFulfilled, (state: IIpoSlice, { payload }) => {
      const venture_companies = payload?.filter(
        (i) =>
          (i?.translations?.ru?.type && i?.translations?.ru?.type === IPO_TYPE.PRE_IPO) || i?.translations?.ru?.type === IPO_TYPE.VENTURE
      );
      const real_estate_companies = payload?.filter((i) => i?.translations?.ru?.type && i?.translations?.ru?.type === IPO_TYPE.PROPERTIES);
      const alternative_companies = payload?.filter((i) => i?.translations?.ru?.type && i?.translations?.ru?.type === IPO_TYPE.ALTERNATIVE);
      state.ipo_companies = payload;
      state.ventureMonthDeal = venture_companies.at(-1);
      state.realEstateMonthDeal = real_estate_companies.at(-1);
      state.venture_companies = venture_companies;
      state.real_estate_companies = real_estate_companies;
      state.alternative_companies = alternative_companies;
    });
    builder.addMatcher(ipoApi.endpoints.getFloatingIPO.matchFulfilled, (state: IIpoSlice, { payload }) => {
      state.floating_ipo_companies = payload;
    });
  },
});

export const selectIPOCompanies = (state: RootState): IIPO[] => state.ipo.ipo_companies;
export const selectVentureCompanies = (state: RootState): IIPO[] => state.ipo.venture_companies;
export const selectRealEstateCompanies = (state: RootState): IIPO[] => state.ipo.real_estate_companies;
export const selectAlternativeCompanies = (state: RootState): IIPO[] => state.ipo.alternative_companies;
export const selectVentureMonthDeal = (state: RootState): IIPO => state.ipo.ventureMonthDeal;
export const selectRealEstateMonthDeal = (state: RootState): IIPO => state.ipo.realEstateMonthDeal;
export const selectFloatingIPOCompanies = (state: RootState): IFloatingIPO[] => state.ipo.floating_ipo_companies;
export const selectBuyIpoModal = (state: RootState): { isOpen: boolean; item: IIPO | undefined } => state.ipo.buyIpoModal;

export const { closeBuyModal, openBuyModal } = ipoSlice.actions;
