import React from 'react';
import cn from 'classnames';

interface IProps {
  number: number;
  title: string;
  sub_title: string;
  isLast?: boolean;
}

export const Card = ({ number, title, sub_title, isLast }: IProps) => {
  return (
    <div
      className={'mobile:p-[20px] flex mobile:bg-white_opacity_10 rounded-30 mobile:flex-col relative z-[2] gap-[20px] laptop:gap-[30px]'}
    >
      <div className={'flex flex-col items-center mobile:items-start'}>
        <div
          className={
            'flex items-center justify-center text-[26px] laptop:text-[36px] min-w-[60px] max-w-[60px] min-h-[60px] rounded-full z-[5] bg-white_opacity_10 font-500'
          }
        >
          {number}
        </div>
        <hr
          className={cn('mobile:hidden h-full rotate-180 border-l border-dashed border-white_opacity_30', {
            ['hidden']: isLast,
          })}
        />
      </div>
      <div className={'flex flex-col mobile:flex-col laptop:flex-col'}>
        <span className={'text-2 mobile:text-2.4 font-500 leading-[20px]  mobile:leading-[31px] mobile:whitespace-pre-line mb-[22px]'}>
          {title}
        </span>
        <span className={'text-1.4 text-white_opacity_70 mobile:text-white font-400 leading-[18px]'}>{sub_title}</span>
        <hr
          className={cn('my-[30px] border-white_opacity_20 mobile:hidden', {
            ['hidden']: isLast,
          })}
        />
      </div>
    </div>
  );
};
