import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ISocialLoginBody } from '7-entities/auth/api/types';
import { authApi } from '7-entities/auth';
import { IUser, userAPI } from '7-entities/user';

export const socialLogin = createAsyncThunk<IUser, ISocialLoginBody, { state: RootState }>(
  'auth/socialLogin',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.socialLogin.initiate(data, { fixedCacheKey: 'socialLogin' })).unwrap();
      const user = await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
      await dispatch(userAPI.endpoints.getWalletBalance.initiate()).unwrap();
      await dispatch(userAPI.endpoints.getProfileInfo.initiate()).unwrap();
      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
