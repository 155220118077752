import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './baseQueryWithReauth';
import {
  AUTH_TAG,
  COMPANIES_TAG,
  DIGITAL_SAFE,
  DOCUMENTS,
  HISTORY,
  IPO,
  NOTIFICATIONS,
  P2P_TAG,
  PORTFOLIO_DEALS,
  PROFILE_INFO,
  USER,
  WALLET,
} from './tags';

export const baseApi = createApi({
  reducerPath: 'api',
  tagTypes: [
    AUTH_TAG,
    COMPANIES_TAG,
    P2P_TAG,
    IPO,
    USER,
    WALLET,
    PORTFOLIO_DEALS,
    DIGITAL_SAFE,
    NOTIFICATIONS,
    HISTORY,
    DOCUMENTS,
    PROFILE_INFO,
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
