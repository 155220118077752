import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    justifyItems: 'start',
    gap: '10px',
    width: '100%',
    [`@media (min-width: 1024px)`]: {
      flexDirection: 'column',
      gap: '80px',
    },
  },
  content: {
    padding: 0,
    width: '100%',
    height: '100%',
  },
  stepIcon: {
    borderWidth: '3px',
    borderColor: 'rgba(196, 196, 196, 0.3)',
    '&[data-progress]': {
      borderColor: 'rgba(78, 110, 233, 1)',
    },
    '&[data-completed]': {
      backgroundColor: 'rgba(78, 110, 233, 1)',
      borderColor: 'rgba(78, 110, 233, 1)',
    },
  },
  separator: {
    height: '3px',
  },
  separatorActive: {
    backgroundColor: 'rgba(78, 110, 233, 1)',
  },
  step: {
    flexDirection: 'column',
    position: 'relative',
  },
  stepBody: {
    display: 'none',
    top: '50px',
    position: 'absolute',
    marginLeft: '0px',
    alignItems: 'center',
    minWidth: '90px',
    [`@media (min-width: 1024px)`]: {
      display: 'block',
    },
  },
  stepLabel: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 400,
    fontFamily: 'Montserrat, serif',
  },
  stepDescription: {
    textAlign: 'center',
  },
}));
