import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { QuickWithdrawForm } from './quickWithdrawForm/quickWithdrawForm';
import { QuickWithdrawConfirm } from './quickWithdrawConfirm/quickWithdrawConfirm';
import type { IWithdrawForm, IWithdrawRejectBody } from '../../../model/types';
import { withdrawWallet } from '../../../model/wallet.thunks';

import { useWithdrawWalletMutation } from '7-entities/user/api/user.api';
import { selectUser, selectWalletBalance } from '7-entities/user';

import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  onBack: () => void;
  onClose: () => void;
  opened: boolean;
}

enum STEPS {
  FORM = 'form',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
  FAILED = 'failed',
}

const QUICK_WITHDRAW_COMMISSION = 15;

export const QuickWithdrawScenario = ({ opened, onClose, onBack }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const wallet = useSelector(selectWalletBalance);
  const maxWithdrawWallet = Number((wallet / ((QUICK_WITHDRAW_COMMISSION + 100) / 100)).toFixed(2));
  const user = useSelector(selectUser);
  const { t } = useLocales('wallet');

  const [step, setStep] = useState<STEPS>(STEPS.FORM);
  const [form, setForm] = useState<IWithdrawForm | undefined>();
  const [, { error }] = useWithdrawWalletMutation({ fixedCacheKey: 'withdrawWallet' });

  const onSubmitForm = (form: IWithdrawForm) => {
    setStep(STEPS.CONFIRM);
    setForm(form);
  };
  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  const onSendOrder = () => {
    if (form) {
      setIsLoading(true);
      dispatch(withdrawWallet({ ...form, urgently: true }))
        .unwrap()
        .then(() => {
          myTracker(user?.deal_id, TRACKER_EVENT.withdraw_quickly);
          setStep(STEPS.SUCCESS);
        })
        .catch(() => setStep(STEPS.FAILED))
        .finally(() => changeLoading());
    }
  };

  return (
    <>
      <QuickWithdrawForm
        onClose={onClose}
        opened={opened && step === STEPS.FORM}
        max={maxWithdrawWallet}
        onBack={onBack}
        onSubmit={onSubmitForm}
      />
      <QuickWithdrawConfirm
        commission={QUICK_WITHDRAW_COMMISSION}
        opened={opened && step === STEPS.CONFIRM}
        onSubmit={onSendOrder}
        onBack={() => setStep(STEPS.FORM)}
        onClose={onClose}
        info={form}
        isLoading={isLoading}
      />
      <StatusScreen
        opened={opened && step === STEPS.SUCCESS}
        onClose={onClose}
        title={t('modals.withdraw.success')}
        description={t('modals.withdraw.quick.success_description')}
        status={'success'}
      />
      <StatusScreen
        opened={opened && step === STEPS.FAILED}
        onClose={onClose}
        title={'FAILED'}
        description={(error as IWithdrawRejectBody)?.data?.detail}
        status={'error'}
      />
    </>
  );
};
