import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ITransferBonusForm } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Modal, NumberInput } from '8-shared/ui';

interface IProps {
  transferBonusHandler: (data: ITransferBonusForm) => void;
  isLoading: boolean;
  opened: boolean;
  onClose: () => void;
  maxCount: number;
}

export const TransferBonusModal = ({ transferBonusHandler, maxCount, opened, onClose, isLoading }: IProps) => {
  const { t, i18n } = useLocales('settings');

  const schema = yup.object().shape({
    price: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(0, i18n.t('validation.negative', { count: 0 }))
      .max(maxCount, i18n.t('validation.max_count', { count: maxCount })),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ITransferBonusForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      price: undefined,
    },
  });

  const onSubmit = (data: ITransferBonusForm) => {
    transferBonusHandler(data);
  };

  return (
    <Modal size={'md'} opened={opened} onClose={onClose}>
      <div className={'flex flex-col w-full text-black gap-7'}>
        <div className={'font-600 text-2.2 leading-[29px] desktop:text-2.8 desktop:leading-[37px]'}>
          {t('transfer_bonus.transfer_bonus_modal.title')}
        </div>
        <span className={'font-400 text-1.4 laptop:text-1.8 opacity-70 '}>{t('transfer_bonus.transfer_bonus_modal.count_label')}</span>
        <form autoCorrect={'off'} className={'flex flex-col gap-[15px]'} onSubmit={handleSubmit(onSubmit)}>
          <NumberInput precision={2} name={'price'} control={control} label={`${t('transfer_bonus.transfer_bonus_modal.count')}`} />
          <div className={'text-1.4 text-black_text_70 leading-[20px]'}>
            {t('transfer_bonus.transfer_bonus_modal.max_count', { count: maxCount })}
          </div>
          <Button disabled={!isValid} loading={isLoading} className={'w-full bg-blue'}>
            {t('transfer_bonus.transfer_bonus_modal.button')}
          </Button>
        </form>
      </div>
    </Modal>
  );
};
