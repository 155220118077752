import React from 'react';

import { useGetDigitalSafeHistoryQuery, useGetPortfolioHistoryQuery } from '7-entities/user';
import { HistoryList } from '5-widgets/profile/history';
import { useLocales } from '8-shared/lib/i18n';
import { Loader } from '8-shared/ui';

export const HistoryPage = () => {
  const { t } = useLocales('history');
  const { isLoading: portfolioLoading } = useGetPortfolioHistoryQuery();
  const { isLoading: digitalSafeLoading } = useGetDigitalSafeHistoryQuery();

  return (
    <>
      <div className={'mobile:hidden text-3.6 leading-[25px] font-700 pb-[25px]'}>{t('title')}</div>
      {(portfolioLoading || digitalSafeLoading) && <Loader />}
      <HistoryList />
    </>
  );
};
