import { createAsyncThunk } from '@reduxjs/toolkit';
import { FieldErrors } from 'react-hook-form';

import { userAPI } from '7-entities/user';
import { IRequestError } from '8-shared/model';

export interface IChangePasswordForm {
  password: string;
  password2: string;
  old_password: string;
}

export type IChangePasswordRejectBody = IRequestError<FieldErrors<IChangePasswordForm>>;

export const changePasswordThunk = createAsyncThunk<void, IChangePasswordForm, { state: RootState }>(
  'auth/login',
  async (body: IChangePasswordForm, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.changePassword.initiate(body, { fixedCacheKey: 'changePassword' })).unwrap();
      await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
