import React from 'react';

import { DesktopActualDeals, MobileActualDeals, BuyIPOScenario } from '6-features/actualDeals';

import { IIPO } from '7-entities/ipo';

interface IProps {
  companies: IIPO[];
}

export const ActualDeals = ({ companies }: IProps) => {
  return (
    <div>
      <BuyIPOScenario />
      <DesktopActualDeals companies={companies} />
      <MobileActualDeals companies={companies} />
    </div>
  );
};
