import React from 'react';
import { Avatar as MantineAvatar, type AvatarProps } from '@mantine/core';

interface IProps extends AvatarProps {
  onCLick?: () => void;
}

export const Avatar = ({ onCLick, variant = 'filled', radius = 'xl', size = 'md', color = 'indigo', ...rest }: IProps) => {
  return <MantineAvatar onClick={onCLick} variant={variant} radius={radius} size={size} color={color} {...rest} />;
};
