import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { PATH_PAGE } from '8-shared/lib/router';
import { ReactComponent as NotFoundIcon } from '8-shared/assets/icons/notFound_icon.svg';
import partnership_orange_shadow from '8-shared/assets/images/not_found_page_photo_1.webp';
import partnership_pink_shadow from '8-shared/assets/images/not_found_page_photo_2.webp';
import partnership_blue_shadow from '8-shared/assets/images/not_found_page_photo_3.webp';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useLocales();

  return (
    <div className={'h-[74vh] flex mx-2'}>
      <img
        className={'absolute top-0 left-0 h-full opacity-60 blur-[100px]'}
        alt={'not_found_page_photo_1'}
        src={partnership_orange_shadow}
      />
      <img
        className={'absolute top-0 left-[5%] h-full w-full z-0 opacity-80 blur-[200px]'}
        alt={'not_found_page_photo_2'}
        src={partnership_pink_shadow}
      />
      <img
        className={'absolute top-0 left-0 h-full z-0 opacity-50 blur-[10px]'}
        alt={'not_found_page_photo_3'}
        src={partnership_blue_shadow}
      />
      <div className={'h-full flex flex-col content-center items-center justify-center w-full h-full z-10'}>
        <NotFoundIcon className={'w-[300px] h-[110px] mobile:w-[450px] mobile:h-[170px] laptop:w-[630px] laptop:h-[230px]'} />
        <h1 className={'text-center text-2.8 mobile:text-3.6 pb-5 laptop:pb-10 uppercase'}>{t('not_found_page.description')}</h1>
        <Button className={'px-12  w-full mobile:w-auto bg-blue'} onClick={() => navigate(PATH_PAGE.root.venture)}>
          {t('not_found_page.back_btn')}
        </Button>
      </div>
    </div>
  );
};
