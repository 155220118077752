import React from 'react';
import { useSelector } from 'react-redux';

import { IOpenDigitalSafeForm } from '7-entities/user';
import { selectDigitalSafeInfo } from '7-entities/digitalSafe';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Modal } from '8-shared/ui';

interface IProps {
  opened: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  info: IOpenDigitalSafeForm | null;
  isLoading: boolean;
}

export const ConfirmOpenDigitalSafeModal = ({ opened, onClose, onBack, onSubmit, isLoading, info }: IProps) => {
  const { t } = useLocales('digitalSafe');
  const digitalSafe = useSelector(selectDigitalSafeInfo);
  return (
    <Modal size={'lg'} opened={opened} onClose={onClose} title={t('open_modals.title')}>
      <div className={'text-2 font-500 text-black_text_opacity_70 flex flex-col gap-5 mt-10'}>
        <div className={'flex flex-col gap-3 border-2 border-gray p-3 rounded-2xl'}>
          <div className={'flex justify-between'}>
            <div>{t('open_modals.confirm.amount')}</div>
            <div>$ {info?.amount}</div>
          </div>
          <div className={'w-full h-px border-t-2 border-gray'} />
          <div className={'flex justify-between items-center'}>
            <div>{t('open_modals.confirm.income')}</div>
            <div>{Number(digitalSafe?.profit_rate)}%</div>
          </div>
        </div>
        <div className={'flex justify-between items-center'}>
          <div>{t('open_modals.confirm.total_price')}</div>
          <div className={'flex flex-col gap-1 text-right'}>
            <div>$ {info?.amount}</div>
          </div>
        </div>
        <div className={'flex gap-3'}>
          <Button onClick={onBack} className={'border-blue text-blue w-full'} variant={'outline'}>
            {t('open_modals.confirm.back_btn')}
          </Button>
          <Button onClick={onSubmit} className={'bg-blue w-full'} loading={isLoading} disabled={isLoading}>
            {t('open_modals.confirm.submit_btn')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
