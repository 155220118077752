import { createAsyncThunk } from '@reduxjs/toolkit';

import { IOpenDigitalSafeForm, userAPI, ICloseDigitalSafeParams } from '7-entities/user';
import { ICommonError, IRequestError } from '8-shared/model';

export type IDigitalSafeThunksReject = IRequestError<ICommonError>;

export const openDigitalSafeThunk = createAsyncThunk<void, IOpenDigitalSafeForm, { state: RootState }>(
  'auth/login',
  async (data: IOpenDigitalSafeForm, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.openDigitalSafe.initiate(data, { fixedCacheKey: 'openDigitalSafe' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const closeDigitalSafeThunk = createAsyncThunk<void, ICloseDigitalSafeParams, { state: RootState }>(
  'auth/login',
  async (data: ICloseDigitalSafeParams, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.closeDigitalSafe.initiate(data, { fixedCacheKey: 'closeDigitalSafe' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
