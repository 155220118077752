import React from 'react';
import { Popover as MantinePopover, PopoverDropdownProps, PopoverProps, PopoverTargetProps } from '@mantine/core';
import { useStyles } from './styles';

// you need to use Target and Content for Popover
export const Popover = (props: PopoverProps) => {
  const { classes } = useStyles();
  return <MantinePopover classNames={classes} {...props} />;
};
// if you use a custom component in Target, you must wrap the React.forwardRef component
export const PopoverTarget = (props: PopoverTargetProps) => <MantinePopover.Target {...props} />;

export const PopoverContent = (props: PopoverDropdownProps) => <MantinePopover.Dropdown {...props} />;
