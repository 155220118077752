import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useViewportSize } from '@mantine/hooks';

import {
  FirstStepRegisterForm,
  FourthStepRegisterForm,
  SecondStepRegisterForm,
  ThirdStepRegisterForm,
  FifthStepRegisterForm,
} from '6-features/auth/register';

import { STEPS_ENUM, VERIFY_STATUS } from '7-entities/auth/model/contants';
import { selectIsLoading } from '7-entities/auth/model/auth.slice';
import { selectVerifyStatus } from '7-entities/user/model/user.slice';
import { selectIsInit } from '7-entities/app/model/app.slice';

import { useLocales } from '8-shared/lib/i18n';
import { Step, Stepper } from '8-shared/ui';

export interface IProps {
  onCloseModal: () => void;
  setOpen: (value: boolean) => void;
}

export const RegisterForm = ({ onCloseModal, setOpen }: IProps) => {
  const isLoading = useSelector(selectIsLoading);
  const verifyStatus = useSelector(selectVerifyStatus);
  const isInit = useSelector(selectIsInit);
  const [params, setParams] = useSearchParams();
  const { t } = useLocales('auth');
  const currentStep = Number(params.get('step'));
  const size = useViewportSize().width;
  const location = useLocation();
  const prevLocation = location.state?.backgroundLocation;

  const checkCurrentStep = () => {
    if (!verifyStatus) {
      return currentStep !== 2 && setParams(`step=${STEPS_ENUM.first}`, { replace: true, state: { backgroundLocation: prevLocation } });
    }
    if (verifyStatus === VERIFY_STATUS.NOT_VERIFIED) {
      return currentStep !== 4 && setParams(`step=${STEPS_ENUM.third}`, { replace: true, state: { backgroundLocation: prevLocation } });
    }
    if (verifyStatus === VERIFY_STATUS.PHONE_VERIFIED) {
      return setParams(`step=${STEPS_ENUM.fifth}`, { replace: true, state: { backgroundLocation: prevLocation } });
    }
    onCloseModal();
  };

  useEffect(() => {
    if (!isInit) {
      return;
    }
    checkCurrentStep();
    setOpen(true);
  }, [verifyStatus, currentStep, isInit]);

  return (
    <div className={'flex flex-col mt-5'}>
      <Stepper
        active={currentStep - 1}
        radius={'sm'}
        iconSize={size > 640 ? 45 : 35}
        orientation={size > 1023 ? 'horizontal' : 'vertical'}
        className={'laptop:px-12'}
      >
        <Step
          label={t('register.steps.0')}
          description={t('register.time', { count: 2 })}
          loading={isLoading && currentStep === STEPS_ENUM.first}
          step={STEPS_ENUM.first}
        >
          <FirstStepRegisterForm />
        </Step>
        <Step
          label={t('register.steps.1')}
          description={t('register.time', { count: 2 })}
          loading={isLoading && currentStep === STEPS_ENUM.second}
          step={STEPS_ENUM.second}
        >
          <SecondStepRegisterForm />
        </Step>
        <Step
          label={t('register.steps.2')}
          description={t('register.time', { count: 2 })}
          loading={isLoading && currentStep === STEPS_ENUM.third}
          step={STEPS_ENUM.third}
        >
          <ThirdStepRegisterForm />
        </Step>
        <Step
          label={t('register.steps.3')}
          description={t('register.time', { count: 2 })}
          loading={isLoading && currentStep === STEPS_ENUM.fourth}
          step={STEPS_ENUM.fourth}
        >
          <FourthStepRegisterForm />
        </Step>
        <Step
          label={t('register.steps.4')}
          description={t('register.time', { count: 2 })}
          loading={isLoading && currentStep === STEPS_ENUM.fifth}
          step={STEPS_ENUM.fifth}
        >
          <FifthStepRegisterForm onClose={onCloseModal} />
        </Step>
      </Stepper>
    </div>
  );
};
