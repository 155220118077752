export const openUrlInNewTab = (url: Url) => {
  if (!url) {
    return;
  }
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const getNoun = (count: number, words: [string, string, string]) => {
  let n = Math.abs(count);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return words[2];
  }
  n %= 10;
  if (n === 1) {
    return words[0];
  }
  if (n >= 2 && n <= 4) {
    return words[1];
  }
  return words[2];
};

export const convertNumber = (number: number): number => {
  return parseFloat((Math.round((number + Number.EPSILON) * 100) / 100).toFixed(2));
};

export const decimalPlaces = (number: number, factor: number = 2) => {
  if (number % 1 !== 0) {
    return number.toFixed(factor);
  }
  return number;
};
