import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser, useChangeAvatarMutation } from '7-entities/user';
import { Avatar, UploadButton } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';

import { changeAvatarThunk } from '../model/changeAvatar';

export const ChangeAvatar = () => {
  const { t } = useLocales('settings');
  const [, { isLoading }] = useChangeAvatarMutation({ fixedCacheKey: 'changeAvatar' });
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const onChange = async (data: File) => {
    const fd = new FormData();
    fd.append('profile_photo', data);
    await dispatch(changeAvatarThunk(fd));
  };
  return (
    <div className={'flex gap-4'}>
      <Avatar src={user?.profile_photo} />
      <UploadButton label={t('upload_avatar')} onChange={onChange} loading={isLoading} />
    </div>
  );
};
