import React from 'react';

interface IProps {
  title: string;
  value: string;
}

export const Property = ({ title, value }: IProps) => {
  return (
    <div className={'flex w-full mobile:flex-col justify-between items-center mobile:items-start'}>
      <div className={'font-400 leading-[25px] text-1.4 desktop:text-1.6 opacity-80 mobile:items-start mr-[11px] mobile:m-0'}>{title}</div>
      <div className={'font-600 leading-[28px] text-2.2 items-end'}>{value}</div>
    </div>
  );
};
