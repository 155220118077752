import { ISecondaryConfirmOrder, ISecondarySaleForm } from './types';

export const getSecondaryOrderInfo = (form: ISecondarySaleForm | undefined): ISecondaryConfirmOrder => {
  const quantity = form?.quantity || 0;
  const priceLot = form?.price_per_security || 0;
  const totalPrice = quantity * priceLot;

  return {
    totalPrice,
    quantity,
    priceLot,
  };
};
