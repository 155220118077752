import React from 'react';
import { Link as RouterLink, Location } from 'react-router-dom';

interface IProps {
  to: string;
  replace: boolean;
  //eslint-disable-next-line
  state: Location | {};
  children: React.ReactNode;
  className?: string;
}

export const Link = ({ to, replace, state, children, className }: IProps) => {
  return (
    <RouterLink
      className={`font-roboto text-1.4 laptop:text-1.6 font-400 underline p-0 m-0 leading-[25px] text-blue ${className}`}
      to={to}
      replace={replace}
      state={state}
    >
      {children}
    </RouterLink>
  );
};
