import React from 'react';

import { ReactComponent as SuccessIcon } from '8-shared/assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '8-shared/assets/icons/error_icon.svg';
import { Button, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  opened: boolean;
  onClose: () => void;
  status: 'success' | 'error';
  title?: string;
  description?: string;
}

export const StatusScreen = ({ opened, onClose, title, description, status }: IProps) => {
  const { t } = useLocales();
  return (
    <Modal size={'md'} opened={opened} onClose={onClose}>
      <div className={'flex flex-col w-full items-center text-black text-center px-[40px] gap-5'}>
        {status === 'success' && <SuccessIcon />}
        {status === 'error' && <ErrorIcon />}
        <div className={'font-600 text-2.2 leading-[29px] desktop:text-2.8 desktop:leading-[37px] mb-[15px]'}>{title}</div>
        <div className={'font-400 text-1.4 leading-[17px] desktop:text-1.8 desktop:leading-[22px]'}>{description}</div>
        <Button type={'button'} className={'bg-blue w-full'} onClick={onClose}>
          {t('common.back')}
        </Button>
      </div>
    </Modal>
  );
};
