import React from 'react';
import { IIPO } from '7-entities/ipo';

import { DealCard } from '../dealCard/dealCard';

interface IProps {
  companies: IIPO[];
}

export const DesktopActualDeals = ({ companies }: IProps) => {
  return (
    <div className={'relative z-[20] hidden laptop:grid layout-padding grid-cols-3 gap-[20px] mb-[130px]'}>
      {companies?.map((company, index) => (
        <DealCard company={company} isThird={index === 2} key={company?.id} />
      ))}
    </div>
  );
};
