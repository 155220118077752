import { IDigitalSafeHistory, IPortfolioHistory, OPERATION_TYPE, DIGITAL_SAFE_HISTORY_TYPE } from '7-entities/user';
import { LangVariants } from '8-shared/lib/i18n';

import { IHistoryData, IHistoryItem } from './types';

const addDateToHistory = (history: IHistoryItem[]) => {
  const historyWithDate: IHistoryData[] = [];
  for (let i = 0; i < history?.length; i++) {
    const date = history?.[i]?.created_at?.slice(0, 10);
    const arr = historyWithDate.find((i) => i?.date?.slice(0, 10) === date);
    if (!arr) {
      historyWithDate.push({
        date: history?.[i]?.created_at,
        items: [{ ...history[i] }],
      });
    } else {
      arr?.items?.push(history[i]);
    }
  }
  return historyWithDate;
};

export const prepareDataForHistory = (
  securities: IPortfolioHistory[],
  digitalSafesHistory: IDigitalSafeHistory[],
  lang: LangVariants
): IHistoryData[] => {
  const prepareSecurities: IHistoryItem[] =
    securities?.map((i) => ({
      logo: i?.company?.translations?.[lang].logo,
      type: i.type,
      name: i?.company?.translations?.[lang]?.title,
      amount: i.cost,
      created_at: i.created_at,
      currency: '$',
      full_price: i.full_price,
    })) ?? [];
  const prepareDigitalSafes: IHistoryItem[] =
    digitalSafesHistory?.map((i) => ({
      logo: i.logo,
      type: i.type === DIGITAL_SAFE_HISTORY_TYPE.BOUGHT ? OPERATION_TYPE.BUY : OPERATION_TYPE.SALE,
      name: 'DigitalSafe',
      amount: i.type === DIGITAL_SAFE_HISTORY_TYPE.BOUGHT ? i.amount : i.total,
      created_at: i.transaction_date,
      currency: '$',
    })) ?? [];
  const generalHistory: IHistoryItem[] = [...prepareSecurities, ...prepareDigitalSafes].sort(
    (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
  );
  return addDateToHistory(generalHistory);
};
