import React, { useEffect, useState } from 'react';

import { ReplenishCurrencyForm } from './replenishCurrencyForm/replenishCurrencyForm';
import { IReplenishCurrencyRejectBody } from '../../../model/types';

import { useReplenishmentCurrencyWalletMutation } from '7-entities/user';

import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

enum REPLENISH_CURRENCY_STEPS {
  FORM = 'form',
  SUCCESS = 'success',
  ERROR = 'error',
}
interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const ReplenishCurrencyScenario = ({ opened, onClose }: IProps) => {
  const [step, setStep] = useState<REPLENISH_CURRENCY_STEPS>(REPLENISH_CURRENCY_STEPS.FORM);
  const { t } = useLocales('wallet');
  const [, { error, isSuccess, reset }] = useReplenishmentCurrencyWalletMutation({ fixedCacheKey: 'replenishmentCurrencyWallet' });

  useEffect(() => {
    if (isSuccess) setStep(REPLENISH_CURRENCY_STEPS.SUCCESS);
    else if (error) setStep(REPLENISH_CURRENCY_STEPS.ERROR);
    return () => reset();
  }, [error, isSuccess]);

  const onCloseHandler = () => {
    setStep(REPLENISH_CURRENCY_STEPS.FORM);
    onClose();
  };

  if (!opened) {
    return null;
  }
  return (
    <>
      <ReplenishCurrencyForm onClose={onCloseHandler} opened={step === REPLENISH_CURRENCY_STEPS.FORM} />
      <StatusScreen
        opened={step === REPLENISH_CURRENCY_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={t('modals.replenishment.success.title')}
        description={t('modals.replenishment.success.description')}
        status={'success'}
      />
      <StatusScreen
        opened={step === REPLENISH_CURRENCY_STEPS.ERROR}
        onClose={onCloseHandler}
        title={t('FAILED')}
        description={(error as IReplenishCurrencyRejectBody)?.data?.detail}
        status={'error'}
      />
    </>
  );
};
