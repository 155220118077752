import React, { useState } from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { Select as MantineSelect, type SelectProps } from '@mantine/core';
import { useStyles } from './styles';

interface IProps<T extends FieldValues> extends Omit<SelectProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const Select = <T extends FieldValues>({ name, control, defaultValue, data, ...rest }: IProps<T>) => {
  const [focused, setFocused] = useState(false);
  const {
    field: { value: value = '', onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });
  const { classes } = useStyles({ floating: !!value || focused });
  return (
    <MantineSelect
      {...field}
      value={value}
      error={fieldState.error?.message}
      mt="md"
      onChange={(e) => fieldOnChange(e)}
      classNames={classes}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      data={[...data, '']}
      {...rest}
    />
  );
};
