import React from 'react';

import solution from '8-shared/assets/images/about_solution_bg_image.webp';
import { useLocales } from '8-shared/lib/i18n';

export const Solution = () => {
  const { t } = useLocales('about');
  return (
    <div className={'flex flex-col relative laptop:items-end mb-[40px] mobile:mb-[50px] laptop:mb-[250px]'}>
      <img
        alt={'about_page_photo_2'}
        src={solution}
        className={
          'absolute top-[-130px] left-0 mobile:top-[-100px] laptop:top-[-180px] desktop:top-[-60%] laptop:left-[-80px] desktop:left-0 max-w-[500px] laptop:max-w-[850px]  desktop:max-w-[100%]'
        }
      />
      <div className={'flex flex-col mobile:items-end pb-[40px] mobile:pb-[50px] laptop:pb-[33px]'}>
        <div
          className={'layout-padding mobile:w-1/2 flex flex-col pt-[340px] mobile:pt-[50px] laptop:pt-[210px] gap-[22px] mobile:gap-[33px]'}
        >
          <span className={'font-500 text-2.3 mobile:text-3.6 leading-[30px] mobile:leading-[46px]'}>{t('solution_title')}</span>
          <span className={'font-400 text-1.4 leading-[18px] laptop:font-500 laptop:text-1.6 laptop:leading-[20px]'}>
            {t('solution_description')}
          </span>
        </div>
      </div>
      <div
        className={
          'laptop:w-1/2 flex flex-col uppercase mobile:flex-row mobile:px-[67px] laptop:layout-padding mobile:justify-between items-center gap-[12px]'
        }
      >
        <div className={'text-[18px] font-600 gap-[7px] leading-[24px] mobile:gap-0 mobile:w-[150px] mobile:whitespace-pre-wrap'}>
          <span className={'text-white_opacity_50'}>{t('team')}</span>
          <span>{t('team_count')}</span>
        </div>
      </div>
    </div>
  );
};
