import React from 'react';
import { AboutUs } from '../aboutUs/aboutUs';
import { Mission } from '../mission/mission';
import { Solution } from '../solution/solution';
import { OurNumbers } from '../ourNumbers/ourNumbers';
import { OurStructure } from '../ourStucture/ourStructure';
import { WhyWe } from '../whyWe/whyWe';
import { Documents } from '../documents/documents';

export const AboutPage = () => {
  return (
    <div className={' flex flex-col relative z-[1]'}>
      <AboutUs />
      <Mission />
      <Solution />
      <OurNumbers />
      <OurStructure />
      <WhyWe />
      <Documents />
    </div>
  );
};
