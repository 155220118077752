import React, { useLayoutEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { IBuyForm } from '../../model/types';

import { Button, Input, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { getNoun } from '8-shared/lib/utils';

interface IProps {
  onSubmit: (data: IBuyForm) => void;
  max: number;
  opened: boolean;
  onClose: () => void;
  min?: number;
}

export const BuyForm = ({ onSubmit, max, opened, onClose, min }: IProps) => {
  const { t, i18n } = useLocales('buy');
  const schema = yup.object().shape({
    quantity: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(min || 1, i18n.t('validation.min_count', { count: min || 1 }))
      .max(max, i18n.t('validation.max_count', { count: max })),
  });

  const { control, handleSubmit, reset } = useForm<IBuyForm>({
    resolver: yupResolver(schema),
  });
  console.log();
  useLayoutEffect(() => {
    if (!opened) reset();
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} title={t('title')} size={'lg'}>
      <form className={'mt-10'} onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} name={'quantity'} label={i18n.t('name_form_fields.number_lots')} type={'number'} />
        <div className={'mt-2.5 text-1.4'}>{t('max_count', { count: max })}</div>
        {min && <div className={'text-1.4'}>{t('min_count', { count: min }) + getNoun(min, t('lots_noun', { returnObjects: true }))}</div>}
        <Button type={'submit'} className={'bg-blue w-full mt-6'} variant={'filled'}>
          {t('next')}
        </Button>
      </form>
    </Modal>
  );
};
