import React from 'react';
import { IRefUser } from '7-entities/user';

interface IProps {
  item: IRefUser;
}
export const LaptopRow = ({ item }: IProps) => {
  return (
    <div
      className={`p-[20px] border 
                        border-white_opacity_50 
                        flex mb-[10px] rounded-[15px]
                        bg-white_opacity_3 justify-between
                        items-center`}
    >
      <div className={`flex flex-col w-[280px] text-[12px] text-white_opacity_50 `}>
        <span className={'text-[20px] text-white truncate mb-[4ppx]'}>{item.full_name}</span>
        <span className={'truncate mb-[4ppx]'}>{item.phone}</span>
        <span className={'truncate mb-[4ppx]'}>{item.email}</span>
      </div>
      <div className={'flex w-[215px] text-right truncate'}>
        <span className={'text-[16px] w-[125px] truncate '}>{item.total_amount_of_money}</span>
        <span className={'text-[16px] w-[80px] truncate'}>{item.number_of_invited}</span>
      </div>
    </div>
  );
};
