import { createSlice } from '@reduxjs/toolkit';

import {
  IDigitalSafeHistory,
  type IDigitalSafeOperation,
  type IManager,
  type IMyReferalsQueryParams,
  type INotification,
  type IPortfolioHistory,
  type IProfileInfo,
  type IUser,
  type IWalletHistory,
  REGISTER_STAGE,
} from './types';
import { userAPI } from '../api/user.api';
import { VERIFY_STATUS } from '../../auth';

interface IUserSlice {
  user: IUser | undefined;
  verifyStatus: VERIFY_STATUS | undefined;
  filters: IMyReferalsQueryParams;
  manager: IManager | undefined;
  portfolioHistory: IPortfolioHistory[];
  walletBalance: number;
  walletHistory: IWalletHistory[];
  replenishmentModal: boolean;
  withdrawModal: boolean;
  digitalSafes: IDigitalSafeOperation[];
  digitalSafesHistory: IDigitalSafeHistory[];
  notifications: INotification[];
  unWatchNotifications: number;
  profileInfo: IProfileInfo | undefined;
}

const initialState: IUserSlice = {
  user: undefined,
  verifyStatus: undefined,
  filters: {
    page: 1,
    page_size: 10,
  },
  manager: undefined,
  portfolioHistory: [],
  walletBalance: 0,
  walletHistory: [],
  replenishmentModal: false,
  withdrawModal: false,
  digitalSafes: [],
  digitalSafesHistory: [],
  notifications: [],
  unWatchNotifications: 0,
  profileInfo: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    openReplenishmentModal: (state: IUserSlice) => {
      state.replenishmentModal = true;
    },
    closeReplenishmentModal: (state: IUserSlice) => {
      state.replenishmentModal = false;
    },
    openWithdrawModal: (state: IUserSlice) => {
      state.withdrawModal = true;
    },
    closeWithdrawModal: (state: IUserSlice) => {
      state.withdrawModal = false;
    },
    changeMyReferalsFilters: (state, { payload }) => {
      state.filters = payload;
    },
    clearUserData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(userAPI.endpoints.getUser.matchFulfilled, (state: IUserSlice, { payload }) => {
      state.user = payload;
      state.manager = payload.manager;

      if (payload.register_stage === REGISTER_STAGE.PROGRESS) {
        state.verifyStatus = VERIFY_STATUS.PROGRESS_VERIFY;
        return;
      }

      if (payload.register_stage === REGISTER_STAGE.COMPLETED) {
        state.verifyStatus = VERIFY_STATUS.COMPLETED_VERIFY;
        return;
      }

      if (payload.register_stage === REGISTER_STAGE.LAST) {
        state.verifyStatus = VERIFY_STATUS.SURVEY_VERIFIED;
        return;
      }

      if (payload.is_phone_confirmed) {
        state.verifyStatus = VERIFY_STATUS.PHONE_VERIFIED;
        return;
      }

      state.verifyStatus = VERIFY_STATUS.NOT_VERIFIED;
    });
    builder.addMatcher(userAPI.endpoints.getUser.matchRejected, (state: IUserSlice) => {
      state.user = undefined;
      state.manager = undefined;
      state.verifyStatus = undefined;
      state.portfolioHistory = [];
      state.walletBalance = 0;
      state.unWatchNotifications = 0;
      state.notifications = [];
      state.digitalSafes = [];
      state.digitalSafesHistory = [];
    });
    builder.addMatcher(userAPI.endpoints.getPortfolioHistory.matchFulfilled, (state: IUserSlice, { payload }) => {
      state.portfolioHistory = payload;
    });
    builder.addMatcher(userAPI.endpoints.getWalletBalance.matchFulfilled, (state: IUserSlice, { payload }) => {
      state.walletBalance = Number(payload.amount_usdt);
    });
    builder.addMatcher(userAPI.endpoints.getDigitalSafeHistory.matchFulfilled, (state: IUserSlice, { payload }) => {
      state.digitalSafesHistory = payload;
    });
    builder.addMatcher(userAPI.endpoints.getNotification.matchFulfilled, (state: IUserSlice, { payload }) => {
      state.notifications = payload;
      state.unWatchNotifications = payload.filter((i) => !i.is_read)?.length;
    });
    builder.addMatcher(userAPI.endpoints.getProfileInfo.matchFulfilled, (state: IUserSlice, { payload }) => {
      state.profileInfo = payload;
    });
  },
});

export const {
  changeMyReferalsFilters,
  clearUserData,
  closeReplenishmentModal,
  openReplenishmentModal,
  openWithdrawModal,
  closeWithdrawModal,
} = userSlice.actions;

export const selectUser = (state: RootState): IUser => state.user.user;
export const selectReferalsFilters = (state: RootState): IMyReferalsQueryParams => state.user.filters;
export const selectManager = (state: RootState): IManager => state.user.manager;
export const selectVerifyStatus = (state: RootState): VERIFY_STATUS => state.user.verifyStatus;
export const selectPortfolioHistory = (state: RootState): IPortfolioHistory[] => state.user.portfolioHistory;
export const selectWalletBalance = (state: RootState): number => state.user.walletBalance;
export const selectWalletHistory = (state: RootState): IWalletHistory[] => state.user.walletHistory;
export const selectReplenishmentModalWallet = (state: RootState): boolean => state.user.replenishmentModal;
export const selectWithdrawModalWallet = (state: RootState): boolean => state.user.withdrawModal;
export const selectDigitalSafesOperations = (state: RootState): IDigitalSafeOperation[] => state.user.digitalSafes;
export const selectNotifications = (state: RootState): INotification[] => state.user.notifications;
export const selectUnwatchNotifications = (state: RootState): number => state.user.unWatchNotifications;
export const selectDigitalSafesHistory = (state: RootState): IDigitalSafeHistory[] => state.user.digitalSafesHistory;
export const selectProfileInfo = (state: RootState): IProfileInfo => state.user.profileInfo;
