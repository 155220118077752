import React, { useEffect, useState } from 'react';

import { ReplenishUSDTForm } from './replenishUSDTForm/replenishUSDTForm';
import { type IReplenishUSDTRejectBody } from '../../../model/types';
import { useReplenishmentUSDTWalletMutation } from '7-entities/user/api/user.api';

import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

enum REPLENISH_USDT_STEPS {
  FORM = 'form',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const ReplenishUSDTScenario = ({ opened, onClose }: IProps) => {
  const [step, setStep] = useState<REPLENISH_USDT_STEPS>(REPLENISH_USDT_STEPS.FORM);
  const { t } = useLocales('wallet');
  const [, { error, isSuccess, reset }] = useReplenishmentUSDTWalletMutation({ fixedCacheKey: 'replenishmentUSDTWallet' });

  useEffect(() => {
    if (isSuccess) setStep(REPLENISH_USDT_STEPS.SUCCESS);
    else if (error) setStep(REPLENISH_USDT_STEPS.ERROR);

    return () => reset();
  }, [error, isSuccess]);

  const onCloseHandler = () => {
    setStep(REPLENISH_USDT_STEPS.FORM);
    onClose();
  };

  if (!opened) {
    return null;
  }

  return (
    <>
      <ReplenishUSDTForm onClose={onCloseHandler} opened={step === REPLENISH_USDT_STEPS.FORM} />
      <StatusScreen
        opened={step === REPLENISH_USDT_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={t('modals.replenishment.success.title')}
        description={t('modals.replenishment.success.description')}
        status={'success'}
      />
      <StatusScreen
        opened={step === REPLENISH_USDT_STEPS.ERROR}
        onClose={onCloseHandler}
        title={t('FAILED')}
        description={(error as IReplenishUSDTRejectBody)?.data?.detail}
        status={'error'}
      />
    </>
  );
};
