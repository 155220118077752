import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

import { Card } from './card/card';

export const OurStructure = () => {
  const { t } = useLocales('about');

  const deal_cards = [
    { title: t('deal_cards_1.title'), sub_title: t('deal_cards_1.sub_title') },
    { title: t('deal_cards_2.title'), sub_title: t('deal_cards_2.sub_title') },
    { title: t('deal_cards_3.title'), sub_title: t('deal_cards_3.sub_title') },
    { title: t('deal_cards_4.title'), sub_title: t('deal_cards_4.sub_title') },
    { title: t('deal_cards_5.title'), sub_title: t('deal_cards_5.sub_title') },
    { title: t('deal_cards_6.title'), sub_title: t('deal_cards_6.sub_title') },
  ];

  return (
    <div className={'layout-padding py-0 mb-[60px] mobile:mb-[120px] laptop:mb-[80px]'}>
      <div className={'uppercase mobile:w-[300px] font-500 text-2.3 mobile:text-3.6 leading-[29px] mobile:leading-[47px] pb-[33px]'}>
        {t('our_deals.title')}
      </div>
      <div
        className={
          'mobile:w-2/3 laptop:w-[500px] font-500 text-1.4 mobile:text-1.6 leading-[18px] mobile:leading-[20px] pb-[22px] mobile:pb-[41px]'
        }
      >
        {t('our_deals.sub_title')}
      </div>
      <div
        className={'grid grid-cols-1 mobile:grid-cols-2 laptop:grid-cols-3 mobile:gap-[16px] mb-[35px] mobile:mb-[60px] laptop:mb-[100px]'}
      >
        {deal_cards.map((i, index) => (
          <Card key={i.title} isLast={index + 1 === deal_cards.length} number={index + 1} title={i.title} sub_title={i.sub_title} />
        ))}
      </div>
    </div>
  );
};
