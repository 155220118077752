import React, { useState } from 'react';

import { Columns } from './columns';
import { Row } from './row';

import { DIGITAL_SAFE_TYPE, IDigitalSafeOperation, useGetMyDigitalSafeOperationsQuery } from '7-entities/user';

import { EmptyData, Loader, Pagination } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useWindowSize } from '8-shared/hooks';

const PAGE_SIZE = 10;

interface IProps {
  onCloseSafe: (item: IDigitalSafeOperation) => void;
}
export const DigitalSafesTable = ({ onCloseSafe }: IProps) => {
  const { t } = useLocales();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isFetching } = useGetMyDigitalSafeOperationsQuery({
    page: currentPage,
    page_size: PAGE_SIZE,
    type: DIGITAL_SAFE_TYPE.IN_PROGRESS,
  });
  const { width } = useWindowSize();
  const totalCount = Math.ceil((data?.count || 0) / PAGE_SIZE);

  if (!isFetching && !data?.results?.length) {
    return <EmptyData title={t('common.empty_table')} />;
  }
  if (isFetching) {
    return <Loader />;
  }

  return (
    <div>
      <Columns />
      {data?.results?.map((i) => (
        <Row key={i?.id} onClose={onCloseSafe} item={i} />
      ))}
      <Pagination
        disabled={isFetching}
        className={'mt-10 w-max mx-auto'}
        total={totalCount}
        value={currentPage}
        onChange={setCurrentPage}
        size={width > 640 ? 'lg' : 'sm'}
      />
    </div>
  );
};
