import React from 'react';

interface IProps {
  number: number;
  title: string;
  description: string;
}

export const StructureCard = ({ number, title, description }: IProps) => {
  return (
    <div
      className={'flex mobile:py-[30px] laptop:p-0 gap-[20px]  border-white_opacity_20 mobile:border-b laptop:border-none last:border-none'}
    >
      <div className={'flex flex-col items-center mobile:items-start'}>
        <div
          className={
            'flex items-center justify-center text-[26px] laptop:text-[36px] min-w-[60px] min-h-[60px] rounded-full z-[5] bg-white_opacity_10 font-500 laptop:min-w-[90px] laptop:min-h-[90px]'
          }
        >
          {number}
        </div>
        <hr className={'mobile:hidden h-full rotate-180 border-l border-dashed border-white_opacity_30'} />
      </div>
      <div className={'flex  flex-col mobile:flex-row laptop:flex-col'}>
        <span
          className={
            'mb-[20px] w-full mobile:w-1/2 laptop:w-full mr-[20px] mobile:w-[210px] laptop:w-full text-2 laptop:text-2.4 font-500 leading-[20px] laptop:leading-[31px]'
          }
        >
          {title}
        </span>
        <span
          className={
            'text-1.4 w-full mobile:w-1/2 laptop:w-full laptop:text-2.2 laptop:leading-[29px] text-white_opacity_70 font-400 leading-[18px]'
          }
        >
          {description}
        </span>
      </div>
    </div>
  );
};
