import { combineReducers } from '@reduxjs/toolkit';

import { baseApi } from '8-shared/api/baseApi';
import { appSlice } from '7-entities/app';
import { authSlice } from '7-entities/auth';
import { ipoSlice } from '7-entities/ipo';
import { p2pSlice } from '7-entities/p2p';
import { digitalSafeSlice } from '7-entities/digitalSafe';
import { userSlice } from '7-entities/user';

export const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [appSlice.name]: appSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [ipoSlice.name]: ipoSlice.reducer,
  [p2pSlice.name]: p2pSlice.reducer,
  [digitalSafeSlice.name]: digitalSafeSlice.reducer,
  [userSlice.name]: userSlice.reducer,
});
