import { createAsyncThunk } from '@reduxjs/toolkit';

import { userAPI } from '7-entities/user';

import type { IReplenishmentCurrencyWalletBody, IReplenishmentUSDTWalletBody, IWithdrawWalletBody } from '7-entities/user/api/types';

export const replenishmentUSDTWallet = createAsyncThunk<void, IReplenishmentUSDTWalletBody, { state: RootState }>(
  'userApi/replenishment',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.replenishmentUSDTWallet.initiate(data, { fixedCacheKey: 'replenishmentUSDTWallet' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const replenishmentCurrencyWallet = createAsyncThunk<void, IReplenishmentCurrencyWalletBody, { state: RootState }>(
  'userApi/replenishment',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(
        userAPI.endpoints.replenishmentCurrencyWallet.initiate(data, { fixedCacheKey: 'replenishmentCurrencyWallet' })
      ).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const withdrawWallet = createAsyncThunk<void, IWithdrawWalletBody, { state: RootState }>(
  'userApi/withdraw',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.withdrawWallet.initiate(data, { fixedCacheKey: 'withdrawWallet' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
