import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
// import ReactFacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import AppleLogin from 'react-apple-login';
import jwtDecode from 'jwt-decode';

import { socialLogin } from './model/thunks';
import { getGoogleUserInfo } from './model/utils';
import type { ISocialLoginRejectBody, IAppleLoginInfo, IAppleUserInfo } from './model/types';

import { useSocialLoginMutation } from '7-entities/auth/api/auth.api';
import { IUser } from '7-entities/user';

import { ReactComponent as AppleIcon } from '8-shared/assets/icons/apple.svg';
// import { ReactComponent as FacebookIcon } from '8-shared/assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '8-shared/assets/icons/google.svg';
import { useAppDispatch } from '8-shared/hooks';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  trigger?: () => Promise<boolean>;
}

export const SocialLogin = ({ trigger }: IProps) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>();
  const [, { isLoading }] = useSocialLoginMutation({ fixedCacheKey: 'socialLogin' });
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const data = await getGoogleUserInfo(codeResponse.access_token);
      if (data) {
        dispatch(
          socialLogin({
            name: data.name,
            surname: data.surname,
            email: data.email,
            social_user_id: data.id,
            type_register: 'google',
          })
        )
          .unwrap()
          .then((data: IUser) => myTracker(data?.deal_id, TRACKER_EVENT.login))
          .catch((error: ISocialLoginRejectBody) => setError(error.data.detail));
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  });
  // const facebookLogin = (res: ReactFacebookLoginInfo) => {
  //   dispatch(
  //     socialLogin({
  //       name: res?.name,
  //       email: res?.email || `${res.userID}@facebook.com`,
  //       type_register: 'facebook',
  //       social_user_id: res.userID,
  //     })
  //   ).unwrap().then((data:IUser) => {
  //       myTracker(data?.deal_id, TRACKER_EVENT.login)
  //   });
  // };

  const appleLogin = (appleResponse: IAppleLoginInfo) => {
    if (!appleResponse.authorization) return;
    const decodeToken: IAppleUserInfo = jwtDecode(appleResponse.authorization.id_token);
    dispatch(
      socialLogin({
        name: appleResponse?.user?.name.firstName,
        surname: appleResponse?.user?.name.lastName,
        email: appleResponse?.user?.email || decodeToken.email || `${appleResponse?.user?.name.lastName}@icloud.com`,
        social_user_id: decodeToken.sub,
        type_register: 'apple',
      })
    )
      .unwrap()
      .then((data: IUser) => {
        myTracker(data?.deal_id, TRACKER_EVENT.login);
      })
      .catch((error: ISocialLoginRejectBody) => setError(error.data.detail));
  };

  const onCheckValidation = async (cb: () => void) => {
    if (!trigger) {
      return cb();
    }

    const isValidate = await trigger();
    if (isValidate) {
      cb();
    }
  };

  return (
    <div className={'flex flex-col gap-2.5'}>
      <div className={'m-auto text-2 font-500'}>или</div>
      <div className={`flex gap-5 mx-auto items-center ${isLoading && 'opacity-30 pointer-events-none'}`}>
        <button onClick={() => onCheckValidation(login)}>
          <GoogleIcon className={'border border-black_text_opacity_15 rounded-full p-1 w-[60px] h-[60px]'} />
        </button>
        <AppleLogin
          render={({ onClick, ...props }) => (
            <button onClick={() => onCheckValidation(onClick)} {...props}>
              <AppleIcon className={'border border-black_text_opacity_15 rounded-full p-1 w-[60px] h-[60px]'} />
            </button>
          )}
          clientId={process.env.REACT_APP_APPLE_OAUTH_CLIENT_ID as string}
          redirectURI={process.env.REACT_APP_REF_URL as string}
          usePopup={true}
          callback={appleLogin}
          scope={'name email'}
        />
        {/*<ReactFacebookLogin*/}
        {/*  textButton={''}*/}
        {/*  cssClass={''}*/}
        {/*  icon={<FacebookIcon className={'border border-black_text_opacity_15 rounded-full p-1 w-[60px] h-[60px]'} />}*/}
        {/*  appId={process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID as string}*/}
        {/*  callback={facebookLogin}*/}
        {/*  scope={'public_profile,email'}*/}
        {/*  fields={'name,email'}*/}
        {/*/>*/}
      </div>
      <div className={'text-red text-1.4 text-center'}>{error}</div>
    </div>
  );
};
