import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ISaleP2PBody } from '7-entities/p2p/model/types';
import { p2pApi } from '7-entities/p2p/api/p2p.api';
import type { ISaleIPOBody } from '7-entities/ipo/api/types';
import { ipoApi } from '7-entities/ipo';

export const saleP2P = createAsyncThunk<void, ISaleP2PBody, { state: RootState }>(
  'p2p/sale',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(p2pApi.endpoints.saleP2PDeals.initiate(data, { fixedCacheKey: 'salePortfolioDeal' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const saleIpo = createAsyncThunk<void, ISaleIPOBody, { state: RootState }>(
  'ipo/sale',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(ipoApi.endpoints.saleIPO.initiate(data, { fixedCacheKey: 'salePortfolioDeal' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
