import React from 'react';
import { useSelector } from 'react-redux';

import { ManagerBlock } from '5-widgets/managerBlock';

import { ChangeAvatar } from '6-features/profile/settings/changeAvatar';
import { UserInfo } from '6-features/profile/settings/userInfo';
import { AgentBanner } from '6-features/profile/settings/agentBanner';
import { ChangePasswordForm } from '6-features/profile/settings/changePasswordForm';
import { TransferBonus } from '6-features/profile/settings/transferBonus';

import { selectUser } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';

export const SettingsPage = () => {
  const { t } = useLocales('settings');
  const user = useSelector(selectUser);
  return (
    <div className={'flex flex-col w-full'}>
      <div className={'flex flex-col min-h-[200px] gap-[30px]'}>
        <div className={'flex flex-col-reverse desktop:grid desktop:grid-cols-2 '}>
          <div className={'flex flex-col w-full desktop:w-[80%] gap-4'}>
            <div className={'text-2.2 text-left font-600 leading-[29px]'}>Личные настройки</div>
            <ChangeAvatar />
            <UserInfo />
            {!user?.is_registered_by_social && <ChangePasswordForm />}
            <TransferBonus />
          </div>
          <div className={'w-full desktop:w-max flex flex-col bg-blue_bg desktop:bg-transparent rounded-10 py-[10px] px-[20px] mb-[25px]'}>
            <div className={'text-2.2 text-left font-600 leading-[29px] mb-[20px] desktop:mb-[25px]'}>{t('manager')}</div>
            <ManagerBlock />
          </div>
        </div>
        <AgentBanner />
      </div>
    </div>
  );
};
