import { baseApi } from '8-shared/api';

import { type IP2pDealDto } from './types';
import { mapCompany } from '../lib/mapCompany';
import type { IBuyP2PBody, IP2pDeal, ISaleP2PBody } from '../model/types';

import { DOCUMENTS, HISTORY, P2P_TAG, PORTFOLIO_DEALS, PROFILE_INFO, WALLET } from '8-shared/api/tags';

export const p2pApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getP2PDeals: build.query<IP2pDeal[], void>({
      query: () => {
        return {
          url: `securities/peer2peer/get_popular/`,
        };
      },
      providesTags: [P2P_TAG],
      transformResponse: (response: IP2pDealDto[]) => response.map(mapCompany),
    }),
    buyP2PDeals: build.mutation<void, IBuyP2PBody>({
      query: (data) => {
        return {
          url: `/securities/peer2peer/buy_from_offer/`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [P2P_TAG, PORTFOLIO_DEALS, WALLET, HISTORY, DOCUMENTS, PROFILE_INFO],
    }),
    saleP2PDeals: build.mutation<void, ISaleP2PBody>({
      query: (data) => {
        return {
          url: `/securities/peer2peer/create_offer/`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [PORTFOLIO_DEALS, WALLET, P2P_TAG, HISTORY, DOCUMENTS, PROFILE_INFO],
    }),
  }),
});

export const { useGetP2PDealsQuery, useBuyP2PDealsMutation, useSaleP2PDealsMutation } = p2pApi;
