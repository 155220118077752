import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { changePasswordThunk, IChangePasswordForm, IChangePasswordRejectBody } from '../model/changePassword';

import { useChangePasswordMutation } from '7-entities/user';

import { useLocales } from '8-shared/lib/i18n';
import { Button, PasswordInput } from '8-shared/ui';
import { formErrorHandler } from '8-shared/lib/form';
import { useAppDispatch } from '8-shared/hooks';

export const ChangePasswordForm = () => {
  const { t, i18n } = useLocales('settings');
  const dispatch = useAppDispatch();

  const [, { isLoading, reset: resetReqBody, isSuccess }] = useChangePasswordMutation({ fixedCacheKey: 'changePassword' });

  const schema = yup.object().shape({
    password: yup
      .string()
      .max(128, i18n.t('validation.max', { count: '128' }))
      .required(i18n.t('validation.required'))
      .test('same_old_password', i18n.t('validation.used'), (value, context) => value !== context?.parent?.old_password),
    password2: yup
      .string()
      .max(128, i18n.t('validation.max', { count: '128' }))
      .test('same_passwords', i18n.t('validation.dontMatchPassword'), (value, context) => value === context?.parent?.password)
      .required(i18n.t('validation.required')),
    old_password: yup
      .string()
      .max(128, i18n.t('validation.max', { count: '128' }))
      .required(i18n.t('validation.required')),
  });

  const { control, reset, handleSubmit, setError } = useForm<IChangePasswordForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IChangePasswordForm) => {
    dispatch(changePasswordThunk(data))
      .unwrap()
      .then(() => {
        reset();
        resetReqBody();
      })
      .catch((error: IChangePasswordRejectBody) => formErrorHandler({ errors: error.data, setError }));
  };

  return (
    <form className={'flex flex-col gap-4'} onSubmit={handleSubmit(onSubmit)}>
      <PasswordInput label={`${t('change_password.old_password')}`} textColor={'#ffffff'} name={'old_password'} control={control} />
      <PasswordInput label={`${t('change_password.password')}`} textColor={'#ffffff'} name={'password'} control={control} />
      <PasswordInput label={`${t('change_password.password2')}`} textColor={'#ffffff'} name={'password2'} control={control} />
      <Button
        isSuccess={isSuccess}
        successLabel={`${t('change_password.change_password_btn_success')}`}
        loading={isLoading}
        className={'bg-blue'}
      >
        {t('change_password.change_password_btn')}
      </Button>
    </form>
  );
};
