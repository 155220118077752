import React from 'react';

import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  title: string;
  link: string;
}

export const Order = ({ title, link }: IProps) => {
  const { t } = useLocales('documents');
  return (
    <div
      className={
        'px-3.5 py-2.5 rounded-2xl flex flex-col justify-between mobile:items-center gap-4 border border-white_opacity_12 bg-white_opacity_3 mobile:flex-row mobile:px-4 mobile:py-7'
      }
    >
      <div className={'text-1.4 p-2.5 mobile:text-2 mobile:font-500'}>{title}</div>
      <a href={link} target={'_blank'} rel={'noopener noreferrer'}>
        <Button variant={'outline'} className={'w-full mobile:w-max'}>
          {t('view')}
        </Button>
      </a>
    </div>
  );
};
