import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '7-entities/user';

import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { openUrlInNewTab } from '8-shared/lib/utils';

export const Statement = () => {
  const { t } = useLocales('documents');
  const user = useSelector(selectUser);
  const onClick = () => {
    openUrlInNewTab(user?.signed_user_assignment as string);
  };
  return (
    <div
      className={
        'px-3.5 py-7 rounded-2xl flex flex-col justify-between mobile:items-center gap-6  mobile:p-8 mobile:flex-row mobile:pr-11 bg-gradient-to-r from-white_opacity_15 via-white_opacity_15 to-navy_blue'
      }
    >
      <div className={'text-2.4 mobile:text-3.2 laptop:text-3.6 font-500 h-min'}>{t('statement')}</div>
      {user?.signed_user_assignment && (
        <Button variant={'outline'} className={'w-full mobile:w-max'} onClick={onClick}>
          {t('view')}
        </Button>
      )}
    </div>
  );
};
