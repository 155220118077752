import type { IPortfolioHistoryDto } from '../api/types';
import type { IPortfolioHistory, OPERATION_TYPE } from '../model/types';

export const mapPortfolioHistory = (dto: IPortfolioHistoryDto): IPortfolioHistory => {
  return {
    belong: dto.belong,
    company: dto.company,
    cost: dto.cost,
    created_at: dto.created_at,
    quantity: dto.quantity,
    type: dto.type as OPERATION_TYPE,
    full_price: dto.full_price,
  };
};
