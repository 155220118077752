import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  control: {
    border: 'none',
    color: 'white',
    '&[data-active]:not([data-disabled]):hover': {
      background: 'rgba(255, 255, 255, 0.03)',
    },
    '@media (hover: hover)': {
      '&:not([data-disabled]):hover': {
        background: 'rgba(255, 255, 255, 0.03)',
      },
    },
    '@media (hover: none)': {
      '&:not([data-disabled]):active': {
        background: 'rgba(255, 255, 255, 0.03)',
      },
    },
    '&[data-active]': {
      border: 'gray solid 1px',
      background: 'rgba(255, 255, 255, 0.03)',
    },
  },
}));
