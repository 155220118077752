import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLanguage } from '7-entities/app';
import { getFullDate } from '8-shared/lib/utils';

import { Row } from '../row/row';
import { IHistoryData } from '../../model/types';

interface IProps {
  item: IHistoryData;
}

export const Day = ({ item }: IProps) => {
  const lang = useSelector(selectCurrentLanguage);
  return (
    <div className={'flex flex-col'}>
      <div className={'px-[20px] py-[15px] rounded-10 bg-white_opacity_8 font-400 text-white_opacity_55 text-1.6'}>
        {getFullDate(item?.date, lang)}
      </div>
      {item?.items?.map((i, index) => (
        <Row key={index} item={i} />
      ))}
    </div>
  );
};
