import React from 'react';

import { IBuyConfirmInfo } from '../../model/types';

import { Button, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  opened: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  info: IBuyConfirmInfo;
  isLoading: boolean;
}

export const ConfirmBuy = ({ onBack, onSubmit, info, onClose, opened, isLoading }: IProps) => {
  const { t } = useLocales('buy');
  return (
    <Modal size={'lg'} opened={opened} onClose={onClose} title={t('title')}>
      <div className={'text-2 font-500 text-black_text_opacity_70 flex flex-col gap-5 mt-10'}>
        <div className={'flex flex-col gap-3 border-2 border-gray p-3 rounded-2xl'}>
          <div className={'flex justify-between'}>
            <div>{t('number_lots')}</div>
            <div>{info.quantity}</div>
          </div>
          <div className={'flex justify-between items-center'}>
            <div>{t('cost_per_lot')}</div>
            <div>{info.priceLot} $</div>
          </div>
          <div className={'w-full h-px border-t-2 border-gray'} />
          <div className={'flex justify-between items-center'}>
            <div>{t('transaction_commission', { commission: info.commission })}</div>
            <div>{info.commissionPrice} $</div>
          </div>
          <div className={'flex justify-between items-center'}>
            <div>{t('amount_without_commission')}</div>
            <div>{info.priceWithoutCommission} $</div>
          </div>
        </div>
        <div className={'flex justify-between items-center'}>
          <div>{t('total_price')}</div>
          <div>{info.totalPrice} $</div>
        </div>
        <div className={'flex gap-3'}>
          <Button onClick={onBack} className={'border-blue text-blue w-full'} variant={'outline'}>
            {t('back')}
          </Button>
          <Button disabled={isLoading} onClick={onSubmit} className={'bg-blue w-full'} loading={isLoading}>
            {t('pay')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
