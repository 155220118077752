import React from 'react';
import { useLocales } from '8-shared/lib/i18n';

export const Title = () => {
  const { t } = useLocales('main');
  return (
    <div className={'flex flex-col gap-[20px] mb-[18px] mobile:mb-[36px] desktop:max-w-[350px] desktop:mb-[30px]'}>
      <div className={'text-3 mobile:text-5 mobile:leading-[45px] desktop:text-9 desktop:leading-[81px] w-1/2 leading-[27px] font-700'}>
        {t('month_deal.title')}
      </div>
      <div className={'text-1.4 leading-[14px] w-4/5 mobile:w-1/2 desktop:text-2 desktop:leading-[28px] desktop:w-full'}>
        {t('month_deal.sub_title')}
      </div>
      <hr className={'h-[1px] w-full border-white_opacity_50 desktop:hidden'} />
    </div>
  );
};
