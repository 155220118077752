import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ITransferBonusForm, selectUser, useGetBonusRequestMutation } from '7-entities/user';
import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';

import { TransferBonusModal } from './transferBonusModal/transferBonusModal';
import { TRANSFER_BONUS_STEPS } from '../../model/types';
import { ITransferBonusReject, transferBonusThunk } from '../../model/transferBonus.thunk';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

export const TransferBonusScenario = ({ opened, onClose }: IProps) => {
  const { t } = useLocales('settings');
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [step, setStep] = useState<TRANSFER_BONUS_STEPS>(TRANSFER_BONUS_STEPS.CONFIRM);

  const [, { isLoading, reset, error }] = useGetBonusRequestMutation({ fixedCacheKey: 'getBonusRequest' });

  const transferBonusHandler = (data: ITransferBonusForm) => {
    dispatch(transferBonusThunk(data))
      .unwrap()
      .then(() => setStep(TRANSFER_BONUS_STEPS.SUCCESS))
      .catch(() => setStep(TRANSFER_BONUS_STEPS.ERROR));
  };

  const onCloseHandler = () => {
    onClose();
    reset();
    setStep(TRANSFER_BONUS_STEPS.CONFIRM);
  };

  if (!opened) {
    return null;
  }

  return (
    <>
      <TransferBonusModal
        maxCount={user?.ref_money}
        opened={step === TRANSFER_BONUS_STEPS.CONFIRM}
        onClose={onCloseHandler}
        isLoading={isLoading}
        transferBonusHandler={transferBonusHandler}
      />
      <StatusScreen
        opened={step === TRANSFER_BONUS_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={`${t('transfer_bonus.transfer_bonus_statuses.success')}`}
        description={`${t('transfer_bonus.transfer_bonus_statuses.success_desc')}`}
        status={'success'}
      />
      <StatusScreen
        opened={step === TRANSFER_BONUS_STEPS.ERROR}
        onClose={onCloseHandler}
        title={`${t('transfer_bonus.transfer_bonus_statuses.error')}`}
        description={(error as ITransferBonusReject)?.data?.detail}
        status={'error'}
      />
    </>
  );
};
