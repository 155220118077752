import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 10,
    background: 'transparent',
    padding: '15px 30px',
    border: 'none',
    [theme.fn.smallerThan(640)]: {
      padding: 15,
      background: '#29264f',
    },
  },
}));
