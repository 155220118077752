import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsLoading } from '7-entities/auth/model/auth.slice';

import { useLocales } from '8-shared/lib/i18n';
import { useCountdownTimer } from '8-shared/hooks';

interface IProps {
  callBack: () => void;
}

export const TryAgain = ({ callBack }: IProps) => {
  const { isLoading } = useSelector(selectIsLoading);
  const { t } = useLocales('auth');
  const { timeLeft, restart } = useCountdownTimer({ initialTime: 60 });
  const onClick = () => {
    callBack();
    restart();
  };
  if (timeLeft <= 0)
    return (
      <div className={'text-1.6 font-400 text-center'}>
        {t('register.dont_has_code')}&nbsp;
        <button type={'button'} onClick={onClick} disabled={isLoading} className={'underline font-500 text-blue disabled:hidden'}>
          {t('register.try_again')}
        </button>
      </div>
    );
  return (
    <div className={'text-1.6 font-400 text-center'}>
      {t('register.dont_has_code')}&nbsp;
      <span>
        {t('register.try_again')} ({timeLeft} {t('register.sec')})
      </span>
    </div>
  );
};
