import React, { useLayoutEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { ISecondarySaleForm } from '../../../../model/types';

import { Button, Input, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

export interface IProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: ISecondarySaleForm) => void;
  max: number;
  onBack: () => void;
}

export const SecondarySaleForm = ({ onSubmit, max, onBack, opened, onClose }: IProps) => {
  const { t, i18n } = useLocales('sale');

  const schema = yup.object().shape({
    price_per_security: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(1, i18n.t('validation.min_count', { count: 1 })),

    quantity: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(1, i18n.t('validation.min_count', { count: 1 }))
      .max(max, i18n.t('validation.max_count', { count: max })),
  });

  const { control, handleSubmit, reset } = useForm<ISecondarySaleForm>({
    resolver: yupResolver(schema),
  });

  useLayoutEffect(() => {
    if (!opened) reset();
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} title={t('title')} size={'lg'}>
      <form className={'mt-10'} onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} name={'quantity'} label={i18n.t('name_form_fields.number_lots')} type={'number'} />
        <div className={'mt-2.5 mb-6 text-1.4'}>{t('max_count', { count: max })}</div>
        <Input control={control} name={'price_per_security'} label={i18n.t('name_form_fields.price_for_lot')} type={'number'} />
        <div className={'mt-2.5 mb-6 text-1.4'}>{t('secondary_sale.description_price')}</div>
        <div className={'flex gap-5 flex-col mobile:flex-row'}>
          <Button type={'button'} onClick={onBack} className={'border-blue text-blue w-full'} variant={'outline'}>
            {t('back')}
          </Button>
          <Button type={'submit'} className={'bg-blue w-full'}>
            {t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
