import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme, { floating }: { floating: boolean }) => ({
  root: {
    position: 'relative',
  },

  label: {
    position: 'absolute',
    zIndex: 2,
    top: 35,
    left: 30,
    pointerEvents: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
    textTransform: floating ? 'lowercase' : 'none',
    transition: 'transform 150ms ease, color 150ms ease, font-size 150ms ease',
    transform: floating ? `translate(-${theme.spacing.sm}, ${rem(-28)})` : `translate(-${theme.spacing.sm}, ${rem(-15)})`,
    fontSize: floating ? theme.fontSizes.xs : rem(16),
    fontWeight: floating ? 500 : 400,
  },

  required: {
    transition: 'opacity 150ms ease',
    opacity: floating ? 1 : 0,
  },
  rightSection: {
    display: 'none',
  },

  dropdown: {
    zIndex: 100000,
    position: 'fixed',
    fontSize: '20px',
  },
  item: {
    fontSize: rem(16),
    fontFamily: 'Montserrat',
    '&[data-selected]': {
      '&, &:hover': {
        backgroundColor: 'rgba(78, 110, 233, 1)',
        color: theme.white,
      },
    },
  },

  input: {
    fontFamily: 'Montserrat',
    fontSize: rem(16),
    height: 65,
    padding: floating ? '30px 20px 10px 20px' : '20px 20px',
  },
}));
