import React, { Suspense, useEffect } from 'react';
import { AppRoutes } from './routes/routes';
import { useSelector } from 'react-redux';

import { selectIsInit, setIsInit } from '7-entities/app';
import {
  useLazyGetNotificationQuery,
  useLazyGetUserQuery,
  useLazyGetWalletBalanceQuery,
  useLazyGetProfileInfoQuery,
} from '7-entities/user';
import { setIsAuth } from '../7-entities/auth';

import { useAppDispatch } from '8-shared/hooks';
import '8-shared/lib/i18n/i18n';
import '8-shared/assets/index.css';
import { ReactComponent as CompanyLogo } from '8-shared/assets/icons/company_logo_large.svg';

export const App = () => {
  const isInit = useSelector(selectIsInit);
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [getNotification] = useLazyGetNotificationQuery();
  const [getWalletBalance] = useLazyGetWalletBalanceQuery();
  const [getProfileInfo] = useLazyGetProfileInfoQuery();

  const initApp = async () => {
    try {
      await getUser()
        .unwrap()
        .then(() => {
          dispatch(setIsAuth(true));
        });
      await getNotification().unwrap();
      await getWalletBalance().unwrap();
      await getProfileInfo().unwrap();
    } catch (e) {
      throw new Error('error');
    } finally {
      dispatch(setIsInit(true));
    }
  };

  useEffect(() => {
    if (!isInit) {
      initApp();
    }
  }, [isInit]);
  if (!isInit)
    return (
      <div className={'w-[100vw] h-[100vh] flex justify-center items-center'}>
        <CompanyLogo className={'w-[300px] h-[300px]'} />
      </div>
    );

  return (
    <Suspense fallback={''}>
      <AppRoutes />
    </Suspense>
  );
};
