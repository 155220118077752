import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigate } from 'react-router-dom';

import { useForgotPasswordMutation } from '7-entities/auth';
import { Button, Input } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { formErrorHandler } from '8-shared/lib/form';
import { PATH_PAGE } from '8-shared/lib/router';

import { forgotPasswordForm } from '../../model/forgotPassword';
import { IForgotPasswordForm, IForgotPasswordRejectBody } from '../../model/types';

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useLocales('auth');
  const dispatch = useAppDispatch();

  const [, { isLoading }] = useForgotPasswordMutation({ fixedCacheKey: 'forgotPassword' });

  const schema = yup.object().shape({
    email: yup.string().email(i18n.t('validation.email')).required(i18n.t('validation.required')),
  });

  const { control, handleSubmit, setError } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IForgotPasswordForm) => {
    dispatch(forgotPasswordForm(data))
      .unwrap()
      .then(() => {
        navigate(PATH_PAGE.login);
      })
      .catch((e: IForgotPasswordRejectBody) => formErrorHandler({ errors: e.data, setError }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input className={'mb-[10px]'} label={`${t('forgot_password.email')}`} name={'email'} control={control} />
      <Input className={'mb-[20px]'} name={'detail'} hidden control={control} />
      <div className={'flex justify-between'}>
        <Button loading={isLoading} className={'bg-blue'}>
          {t('forgot_password.submit')}
        </Button>
      </div>
    </form>
  );
};
