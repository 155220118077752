import { IPO_TYPE } from '7-entities/ipo';

export const getCompanyTagColor = (tag: IPO_TYPE | null): string | null => {
  switch (tag) {
    case IPO_TYPE.PRE_IPO:
      return 'bg-[#E94E4E]';
    case IPO_TYPE.PROPERTIES:
      return 'bg-[#ffffff]';
    case IPO_TYPE.VENTURE:
      return 'bg-[#96FF85]';
    case IPO_TYPE.ALTERNATIVE:
      return 'bg-[#8338EC]';
    default:
      return null;
  }
};
