import React from 'react';
import cn from 'classnames';

export interface ITab {
  label: string;
  value: string;
}

interface IProps {
  data: ITab[];
  activeTab?: string;
  onChange: (tab: ITab) => void;
}

export const TabSwitcher = ({ data, activeTab, onChange }: IProps) => {
  const onChangeCurrentPage = (tab: ITab) => {
    onChange(tab);
  };

  return (
    <div className={'w-max flex rounded-full font-400 text-1.4 mobile:text-1.8 bg-switcher_color text-center text-white_opacity_50'}>
      {data.map((i) => (
        <div
          key={i.value}
          className={cn('cursor-pointer px-[20px] mobile:px-[40px] py-[11px] w-[123px] mobile:w-[194px]', {
            ['switcher_select text-white font-600 rounded-full']: activeTab === i.value,
          })}
          onClick={() => onChangeCurrentPage(i)}
        >
          {i.label}
        </div>
      ))}
    </div>
  );
};
