import React, { useRef } from 'react';

import { FeedbackForm } from '6-features/feedback';
import { useLocales } from '8-shared/lib/i18n';
import partnership_blue_shadow from '8-shared/assets/images/partnership_bg_image_3.webp';

import { BecomePartner } from '../becomePartner/becomePartner';
import { Information } from '../information/information';
import { PartnerProgram } from '../partnerProgramm/partnerProgram';

export const PartnershipPage = () => {
  const { t } = useLocales('partnership');

  const ref = useRef<HTMLDivElement>(null);
  const scrollTo = () => {
    ref?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'start' });
  };
  return (
    <div className={'pt-24 pb-52'}>
      <img className={'flex absolute top-0 w-full right-0'} alt={'partnership_page_photo_1'} src={partnership_blue_shadow} />
      <BecomePartner scrollTo={scrollTo} />
      <Information scrollTo={scrollTo} />
      <PartnerProgram scrollTo={scrollTo} />
      <div className={'layout-padding-mobile'}>
        <div
          ref={ref}
          className={
            'w-full flex flex-col laptop:flex-row mb-[30px] partnership_page_feedback_card backdrop-blur-[24px] px-[20px] laptop:px-[60px] py-[75px]  rounded-30 border border-white_opacity_40'
          }
        >
          <div className={'flex flex-col laptop:w-[50%] laptop:mr-[43px]'}>
            <span className={'uppercase text-[23px] mobile:text-[36px] font-500 leading-[30px] mobile:leading-[47px] mb-[22px]'}>
              {t('feedback_form.title')}
            </span>
            <span className={'text-1.4 laptop:text-1.6 mobile:w-[380px] laptop:w-full font-300 leading-[20px] mb-[36px]'}>
              {t('feedback_form.sub_title')}
            </span>
          </div>
          <div className={'flex flex-col w-full'}>
            <FeedbackForm />
          </div>
        </div>
      </div>
    </div>
  );
};
