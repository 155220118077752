import React from 'react';

import { CopyButton } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  inviteLink: string;
}

export const InviteLink = ({ inviteLink }: IProps) => {
  const { t } = useLocales('agent');
  return (
    <div className={'flex flex-col rounded-10 justify-between mobile:flex-row mobile:bg-white_opacity_20 laptop:w-[600px]'}>
      <span className={'text-[16px] rounded-10 px-[20px] py-[15px] bg-white_opacity_20 mobile:bg-white/0 mb-[15px]'}>{inviteLink}</span>
      <CopyButton value={inviteLink} label={`${t('copy_btn.copy')}`} copiedLabel={`${t('copy_btn.copied')}`} />
    </div>
  );
};
