import { createAsyncThunk } from '@reduxjs/toolkit';

import { IBuyP2PBody } from '7-entities/p2p/model/types';
import { p2pApi } from '7-entities/p2p/api/p2p.api';

export const buyP2P = createAsyncThunk<void, IBuyP2PBody, { state: RootState }>(
  'ipo/buyP2P',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(p2pApi.endpoints.buyP2PDeals.initiate(data, { fixedCacheKey: 'buyP2P' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
