import React from 'react';
import cn from 'classnames';
import { RemainIndicator, Tag } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

import { getCompanyTagColor } from '../../model/getCompanyTagColor';
import { Property } from './property/property';
import { Buttons } from './buttons/buttons';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '7-entities/app';
import { IIPO } from '7-entities/ipo';

interface IProps {
  company: IIPO;
  isThird: boolean;
}

export const DealCard = ({ company, isThird }: IProps) => {
  const lang = useSelector(selectCurrentLanguage);
  const data = company?.translations?.[lang];
  const { t, i18n } = useLocales('main');

  return (
    <>
      <div
        className={cn(
          'relative main_page_actual_deal_card z-[0] flex h-full laptop:h-full flex-col  min-h-[500px] ml-[10px] mobile:ml-[20px] laptop:ml-0 justify-between backdrop-blur-[24px] p-[30px] rounded-30 border border-white_opacity_20',
          {
            ['main_page_actual_deal_card_third']: isThird,
          }
        )}
      >
        <div className={'flex justify-between items-start mb-[20px] gap-[60px]'}>
          <div className={'grow'}>
            <img alt={'mainIPO-page-photo-6'} src={data?.logo} />
          </div>
          {data?.type && <Tag color={getCompanyTagColor(data?.type)} label={data?.type} />}
        </div>
        <div className={'text-1.2 mobile:text-1.4 text-white_opacity_75 mb-[20px] mobile:mb-[40px]'}>{data?.description}</div>
        <div>
          <div
            className={'text-2.2 mobile:text-3.2 leading-[27px] mobile:leading-[39px] font-600 mb-[5px] mobile:mb-[10px]'}
          >{`${data?.price} $`}</div>
          <div className={'flex flex-col mobile:mb-[20px]'}>
            <div className={'flex flex-col mb-[20px]'}>
              <div className={'flex justify-between mb-[8px]'}>
                <div className={'font-400 leading-[20px] text-1.2 mobile:text-1.4'}>
                  {`${t('actual_deals.deal_card.remain')} ${i18n.t('plural.lot', {
                    count: data?.remain,
                  })}`}
                </div>
                <div className={'font-400 leading-[20px] text-1.2 mobile:text-1.4 text-white_opacity_30'}>
                  {`${data?.max_securities - data?.remain} / ${data?.max_securities}`}
                </div>
              </div>
              <RemainIndicator max={data?.max_securities} current={data?.remain} isSmall />
            </div>
            <div className={'grid grid-cols-1 mobile:grid-cols-2 mb-0 mobile:mb-[20px] items-end mobile:justify-end'}>
              <Property
                value={`$ ${data?.capitalization} ${data?.capitalization_measure}`}
                title={t('actual_deals.deal_card.capitalization')}
              />
              <Property value={`${data?.profit}`} title={t('actual_deals.deal_card.profit')} />
            </div>
          </div>
        </div>
        <Buttons company={company} />
      </div>
    </>
  );
};
