export enum OPEN_DIGITAL_SAFE_STEPS {
  FORM,
  CONFIRM,
  SUCCESS,
  ERROR,
}

export enum CLOSE_DIGITAL_SAFE_STEPS {
  CONFIRM,
  SUCCESS,
  ERROR,
}
