import React from 'react';
import { Outlet } from 'react-router-dom';

import { ProfileTabs } from '5-widgets/profile/tabs';

export const ProfilePageLayout = () => {
  return (
    <div className={'text-white w-full h-full bg-main_bg flex justify-center flex-col'}>
      <div className={'relative max-w-[1920px] w-full overflow-hidden h-full flex flex-col'}>
        <ProfileTabs />
        <div className={'px-[15px] mobile:px-[30px] large:px-[130px] py-[25px] mobile:py-[30px] pb-[70px]'}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
