import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { QuickSaleForm } from './quickSaleForm/quickSaleForm';
import { QuickSaleConfirm } from './quickSaleConfirm/quickSaleConfirm';
import { getQuickOrderInfo } from '../../../model/getQuickOrderInfo';
import type { IQuickSaleForm } from '../../../model/types';
import { saleIpo } from '../../../model/thunks';

import { IPortfolioDeals, selectUser } from '7-entities/user';
import { selectCurrentLanguage } from '7-entities/app';

import { StatusScreen } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useAppDispatch } from '8-shared/hooks';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

interface IProps {
  onBack: () => void;
  onClose: () => void;
  opened: boolean;
  item: IPortfolioDeals;
}

enum STEPS {
  FORM = 'form',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
}

export const QuickScenario = ({ opened, onClose, onBack, item }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const lang = useSelector(selectCurrentLanguage);
  const user = useSelector(selectUser);
  const [step, setStep] = useState<STEPS>(STEPS.FORM);
  const [form, setForm] = useState<IQuickSaleForm | undefined>();
  const { t } = useLocales('sale');

  const onSubmitForm = (form: IQuickSaleForm) => {
    setStep(STEPS.CONFIRM);
    setForm(form);
  };
  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };
  const onSendOrder = () => {
    if (!form) return;
    setIsLoading(true);
    dispatch(saleIpo({ operation_id: item.id, quantity: form.quantity }))
      .unwrap()
      .then(() => {
        setStep(STEPS.SUCCESS);
        dataLayerEventHandler('sold_operations');
        myTracker(user?.deal_id, TRACKER_EVENT.sell_quickly);
      })
      .finally(() => changeLoading());
  };
  return (
    <>
      <QuickSaleForm onClose={onClose} opened={opened && step === STEPS.FORM} max={item.remain} onBack={onBack} onSubmit={onSubmitForm} />
      <QuickSaleConfirm
        opened={opened && step === STEPS.CONFIRM}
        onSubmit={onSendOrder}
        onBack={() => setStep(STEPS.FORM)}
        onClose={onClose}
        info={getQuickOrderInfo({ item, form, lang })}
        isLoading={isLoading}
      />
      <StatusScreen
        opened={opened && step === STEPS.SUCCESS}
        onClose={onClose}
        title={t('quick_sale.success.title')}
        description={t('quick_sale.success.description')}
        status={'success'}
      />
    </>
  );
};
