import { createListenerMiddleware, type TypedStartListening } from '@reduxjs/toolkit';

import { logoutAction } from './logout.action';
import { invalidateRefreshTokenListener } from './invalidateTokenEvent';

export const invalidateJWTTokenListener = createListenerMiddleware();
export type TypedListening = TypedStartListening<RootState, AppDispatch>;

export const startInvalidateJSWTTokenListener = invalidateJWTTokenListener.startListening as TypedListening;

startInvalidateJSWTTokenListener({
  actionCreator: invalidateRefreshTokenListener,
  effect: (_, { dispatch }) => {
    logoutAction(dispatch);
  },
});
