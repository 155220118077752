import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { selectDigitalSafeInfo } from '7-entities/digitalSafe';
import { IOpenDigitalSafeForm } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Input, Modal } from '8-shared/ui';

interface IProps {
  onSubmitForm: (data: IOpenDigitalSafeForm) => void;
  opened: boolean;
  onClose: () => void;
}

export const OpenDigitalSafeFormModal = ({ onSubmitForm, opened, onClose }: IProps) => {
  const { t, i18n } = useLocales('digitalSafe');
  const digitalSafe = useSelector(selectDigitalSafeInfo);
  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(i18n.t('validation.required'))
      .required(i18n.t('validation.required'))
      .min(Number(digitalSafe?.deposit_amount), i18n.t(' ')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IOpenDigitalSafeForm>({
    resolver: yupResolver(schema),
  });

  useLayoutEffect(() => {
    if (!opened) reset();
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} title={t('open_modals.title')} size={'lg'}>
      <form className={'mt-10'} onSubmit={handleSubmit(onSubmitForm)}>
        <Input control={control} name={'amount'} label={t('open_modals.form.amount')} type={'number'} />
        <div className={`${errors.amount?.message && 'text-red'} mt-2.5 mb-6 text-1.4`}>
          {t('open_modals.form.min_count', { count: Number(digitalSafe?.deposit_amount) })}
        </div>
        <Button type={'submit'} className={'bg-blue w-full'} variant={'filled'}>
          {t('open_modals.form.next_btn')}
        </Button>
      </form>
    </Modal>
  );
};
