import React from 'react';

import { Button } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import partnership_header_bg_image from '8-shared/assets/images/partnership_bg_image_2.webp';
import { ReactComponent as DotIcon } from '8-shared/assets/icons/dot.svg';

interface IProps {
  scrollTo: () => void;
}

export const BecomePartner = ({ scrollTo }: IProps) => {
  const { t } = useLocales('partnership');
  return (
    <div className={'relative z-0 mb-[60px] mobile:mb-[90px] laptop:mb-[270px]'}>
      <img
        alt={'partnership_page_photo_2'}
        className={'absolute right-[30px] w-[90%] max-h-[750px] mobile:w-1/2 opacity-60 z-0'}
        src={partnership_header_bg_image}
      />
      <div className={'flex flex-col mobile:w-1/2  pt-[100%] mobile:pt-[50px] layout-padding'}>
        <h1
          className={
            'font-600 text-3.3 mobile:text-3.5 laptop:text-7 leading-[33px] mobile:leading-[35px] laptop:leading-[70px] tracking-widest mb-[22px] laptop:mb-[33px]'
          }
        >
          <span>{t('title')}</span>
          <span className={'text-yellow block'}>{t('company')}</span>
        </h1>
        <div className={'flex mb-[22px] laptop:mb-[33px]'}>
          <DotIcon className={'fill-yellow h-[10px] w-[10px] mr-[15px] mt-[4px] laptop:mt-[9px]'} />
          <span className={'font-500 text-1.4 laptop:text-2.8 leading-[18px] laptop:leading-[36px]'}>{t('option_1')}</span>
        </div>
        <div className={'flex mb-[22px] laptop:mb-[33px]'}>
          <DotIcon className={'fill-yellow h-[10px] w-[10px] mr-[15px] mt-[4px] laptop:mt-[9px]'} />
          <span className={'font-500 text-1.4 laptop:text-2.8 leading-[18px] laptop:leading-[36px]'}>{t('option_2')}</span>
        </div>
        <Button onClick={scrollTo} className={'w-full link relative z-[5] bg-blue mobile:w-max mr-[10px]'}>
          {t('become_partner')}
        </Button>
      </div>
    </div>
  );
};
