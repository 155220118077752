import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useViewportSize } from '@mantine/hooks';

import { Button, Input } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { ReactComponent as ArrowIcon } from '8-shared/assets/icons/arrow_down_2.svg';

import { IReferalsTableFilterForm } from '../../../model/types';

interface IProps {
  filterReferalsList: (data: IReferalsTableFilterForm) => void;
  isLoading: boolean;
}

export const Filters = ({ filterReferalsList, isLoading }: IProps) => {
  const { t } = useLocales('agent');
  const { width } = useViewportSize();
  const isDesktop = useMemo(() => width >= 1024, [width]);
  const [show, setShow] = useState<boolean>(isDesktop);
  const { handleSubmit, control } = useForm<IReferalsTableFilterForm>();
  const onSubmit = (data: IReferalsTableFilterForm) => {
    filterReferalsList(data);
  };
  return (
    <div className={`flex flex-col relative ${!show && 'top-[25px]'}`}>
      {!isDesktop && (
        <div
          className={`flex uppercase items-center z-[42]
                      w-[50px] text-[10px]
                      text-white_opacity_50 laptop:text-[14px] mb-[10px]`}
          onClick={() => setShow((prevState) => !prevState)}
        >
          <span className={'mr-[4px]'}>{t('referals_table.filters.search')}</span>
          <ArrowIcon className={`${show && 'rotate-180'} w-[6px] fill-white_opacity_50`} />
        </div>
      )}
      {show && (
        <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col laptop:flex-row gap-4'}>
          <Input
            className={'bg-white_opacity_20 h-max w-full'}
            name={'full_name'}
            textColor={'#ffffff'}
            label={`${t('referals_table.filters.full_name')}`}
            control={control}
          />
          <Input
            className={'bg-white_opacity_20 h-max w-full'}
            name={'phone'}
            textColor={'#ffffff'}
            label={`${t('referals_table.filters.phone')}`}
            control={control}
          />
          <Input
            className={'bg-white_opacity_20 h-max w-full'}
            name={'email'}
            textColor={'#ffffff'}
            label={`${t('referals_table.filters.email')}`}
            control={control}
          />
          <Button loading={isLoading} className={'w-full bg-blue mobile:w-[220px] laptop:w-max h-auto'}>
            {t('referals_table.filters.search_btn')}
          </Button>
        </form>
      )}
    </div>
  );
};
