import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useClickOutside } from '@mantine/hooks';
import cn from 'classnames';

import { setCurrentLanguage } from '7-entities/app';
import { language, LangVariants, useLocales } from '8-shared/lib/i18n';
import { ReactComponent as ArrowDown } from '8-shared/assets/icons/arrow_down_2.svg';
import { config } from '8-shared/config';

interface ILangOption {
  value: LangVariants;
  label: string;
}

export const LangSwitcher = () => {
  const { i18n } = useLocales();
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const ref = useClickOutside(() => setOpen(false));

  const onChangeCurrentLanguage = (lang: LangVariants) => {
    i18n.changeLanguage(lang);
    dispatch(setCurrentLanguage(lang));
    language.setLanguage(lang);
    setOpen(false);
  };

  const languages: ILangOption[] = config.SUPPORTED_LANG.map((i) => ({ value: i as LangVariants, label: i.toUpperCase() }));

  return (
    <div className={'relative cursor-pointer text-white m-auto'} ref={ref}>
      <div onClick={() => setOpen(!open)} className={'flex items-center gap-[5px] text-1.6 font-700'}>
        <div>{i18n.language.toUpperCase()}</div>
        <ArrowDown className={cn('fill-white w-[10px] transition-all', { ['rotate-180']: open })} />
      </div>
      {open && (
        <div className={'absolute mx-auto top-[24px] left-0'}>
          {languages
            .filter((i) => i.value !== i18n.language)
            .map((lang) => (
              <div
                key={lang.value}
                className={'text-center text-1.6 font-700 hover:text-yellow'}
                onClick={() => onChangeCurrentLanguage(lang.value)}
              >
                {lang.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
