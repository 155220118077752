import React from 'react';
import { ActionIcon as MantineActionIcon, type ActionIconProps } from '@mantine/core';

interface IProps extends ActionIconProps {
  onClick?: () => void;
}

export const ActionIcon = ({ children, onClick, ...rest }: IProps) => {
  return (
    <MantineActionIcon onClick={onClick} {...rest}>
      {children}
    </MantineActionIcon>
  );
};
