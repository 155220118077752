import { NumberInput as MantineNumberInput, type NumberInputProps as MantineNumberInputProps } from '@mantine/core';
import React, { useState } from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { useStyles } from './styles';

interface IProps<T extends FieldValues> extends Omit<MantineNumberInputProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
  textColor?: '#ffffff' | '#000000' | string;
}

export const NumberInput = <T extends FieldValues>({ name, control, defaultValue, textColor = '#000000', ...rest }: IProps<T>) => {
  const [focused, setFocused] = useState(false);
  const {
    field: { value: value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });
  const { classes } = useStyles({ floating: !!value || focused, textColor: textColor });
  return (
    <MantineNumberInput
      {...rest}
      {...field}
      type={'number'}
      value={value}
      hideControls
      onChange={(e) => {
        fieldOnChange(e);
      }}
      classNames={classes}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      error={fieldState.error?.message}
      autoComplete="nope"
    />
  );
};
