import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi, IChangeRegisterStageBody, IGetVerifyResponse } from '7-entities/auth';

export const changeVerifyStatus = createAsyncThunk<void, IChangeRegisterStageBody, { state: RootState }>(
  'auth/sendPhoneRegister',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.changeVerifyStatus.initiate(body)).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVerifyThunk = createAsyncThunk<IGetVerifyResponse, void, { state: RootState }>(
  'auth/createSessionVerify',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // return promise that extract body of response
      return await dispatch(authApi.endpoints.getVerify.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
