import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSelector } from 'react-redux';

import { IFifthStepForm, IFifthStepRejectBody, sendSurveyThunk } from '../../model';

import { selectIsLoading, STEPS_ENUM } from '7-entities/auth';

import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Input, Select } from '8-shared/ui';
import { formErrorHandler } from '8-shared/lib/form';
import { getReferalId } from '8-shared/lib/webStorage/referalId';

interface IProps {
  onClose: () => void;
}
export const FifthStepRegisterForm = ({ onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useSelector(selectIsLoading);
  const { t, i18n } = useLocales('auth');

  const schema = yup.object().shape({
    name: yup.string().required(i18n.t('validation.required')),
    surname: yup.string().required(i18n.t('validation.required')),
    investment_experience: yup.string().required(i18n.t('validation.required')),
    investment_strategy: yup.string().required(i18n.t('validation.required')),
    investment_budget: yup.string().required(i18n.t('validation.required')),
  });

  const { control, handleSubmit, setError } = useForm<IFifthStepForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFifthStepForm) => {
    dispatch(sendSurveyThunk({ ...data, invite_ref: getReferalId() }))
      .unwrap()
      .catch((e: IFifthStepRejectBody) => {
        formErrorHandler<IFifthStepForm>({ errors: e.data, setError });
      });
  };
  const hasInvestData = [...t('register.fifth_step.experience.variants', { returnObjects: true })];
  const strategyData = [...t('register.fifth_step.strategy.variants', { returnObjects: true })];
  const budgetData = [...t('register.fifth_step.budget.variants', { returnObjects: true })];
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-4 w-full h-full'}>
      <div className={'text-1.3 mobile:text-1.6 text-center font-600'}>{t('register.step_title', { step: STEPS_ENUM.fifth })}</div>
      <div className={'w-full flex flex-col gap-5 h-full justify-between pb-5'}>
        <div>
          <div className={'text-center text-2 font-500 mb-5  laptop:hidden'}>{t('register.fifth_step.title')}</div>
          <Input className={'mb-[10px]'} label={i18n.t('name_form_fields.name')} name={'name'} control={control} />
          <Input className={'mb-[10px]'} label={i18n.t('name_form_fields.surname')} name={'surname'} control={control} />
          <Select
            data={hasInvestData}
            className={'mb-[10px]'}
            label={t('register.fifth_step.experience.title')}
            name={'investment_experience'}
            control={control}
          />
          <Select
            data={strategyData}
            className={'mb-[10px]'}
            label={t('register.fifth_step.strategy.title')}
            name={'investment_strategy'}
            control={control}
          />
          <Select
            data={budgetData}
            className={'mb-[10px]'}
            label={t('register.fifth_step.budget.title')}
            name={'investment_budget'}
            control={control}
          />
        </div>
        <div className={'flex flex-col gap-2 mobile:flex-row mobile:gap-5'}>
          <Button className={'border-blue w-full text-blue'} loading={isLoading} type={'button'} variant={'outline'} onClick={onClose}>
            {t('register.skip')}
          </Button>
          <Button type={'submit'} className={'bg-blue w-full'} loading={isLoading}>
            {t('register.fifth_step.finish')}
          </Button>
        </div>
      </div>
    </form>
  );
};
