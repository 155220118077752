import React from 'react';
import { Outlet } from 'react-router-dom';

import { MainPageSwitcher, Slogan } from '5-widgets/mainPage';
import { useGetIPOQuery } from '7-entities/ipo';

export const MainPageLayout = () => {
  useGetIPOQuery();
  return (
    <>
      <div className={'flex flex-col items-center mb-[75px] pt-[50px]'}>
        <Slogan />
        <MainPageSwitcher />
      </div>
      <div className={'flex flex-col flex-auto'}>
        <Outlet />
      </div>
    </>
  );
};
