import React from 'react';

import { Button, Modal } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';

interface IProps {
  createAgentRequest: () => void;
  isLoading: boolean;
  opened: boolean;
  onClose: () => void;
}

export const AgentRequestModal = ({ createAgentRequest, onClose, opened, isLoading }: IProps) => {
  const { t } = useLocales('settings');
  return (
    <Modal size={'md'} opened={opened} onClose={onClose}>
      <div className={'flex flex-col w-full items-center text-black text-center gap-7'}>
        <div className={'font-600 text-2.2 leading-[29px] desktop:text-2.8 desktop:leading-[37px]'}>
          {t('agent_request.agent_request_modal.title')}
        </div>
        <Button type={'button'} loading={isLoading} onClick={createAgentRequest} className={'w-full bg-blue'}>
          {t('agent_request.agent_request_modal.button')}
        </Button>
      </div>
    </Modal>
  );
};
