import { IRefUserWithPaginationDto } from '../api/types';
import { IRefUserWithPagination } from '../model/types';

export const mapReferals = (dto: IRefUserWithPaginationDto): IRefUserWithPagination => {
  return {
    number_of_pages: dto.number_of_pages,
    number_of_refs: dto.number_of_refs,
    refs: dto.refs,
  };
};
