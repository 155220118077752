import { createSlice } from '@reduxjs/toolkit';

import { language, LangVariants } from '8-shared/lib/i18n';

export interface IAppState {
  isInit: boolean;
  currentLanguage: LangVariants;
}

const initialState: IAppState = {
  isInit: false,
  currentLanguage: language.getCurrentLanguage() as LangVariants,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInit: (state, action) => {
      state.isInit = action.payload;
    },
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
      language.setLanguage(action.payload);
    },
  },
});

export const selectCurrentLanguage = (state: RootState): LangVariants => state.app.currentLanguage;
export const selectIsInit = (state: RootState): boolean => state.app.isInit;

export const { setIsInit, setCurrentLanguage } = appSlice.actions;
