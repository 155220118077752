import { createAsyncThunk } from '@reduxjs/toolkit';

import { authApi } from '7-entities/auth';
import type {
  IConfirmEmailRegisterBody,
  IConfirmPhoneRegisterBody,
  ISendEmailRegisterBody,
  ISendPhoneRegisterBody,
  ISurveyRegisterBody,
} from '7-entities/auth/api/types';
import { IUser, userAPI } from '7-entities/user';

export const sendEmailRegisterThunk = createAsyncThunk<void, ISendEmailRegisterBody, { state: RootState }>(
  'auth/sendEmailRegister',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.sendEmail.initiate(data)).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmEmailRegisterThunk = createAsyncThunk<IUser, IConfirmEmailRegisterBody, { state: RootState }>(
  'auth/confirmEmailRegister',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.confirmEmail.initiate(data)).unwrap();
      await dispatch(userAPI.endpoints.getProfileInfo.initiate()).unwrap();
      return await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendPhoneRegisterThunk = createAsyncThunk<void, ISendPhoneRegisterBody, { state: RootState }>(
  'auth/sendPhoneRegister',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.sendPhone.initiate(body)).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmPhoneRegisterThunk = createAsyncThunk<void, IConfirmPhoneRegisterBody, { state: RootState }>(
  'auth/confirmPhoneRegister',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.confirmPhone.initiate(body)).unwrap();
      await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendSurveyThunk = createAsyncThunk<void, ISurveyRegisterBody, { state: RootState }>(
  'auth/sendSurveyThunk',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authApi.endpoints.sendSurvey.initiate(body)).unwrap();
      await dispatch(userAPI.endpoints.getUser.initiate()).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
