import React from 'react';
import { useSelector } from 'react-redux';

import { FormVerify } from './formVerify/formVerify';
import { ProgressVerify } from './progress/ProgressVerify';

import { VERIFY_STATUS } from '7-entities/auth';
import { selectVerifyStatus } from '7-entities/user';

interface IProps {
  onClose: () => void;
}

export const Veriff = ({ onClose }: IProps) => {
  const verifyStatus = useSelector(selectVerifyStatus);

  return <>{verifyStatus === VERIFY_STATUS.PROGRESS_VERIFY ? <ProgressVerify onClose={onClose} /> : <FormVerify onClose={onClose} />}</>;
};
