import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { buyP2P } from '../../model/thunks';
import { getInfoOrder } from '../../model/getInfoOrder';

import { ConfirmBuy, BuyForm, type IBuyForm } from '6-features/buy';

import { useBuyP2PDealsMutation, closeBuyP2PModal, selectBuyP2PModal, type IBuyP2PBody } from '7-entities/p2p';
import { selectCurrentLanguage } from '7-entities/app';
import { selectUser } from '7-entities/user';

import { StatusScreen } from '8-shared/ui';
import { useAppDispatch } from '8-shared/hooks';
import { useLocales } from '8-shared/lib/i18n';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';

export enum PURCHASE_STEPS {
  FORM = 'form',
  CONFIRM = 'confirm',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const BuyP2PScenario = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<PURCHASE_STEPS>(PURCHASE_STEPS.FORM);
  const [formData, setFormData] = useState<IBuyForm | undefined>();
  const { isOpen, item } = useSelector(selectBuyP2PModal);
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const [, { reset }] = useBuyP2PDealsMutation({ fixedCacheKey: 'buyP2P' });
  const { t } = useLocales('buy');
  const lang = useSelector(selectCurrentLanguage);

  const changeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  const availableLots = item?.quantity;
  const onCloseHandler = () => {
    reset();
    setStep(PURCHASE_STEPS.FORM);
    dispatch(closeBuyP2PModal());
  };
  const onSubmitForm = (data: IBuyForm) => {
    setFormData(data);
    setStep(PURCHASE_STEPS.CONFIRM);
  };
  const onSubmit = () => {
    setIsLoading(true);
    const body: IBuyP2PBody = {
      quantity: formData?.quantity || 0,
      offer: item?.id || 0,
    };
    dispatch(buyP2P(body))
      .unwrap()
      .then(() => {
        myTracker(user?.deal_id, TRACKER_EVENT.buy_secondary);
        setStep(PURCHASE_STEPS.SUCCESS);
      })
      .catch(() => {
        setStep(PURCHASE_STEPS.FAILED);
      })
      .finally(() => changeLoading());
  };

  if (!isOpen) return null;

  return (
    <>
      <BuyForm onClose={onCloseHandler} opened={step === PURCHASE_STEPS.FORM} onSubmit={onSubmitForm} max={availableLots || 0} />
      <ConfirmBuy
        isLoading={isLoading}
        onClose={onCloseHandler}
        opened={step === PURCHASE_STEPS.CONFIRM}
        onSubmit={onSubmit}
        onBack={() => setStep(PURCHASE_STEPS.FORM)}
        info={getInfoOrder({ item, count: formData?.quantity, lang })}
      />
      <StatusScreen
        opened={step === PURCHASE_STEPS.SUCCESS}
        onClose={onCloseHandler}
        title={t('success.title')}
        description={t('success.description')}
        status={'success'}
      />
      <StatusScreen
        opened={step === PURCHASE_STEPS.FAILED}
        onClose={onCloseHandler}
        title={t('failed.title')}
        description={t('failed.description')}
        status={'error'}
      />
    </>
  );
};
