import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RegisterForm } from '5-widgets/registerForm/registerForm';

import { selectIsAuth } from '7-entities/auth';

import { Link, Modal } from '8-shared/ui';
import { PATH_PAGE } from '8-shared/lib/router';
import { useLocales } from '8-shared/lib/i18n';
import { setReferalId } from '8-shared/lib/webStorage/referalId';

export const RegisterPage = () => {
  const isAuth = useSelector(selectIsAuth);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = location.state?.backgroundLocation;
  const { id } = useParams();
  const { t } = useLocales('auth');

  useEffect(() => {
    if (id) {
      setReferalId(id);
    }
  }, []);

  const onPressCloseModalHandler = () => {
    if (prevLocation) navigate(-1);
    else navigate('/', { replace: true });
  };

  return (
    <Modal opened={open} keepMounted onClose={onPressCloseModalHandler} closeOnClickOutside={false} title={t('register.title')}>
      <div hidden={isAuth}>
        <span className={'text-1.4 laptop:text-1.6 font-300 leading-[25px] mr-[5px]'}>{t('register.or')}</span>
        <Link
          to={PATH_PAGE.login}
          replace
          state={{
            backgroundLocation: prevLocation,
          }}
        >
          {t('register.sign_in')}
        </Link>
      </div>
      <RegisterForm setOpen={setOpen} onCloseModal={onPressCloseModalHandler} />
    </Modal>
  );
};
