import { PinInput as MantinePinInput, type PinInputProps as MantinePinInputProps } from '@mantine/core';
import React from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { useStyles } from './styles';

interface IProps<T extends FieldValues> extends Omit<MantinePinInputProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const PinInput = <T extends FieldValues>({ name, control, defaultValue, className, ...rest }: IProps<T>) => {
  const {
    field: { value: value = '', onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });
  const { classes } = useStyles();
  return (
    <div className={className}>
      <MantinePinInput
        {...rest}
        {...field}
        size={'md'}
        value={value}
        onChange={(e) => {
          fieldOnChange(e);
        }}
        classNames={classes}
        error={!!fieldState.error?.message}
      />
      <div className={'text-red text-1.2 break-words mt-1.5'}>{fieldState.error?.message}</div>
    </div>
  );
};
