import React from 'react';

interface IProps {
  title: string;
  info: { value: number | string; title: string }[];
}

export const InfoBlock = ({ title, info }: IProps) => {
  return (
    <div
      className={`w-full h-full p-[20px] justify-center  small_mobile:h-[210px] laptop:h-[280px]
                        flex flex-col
                        bg-white_opacity_15 text-[22px] 
                        rounded-20 border border-white_opacity_7 
                        mobile:items-center laptop:w-[480px] mb-[30px] mr-[30px]`}
    >
      <span className={'mobile:pb-[24px]'}>{title}</span>
      {info.map((i) => (
        <div key={i.title} className={'flex w-full pb-[15px] justify-between border-b border-white_opacity_7'}>
          <span className={'text-[14px] text-white_opacity_70 mobile:text-[16px] mr-[20px]'}>{i.title}</span>
          <span className={'!whitespace-nowrap self-center'}>{i.value}</span>
        </div>
      ))}
    </div>
  );
};
