import React from 'react';

import about_image from '8-shared/assets/images/about_header_bg_image.webp';
import { useLocales } from '8-shared/lib/i18n';

export const AboutUs = () => {
  const { t } = useLocales('about');

  const our_indicators = [
    { value: t('indicators_value.capital'), title: t('indicators_title.capital') },
    { value: t('indicators_value.portfolio'), title: t('indicators_title.portfolio') },
    { value: t('indicators_value.actives'), title: t('indicators_title.actives') },
  ];

  return (
    <div className={'relative z-[3] flex flex-col  max-w-full layout-padding'}>
      <img
        alt={'about_page_photo_1'}
        src={about_image}
        className={
          'z-[1] absolute scale-[2.4] overflow-hidden top-0 right-[30px] w-full max-w-[450px] mobile:right-[100px] mobile:scale-[3] laptop:right-[150px] laptop:top-[120px] laptop:scale-[5] desktop:scale-[5] desktop:top-[100px] desktop:right-[150px] large:right-[15%]'
        }
      />
      <div className={'relative z-[3] pt-[210px] mobile:pt-[100px]'}>
        <div className={'py-[5px] px-[16px] border w-max border-white text-1.4 laptop:text-1.6 rounded-10 mb-[25px] laptop:mb-[45px]'}>
          {t('about_us')}
        </div>
        <h1 className={'text-3.5 laptop:text-7 leading-[35px] laptop:leading-[70px] font-600 tracking-widest mb-[20px] laptop:mb-[40px]'}>
          {t('who_are_we')}
        </h1>
        <div
          className={
            'mobile:w-1/2 laptop:w-3/5 text-1.4 laptop:text-2.8 leading-[18px] laptop:leading-[28px] font-500 mb-[20px] laptop:mb-[40px]'
          }
        >
          <span className={'text-green'}>{t('company_name')}</span>
          <span>{t('company_description')}</span>
        </div>
        <div
          className={
            'flex flex-col mobile:flex-row mobile:justify-between pb-[65px] mobile:pb-[34px] laptop:pb-[130px]  mobile:border-b border-white_opacity_50'
          }
        >
          {our_indicators.map((i) => (
            <div
              key={i.title}
              className={
                'flex mobile:flex-col items-center mobile:items-start justify-between pb-[10px] pt-[30px] border-b mobile:border-none border-white_opacity_20'
              }
            >
              <span className={'text-1.2 laptop:text-1.6 leading-[15px] laptop:leading-[20px] text-white_opacity_50'}>{i.title}</span>
              <span className={'text-green font-500 text-2 laptop:text-3.6 leading-[26px] laptop:leading-[46px]'}>{i.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
