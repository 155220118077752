import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { REPLENISH_STEPS } from '../../model/types';
import { ReplenishMethod } from './replenishMethod/replenishMethod';
import { ReplenishCurrencyScenario } from './currency/replenishCurrencyScenario';
import { ReplenishUSDTScenario } from './usdt/replenishUSDTScenario';

import { closeReplenishmentModal, selectReplenishmentModalWallet } from '7-entities/user/model/user.slice';

import { useAppDispatch } from '8-shared/hooks';

export const ReplenishScenario = () => {
  const [step, setStep] = useState<REPLENISH_STEPS>(REPLENISH_STEPS.METHOD);
  const isOpen = useSelector(selectReplenishmentModalWallet);
  const dispatch = useAppDispatch();

  const onCloseHandler = () => {
    setStep(REPLENISH_STEPS.METHOD);
    dispatch(closeReplenishmentModal());
  };

  if (!isOpen) return null;

  return (
    <>
      <ReplenishMethod onClose={onCloseHandler} opened={step === REPLENISH_STEPS.METHOD} onSelect={setStep} />
      <ReplenishUSDTScenario onClose={onCloseHandler} opened={step === REPLENISH_STEPS.USDT} />
      <ReplenishCurrencyScenario onClose={onCloseHandler} opened={step === REPLENISH_STEPS.CURRENCY} />
    </>
  );
};
