export const TRACKER_EVENT = {
  register_stage_1: 'email_registration',
  register_stage_2: 'email_confirmation',
  register_stage_3: 'phone_number_registration',
  register_stage_4: 'phone_verification',
  register_stage_5_miss: 'verification_missed',
  login: 'login',
  venture: 'view_venture_category',
  property: 'view_property_category',
  altertative: 'view_altertative_category',
  portfolio: 'view_portfolio',
  wallet: 'view_wallet',
  safe_more: 'view_more_digital_safe',
  lot_more: 'view_more_lot',
  try_replenish_unfinished: (price: number) => `attempt_replenish_unfinished:${price}`,
  try_replenish_failed: (price: number) => `attempt_replenish_failed:${price}`,
  try_replenish_success: (price: number) => `attempt_replenish_successful:${price}`,
  withdraw_quickly: `immediate_withdrawal`,
  withdraw_standard: `standard_withdrawal`,
  write_us: `press_write_to_us`,
  go_to_chat: `go_to_chat`,
  try_buy_unverified: `attempt_buy_unverified_user`,
  try_buy_verified: `attempt_buy_verified_user`,
  buy_first: `purchase_lot_IPO`,
  buy_secondary: `purchase_lot_P2P`,
  buy_digital_safe: `purchase_digital_safe`,
  sell_quickly: `quick_sale`,
  sell_secondary: `secondary_market_sale`,
};
