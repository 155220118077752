import { createAsyncThunk } from '@reduxjs/toolkit';

import { userAPI } from '7-entities/user';

export const changeAvatarThunk = createAsyncThunk<void, FormData, { state: RootState }>(
  'auth/login',
  async (body: FormData, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(userAPI.endpoints.changeAvatar.initiate(body, { fixedCacheKey: 'changeAvatar' })).unwrap();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
