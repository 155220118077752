import React from 'react';

import { PortfolioDeals } from '5-widgets/profile/portfolio';

export const PortfolioPage = () => {
  return (
    <div>
      <PortfolioDeals />
    </div>
  );
};
