import React from 'react';

import { useLocales } from '8-shared/lib/i18n';
import { Button } from '8-shared/ui';
import partnership_red_shadow from '8-shared/assets/images/partnership_bg_image.webp';
import { ReactComponent as RingIcon } from '8-shared/assets/icons/ring.svg';

interface IProps {
  scrollTo: () => void;
}

export const Information = ({ scrollTo }: IProps) => {
  const { t } = useLocales('partnership');

  const tags = [
    t('tags.bloggers'),
    t('tags.fin_services'),
    t('tags.agents'),
    t('tags.internet_services'),
    t('tags.businessmen'),
    t('tags.consultants'),
  ];

  const bonuses = [t('bonuses.first'), t('bonuses.second'), t('bonuses.third')];
  return (
    <div className={'relative z-[0] flex flex-col laptop:flex-row  mb-[60px] mobile:mb-[90px] laptop:mb-[125px] layout-padding-mobile'}>
      <div className={'flex flex-col flex-wrap layout-padding mobile:px-[0] mobile:w-[40%] mb-[40px] laptop:mr-[58px]'}>
        <div className={'uppercase text-2.3 mobile:text-3.6 font-500 leading-[30px] mobile:leading-[47px] mb-[22px]'}>
          {t('information.title')}
        </div>
        <div className={'text-1.6 font-300 leading-[20px]'}>{t('information.sub_title')}</div>
      </div>
      <div className={'relative flex flex-col z-[2] px-[14px] mobile:px-[30px] py-[28px] mobile:py-[40px] rounded-30  bg-white_opacity_10'}>
        <img
          alt={'partnership_page_photo_3'}
          src={partnership_red_shadow}
          className={'absolute w-full h-full scale-[1.8] -left-[50px] -z-[10] mobile:-left-[150px] opacity-60'}
        />
        <div className={'flex flex-wrap mb-[35px]'}>
          {tags.map((el, index) => (
            <div
              className={
                'py-[5px] px-[16px] mr-[12px] mb-[12px] border w-max border-white text-1.2 mobile:text-1.4 laptop:text-1.6 rounded-10'
              }
              key={index}
            >
              {el}
            </div>
          ))}
        </div>
        <div className={'flex flex-col'}>
          <span className={'text-1.4 uppercase font-500 opacity-50 mb-[12px]'}>{t('tariff')}</span>
          <span className={'text-[36px] font-500 uppercase'}>{t('tariff_name')}</span>
          <div className={'flex items-center mb-[30px] mobile:mb-[20px]'}>
            <span className={'text-4.6 mobile:text-9.6 font-500  mr-[20px] mobile:mr-[38px]'}>{t('percent')}</span>
            <span className={'text-1.2 mobile:text-1.4 laptop:text-1.8 mobile:w-1/3 font-500 leading-[24px]'}>
              {t('percent_description')}
            </span>
          </div>
          <hr className={'border-white_opacity_50  mb-[37px]'} />
        </div>
        <div className={'grid grid-cols-1 mobile:grid-cols-3  mb-[37px]'}>
          {bonuses.map((el, index) => (
            <div
              className={'flex mobile:flex-col mr-[0px] mb-[22px] mobile:mb-[0px]  mobile:mr-[44px] desktop:mr-[85px] last:mr-0'}
              key={index}
            >
              <RingIcon className={'min-w-[21px] h-[21px] mr-[23px] mb-[23px]'} />
              <span className={'text-[14px] laptop:text-[16px] font-300 leading-[20px]'}>{el}</span>
            </div>
          ))}
        </div>
        <Button className={'bg-blue'} onClick={scrollTo}>
          {t('become_partner')}
        </Button>
      </div>
    </div>
  );
};
