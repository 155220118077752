import React from 'react';
import { useLocales } from '8-shared/lib/i18n';

export const Columns = () => {
  const { t } = useLocales('wallet');
  return (
    <div className={'w-full flex flex-row text-[10px] text-white_opacity_50 laptop:text-[14px] laptop:justify-between mb-[15px]'}>
      <span className={'desktop:w-[40%] w-[30%] hidden uppercase laptop:block'} />
      <span className={'w-[20%] text-center uppercase hidden laptop:block'}>{t('table.columns.status')}</span>
      <span className={'w-[25%] text-center uppercase hidden laptop:block'}>{t('table.columns.amount')}</span>
      <span className={'w-[20%] text-center uppercase hidden laptop:block'}>{t('table.columns.date')}</span>
    </div>
  );
};
