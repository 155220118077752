export const dataLayerEvents = {
  feedback: {
    event: 'send_callback',
    eventCategory: 'send_callback',
    eventAction: 'send',
  },
  authorization: {
    event: 'authorization',
    eventCategory: 'authorization',
    eventAction: 'send',
  },
  firstStep: {
    event: 'registration',
    eventCategory: 'reg_step_1',
    eventAction: 'send',
  },
  secondStep: {
    event: 'registration',
    eventCategory: 'reg_step_2',
    eventAction: 'send',
  },
  thirdStep: {
    event: 'registration',
    eventCategory: 'reg_step_3',
    eventAction: 'send',
  },
  fourthStep: {
    event: 'registration',
    eventCategory: 'reg_step_4',
    eventAction: 'send',
  },
  fifthStep: {
    event: 'registration',
    eventCategory: 'reg_step_5',
    eventAction: 'send',
  },
  userId: {
    event: 'userId',
    user_id: undefined,
  },
  sold_operations: {
    event: 'buy_stock',
    eventCategory: 'buy_stock',
    eventAction: 'click',
  },
  click_buy_on_banner: {
    event: 'buy_click',
    eventCategory: 'buy_click',
    eventAction: 'click',
  },
} as const;
