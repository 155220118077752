import { IDigitalSafeHistoryDto } from '../api/types';
import { DIGITAL_SAFE_HISTORY_TYPE, IDigitalSafeHistory } from '../model/types';

export const mapDigitalSafeHistory = (dto: IDigitalSafeHistoryDto): IDigitalSafeHistory => {
  return {
    id: dto.id,
    type: dto.type as DIGITAL_SAFE_HISTORY_TYPE,
    amount: dto.amount,
    transaction_date: dto.transaction_date,
    operation_rate: dto.operation_rate,
    total: dto.total,
    logo: dto.logo,
  };
};
