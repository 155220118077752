import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IIPO } from '7-entities/ipo';
import { selectCurrentLanguage } from '7-entities/app';
import { Tag } from '8-shared/ui';
import { dataLayerEventHandler } from '8-shared/lib/dataLayer';

import { getCompanyTagColor } from '../model/getCompanyTagColor';
import { Title } from './title/title';
import { Buttons } from './buttons/buttons';
import { Information } from './information/information';

interface IProps {
  deal: IIPO;
  onClickBuyHandler: () => void;
  onClickMoreHandler: () => void;
}

export const MonthDeal = ({ deal, onClickMoreHandler, onClickBuyHandler }: IProps) => {
  const lang = useSelector(selectCurrentLanguage);

  const monthDeal = useMemo(() => deal?.translations?.[lang], [lang, deal]);

  const onClickBuyWithMetric = () => {
    onClickBuyHandler();
    dataLayerEventHandler('click_buy_on_banner');
  };

  return (
    <div className={'z-[1] layout-padding-mobile'}>
      <div
        className={
          'relative grid grid-areas-month_deal_mobile desktop:grid-areas-month_deal_laptop monthly_deal_bg py-[40px] px-[15px] mobile:px-[20px] desktop:pl-[116px] desktop:pr-[90px] desktop:py-[105px] desktop:gap-x-[90px] rounded-30 border'
        }
      >
        {monthDeal?.type && (
          <Tag className={'absolute right-[20px] top-[20px]'} color={getCompanyTagColor(monthDeal?.type)} label={monthDeal?.type} />
        )}
        <Title />
        <Buttons onClickBuyHandler={onClickBuyWithMetric} onClickMoreHandler={onClickMoreHandler} />
        <Information company={monthDeal} />
      </div>
    </div>
  );
};
