export enum STEPS_ENUM {
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
  fifth = 5,
  six = 6,
}

export enum VERIFY_STATUS {
  NOT_VERIFIED = 'not_verified',
  PHONE_VERIFIED = 'phone_verified',
  SURVEY_VERIFIED = 'survey_verified',
  PROGRESS_VERIFY = 'progress_verify',
  COMPLETED_VERIFY = 'completed_verify',
}
