import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  logo: {
    cursor: 'pointer',
    width: 40,
    [theme.fn.largerThan(640)]: {
      display: 'none',
    },
  },
  logo_large: {
    cursor: 'pointer',
    width: 190,
    [theme.fn.smallerThan(1024)]: {
      width: 130,
    },
    [theme.fn.smallerThan(640)]: {
      display: 'none',
    },
  },
  inner: {
    section: {
      background: '#242237',
    },
  },
}));
