import { LangVariants } from '../i18n';

const months = {
  ru: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
  en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
};

export const getFullDate = (date: string, lang: LangVariants) => {
  const fullDate = new Date(date);
  const day = fullDate.getDate();
  const month = months[lang][fullDate.getMonth()];
  const year = fullDate.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getDate = (date: string) => {
  const fullDate = new Date(date);
  const day = fullDate.getDate();
  const month = fullDate.getMonth() + 1;
  const year = fullDate.getFullYear();
  return `${day}.${month > 10 ? month : `0${month}`}.${year}`;
};

export const getMonthName = (date: string, lang: LangVariants) => {
  const fullDate = new Date(date);
  return months[lang][fullDate.getMonth()];
};

export const getHoursAndMinutes = (date: string): string => {
  const needDate = new Date(date);
  const hours = needDate.getHours() < 10 ? '0' + needDate.getHours() : needDate.getHours();
  const minutes = needDate.getMinutes() < 10 ? '0' + needDate.getMinutes() : needDate.getMinutes();
  return hours + ':' + minutes;
};
