import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

export const Mission = () => {
  const { t } = useLocales('about');
  return (
    <div className={'layout-padding'}>
      <div
        className={
          'flex flex-col laptop:flex-row gap-[20px] mobile:gap-[33px] laptop:gap-[120px] mobile:py-[50px] laptop:py-[73px] mobile:border-b mobile:border-white_opacity_50'
        }
      >
        <span className={'font-500 text-2.3 mobile:text-3.6 leading-[30px] mobile:leading-[46px]'}>{t('our_mission')}</span>
        <span className={'font-500 text-1.4 laptop:text-2.8 leading-[18px] laptop:leading-[36px]'}>{t('mission_description')}</span>
      </div>
    </div>
  );
};
