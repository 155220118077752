import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useClickOutside } from '@mantine/hooks';

import { WalletInfo } from './wallet/wallet';

import { selectUser } from '7-entities/user';

import { PATH_PAGE } from '8-shared/lib/router';
import { useLocales } from '8-shared/lib/i18n';
import { myTracker, TRACKER_EVENT } from '8-shared/lib/myTracker';
import { ReactComponent as ArrowIcon } from '8-shared/assets/icons/arrow_down_2.svg';

export const ProfileTabs = () => {
  const { t } = useLocales('navigation');
  const location = useLocation();
  const user = useSelector(selectUser);
  const [isOpen, setIsOpen] = useState(false);
  const arrow = useRef<HTMLDivElement>(null);
  const info = useRef<HTMLDivElement>(null);

  useClickOutside(() => setIsOpen(false), null, [arrow.current, info.current]);

  const links = [
    { title: t('profile.portfolio'), path: PATH_PAGE.profile.portfolio, isShow: true },
    { title: t('profile.history'), path: PATH_PAGE.profile.history, isShow: true },
    { title: t('profile.digital_safe'), path: PATH_PAGE.profile.digital_safe, isShow: true },
    { title: t('profile.settings'), path: PATH_PAGE.profile.settings, isShow: true },
    { title: t('profile.agent'), path: PATH_PAGE.profile.agent, isShow: user?.is_agent },
    { title: t('profile.wallet'), path: PATH_PAGE.profile.wallet, isShow: true },
    { title: t('profile.documents'), path: PATH_PAGE.profile.documents, isShow: true },
  ];
  const onClick = (path: string) => {
    if (path === PATH_PAGE.profile.portfolio) {
      myTracker(user?.deal_id, TRACKER_EVENT.portfolio);
    }
    if (path === PATH_PAGE.profile.wallet) {
      myTracker(user?.deal_id, TRACKER_EVENT.wallet);
    }
  };

  return (
    <>
      <div className={'flex relative w-full px-[15px]  mobile:px-[30px] large:px-[130px] '}>
        <div className={`w-full transition-all ease-linear ${isOpen && 'bg-balance_desktop_bg rounded-t-15'} `}>
          <div
            className={
              'flex justify-between desktop:border desktop:border-white_opacity_15  w-full pr-[20px] py-[14px] rounded-15 overflow-y-scroll no-scrollbar'
            }
          >
            <div className={'flex  items-center text-1.1 laptop:text-1.8 w-full desktop:w-max'}>
              {links
                .filter((i) => i.isShow)
                .map((i) => (
                  <Link
                    key={i.path}
                    className={cn('w-full border-b desktop:border-0 py-[14px] px-[20px] text-center min-w-max', {
                      ['text-white font-600']: location.pathname === i.path,
                      ['text-white_opacity_50 font-400']: location.pathname !== i.path,
                    })}
                    onClick={() => onClick(i.path)}
                    to={i.path}
                  >
                    {i.title}
                  </Link>
                ))}
            </div>
            <div
              ref={arrow}
              className={'text-1.8 hidden desktop:flex items-center cursor-pointer'}
              onClick={() => setIsOpen((prevState) => !prevState)}
            >
              <ArrowIcon className={`fill-white w-[25px] h-[25px] transition-all ${isOpen && 'rotate-180'}`} />
            </div>
          </div>
        </div>
      </div>
      <WalletInfo opened={isOpen} onClose={() => setIsOpen(false)} ref={info} />
    </>
  );
};
