import React from 'react';
import { useSelector } from 'react-redux';

import { selectManager } from '7-entities/user';
import { ReactComponent as PhoneIcon } from '8-shared/assets/icons/phone_manager.svg';
import { ReactComponent as WhatsAppIcon } from '8-shared/assets/icons/whatsapp_manager.svg';

import { getManagerFullName } from '../model/getManagerFullName';

export const ManagerBlock = () => {
  const manager = useSelector(selectManager);

  if (!manager?.email) {
    return null;
  }
  return (
    <div>
      <div className={'flex flex-col rounded-10  desktop:px-[27px] desktop:py-[25px] desktop:border-[3px] desktop:border-white_opacity_10'}>
        <div className={'flex flex-col gap-[15px] mb-[17px] mobile:flex-row mobile:items-center'}>
          <img alt={''} src={manager?.profile_photo} className={'w-[70px] h-[70px] rounded-full'} />
          <div className={'flex flex-col'}>
            <div className={'text-1.4 mobile:text-1.8 font-600 leading-[16px] mb-[7px]'}>{getManagerFullName(manager)}</div>
            <div className={'text-1.4 small_mobile:text-1.6 font-400 opacity-70 leading-[14px]'}>{manager?.manager_type ?? ''}</div>
          </div>
        </div>

        <div className={'flex flex-col'}>
          <div className={'flex items-center mb-[15px]'}>
            <PhoneIcon className={'w-[20px] mr-[15px]'} />
            <div className={'text-1.4 mobile:text-1.8 font-400 leading-[20px]'}>{manager?.phone ?? ''}</div>
          </div>
          <div className={'flex items-center mb-[15px]'}>
            <WhatsAppIcon className={'w-[20px] mr-[15px]'} />
            <div className={'text-1.4 mobile:text-1.8 font-400 leading-[20px]'}>{manager?.email ?? ''}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
