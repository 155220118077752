import { IQuickConfirmOrder, IQuickSaleForm } from './types';

import { IPortfolioDeals } from '7-entities/user';

import { LangVariants } from '8-shared/lib/i18n';

interface IProps {
  item?: IPortfolioDeals;
  form?: IQuickSaleForm;
  lang: LangVariants;
}
export const getQuickOrderInfo = ({ item, form, lang }: IProps): IQuickConfirmOrder => {
  const commission = 60;
  const quantity = form?.quantity || 0;
  const priceLot = item?.company?.translations?.[lang]?.price || 0;
  const priceWithoutCommission = quantity * priceLot;
  const commissionPrice = (priceWithoutCommission * commission) / 100;
  const totalPrice = priceWithoutCommission - commissionPrice;

  return {
    totalPrice,
    commission,
    quantity,
    commissionPrice,
    priceLot,
  };
};
